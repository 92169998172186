import { AnyAction } from 'redux';
import {
    GET_AUTH_FAILURE,
    GET_AUTH_REQUEST,
    GET_AUTH_SUCCESS,
    GET_SIGNUP_REQUEST,
    GET_SIGNUP_SUCCESS,
    GET_SIGNUP_FAILURE,
    RESET_VALIDATION_MESSAGE,
} from '../constants/auth.constant';

const initialState = {
    loading: false,
    data: [],
    loginError: '',
    signUpError: '',
};

const authReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case GET_AUTH_REQUEST:
            return {
                ...state,
                loadingLogin: true,
                loginError: '',
            };

        case GET_AUTH_SUCCESS:
            return {
                ...state,
                loadingLogin: false,
                data: action.payload,
                loginError: '',
            };
        case GET_AUTH_FAILURE:
            return {
                ...state,
                loadingLogin: false,
                data: [],
                loginError: action.payload,
            };
        case RESET_VALIDATION_MESSAGE:
            return {
                ...state,
                loading: false,
                data: [],
                loginError:'',
            };
        case GET_SIGNUP_REQUEST:
            return {
                ...state,
                loading: true,
                signUpError: '',
            };

        case GET_SIGNUP_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                signUpError: '',
            };
        case GET_SIGNUP_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                signUpError: action.payload,
            };
        default:
            return state;
    }
};

export default authReducer;
