import React, { useState, Suspense, useContext, useEffect } from 'react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AppContext } from '../context/AppContect';
import Popup from '../common/popup/Popup';
import Toast from '../toast/Toast';
import { toast } from 'react-toastify';
import Terms from '../auth/Terms';
import Loader from '../common/loader/Loader';
import styles from './DebitCard.module.css';

export type ToastType = 'error' | 'success' | null;

const DebitCard = () => {
  const appContext = useContext(AppContext);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isOpen, setIsTnCOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState<ToastType>(null);
  const [cardComplete, setCardComplete] = useState(false);

  const handleSave = async (event: React.FormEvent) => {
    if (isChecked && cardComplete) {
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }

      const cardElement: any = elements.getElement(CardElement);

      if (!cardElement) {
        return;
      }

      else {
        const response = await stripe.createToken(cardElement);

        if (response?.error && response?.error?.message) {
          setToastMessage(response?.error?.message);
          setToastType('error')
          setShowToast(true);
          return
        }

        if (response?.token?.card?.funding === 'credit') {
          setIsLoading(true);
          const { id } = response.token;

          await axios
            .patch('visitor/update-stripe-customerId', { newStripeCustomerId: id })
            .then(async (response) => {
              if (response?.data?.statusCode === 200) {
                if (appContext?.visitor?.isDebitCard) {
                  appContext.visitor.isDebitCard = true;
                }
                navigate('/appointments');
                toast.success('Payment method added successfully 🎉');
              } else {
                setToastMessage(response.data.message);
                setShowToast(true);
                setToastType('error')
              }
            })
            .catch((error) => {
              setToastMessage(error.response.data.message);
              setShowToast(true);
              setToastType('error')
            });
        } else {
          setToastMessage('Only credit cards are allowed');
          setToastType('error');
          setShowToast(true);
        }
      }
      setIsLoading(false);
    } else {
      return
    }
  };

  const updateTCCheck = (status: boolean) => {
    setIsTnCOpen(false);
    setIsChecked(status);
  };

  useEffect(() => {
    if (showToast) {
      setTimeout(() => {
        setShowToast(false);
        setToastMessage(null);
        setToastType(null);
      }, 4000);
    }
  }, [showToast]);

  const handleCardChange = (event: any) => {
    setCardComplete(event.complete);
  };


  return (
    <div className={styles.container}>
      {isLoading && <Loader />}
      <div className={`${styles.paymentCardWrapper} creditCard`}>
        <div className={styles.innerForm}>

          <div className={styles.detailsBody}>
            <Suspense fallback={<Loader />}>
              <form onSubmit={handleSave}>
                {<>
                  <div className={styles.chargeInfo}>
                    <div className={styles.information}>
                      <h3>Payment Update Request</h3>
                      <p>Your account is currently linked to a debit card. To enhance your experience, we kindly request you to add a credit card instead.</p>
                    </div>

                    <div className={styles.information}>
                      <h5 className={styles.title}>Liability Preauthorization</h5>
                      <p className={styles.description}>
                        {' '}
                        As with any hotel stay, your credit card will cover the of incidence of damages resulting from your tour or
                        keys not being returned.
                      </p>
                    </div>

                    <p>Update your payment method now to continue enjoying seamless bookings!</p>
                  </div>

                  <CardElement onChange={handleCardChange} />
                </>}

                <div className={`${styles.customBoxes} ${styles.boxes}`} onClick={() => setIsTnCOpen(true)} role='button'>
                  <input
                    type="checkbox"
                    id="terms"
                    name="terms"
                    checked={isChecked}
                  />
                  <label htmlFor='terms'>Accept Terms and Conditions</label>
                </div>

                <div className={styles.btnWrapper}>
                  <button disabled={(!isChecked || !cardComplete)} type="submit">
                    Save
                  </button>
                </div>
              </form>
            </Suspense>
          </div>
        </div>
        <Popup open={isOpen} setOpen={setIsTnCOpen} className='terms-modal' bodyData={<Terms setOpen={setIsTnCOpen} handleBtnClick={updateTCCheck} />} />
      </div>

      {(showToast && toastType && toastMessage) && <Toast message={toastMessage} type={toastType} />}
    </div >
  )
}

export default DebitCard
