
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { AnyIfEmpty } from 'react-redux';
import { NavigateFunction } from 'react-router-dom';
import {
	GET_LEAD_SOURCES_REQUEST,
	GET_LEAD_SOURCES_SUCCESS,
	GET_LEAD_SOURCES_FAILURE
} from '../constants/leadSources.constants';


const getLeadSourceRequest = () => {
	return {
		type: GET_LEAD_SOURCES_REQUEST,
	};
};

const getLeadSourceSuccess = (data: AnyIfEmpty<object>) => {
	return {
		type: GET_LEAD_SOURCES_SUCCESS,
		payload: data,
	};
};

const getLeadSourceFailure = (locationError: string) => {
	return {
		type: GET_LEAD_SOURCES_FAILURE,
		payload: locationError,
	};
};

export const getLeadSource = (locationId: string) => {
	return async (dispatch: AnyIfEmpty<object>) => {
		dispatch(getLeadSourceRequest());
		return await axios
			.get(`/propify/sync-lead-sources/${locationId}`)
			.then(async (response) => {
				await dispatch(getLeadSourceSuccess(response.data));
			})
			.catch((err) => dispatch(getLeadSourceFailure(err.message)));
	};
};