import React from 'react';
import iconLocation from '../../../images/icons/icon-location.svg';
import { locationProps } from '../../../interface/LocationInterface';
import Slider from '../../common/slider/Slider';
import styles from './LocationCard.module.css';
interface LocationProps {
    location: locationProps;
}

const LocationCard = ({ location }: LocationProps) => {
    return (
        <div className={styles.card}>
            <div className={styles.slider}>
                <Slider images={location.images} />
            </div>
            <div className={styles.cardBody}>
                <h3 className={styles.bodyTitle}>{location.name}</h3>
                <p className={styles.timeZone}>{location.timeZone}</p>
                <p className={styles.address}>
                    <img src={iconLocation} alt='location' />
                    <span>
                        {location.street1}, {location.street2}, {location.city} {location.state} {location.zip}
                    </span>
                </p>
                <p className={styles.description}>{location.description}</p>
            </div>
        </div>
    );
};

export default LocationCard;
