import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

const ProtectedRoutes = () => {
    const token: string | null = localStorage.getItem('visitorAccessToken');
    // const location = useLocation();
    const { search, pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }, [pathname]);

    useEffect(() => {
        if (!token) {
            <Navigate to="/signup" replace state={{ destinationURL: pathname, modifiedAppointment: search }} />
        }
    }, []);

    return token ? <Outlet /> : <Navigate to="/signup" replace state={{ destinationURL: pathname, modifiedAppointment: search }} />;
};

export default ProtectedRoutes;
