import React from 'react';
import styles from './Toast.module.css';
import iconSuccess from '../../images/icon-toast-success.svg';
import iconWarning from '../../images/icon-toast-warning.svg';
import iconDelete from '../../images/icon-toast-error.svg';
import toastClose from '../../images/icon-close.svg';

interface ToastProps {
    type?: string;
    message?: string;
}

const Toast = ({ type, message }: ToastProps) => {
    return (
        <div className={styles.toastContainer}>
            <div className={type === 'success' ? styles.toastSuccess : type === 'warning' ? styles.toastWarning : styles.toastError}>
                <img src={type === 'success' ? iconSuccess : type === 'wanning' ? iconWarning : iconDelete} alt="Icon Success" />
            </div>
            <div>
                <h2 className={styles.toastTitle}>{message}</h2>
            </div>
            <div className={styles.toastClose}>
                <img src={toastClose} alt="Close" />
            </div>
        </div>
    );
};

export default Toast;
