import { createContext } from 'react';

const accessToken: string | null = localStorage.getItem('visitorAccessToken');
const visitor: string | null = localStorage.getItem('visitor');

const AppContext = createContext({
  visitorAccessToken: accessToken ? accessToken : '',
  visitor: visitor ? JSON.parse(visitor) : {}
});

export { AppContext };
