import { VERIFY_USER_FAILURE, VERIFY_USER_REQUEST, VERIFY_USER_SUCCESS } from '../constants/verifyUser.constants';

const initialState = {
    loading: false,
    data: [],
    errMsg: '',
};

export const verifyUserReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case VERIFY_USER_REQUEST:
            return {
                ...state,
                loading: true,
                data: [],
                errMsg: '',
            };
        case VERIFY_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case VERIFY_USER_FAILURE:
            return {
                ...state,
                loading: false,
                errMsg: action.payload,
            };
        default:
            return state;
    }
};
