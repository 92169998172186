import React, { Dispatch, SetStateAction, useEffect } from 'react';
import styles from './UnitsFilterPopup.module.css';
import ReactSlider from 'react-slider';
import iconClose from '../../../images/icons/icon-close.svg';
import iconBeds from '../../../images/icons/icon-beds.svg';
import iconBathroom from '../../../images/icons/icon-bathtub.svg';
import iconDollar from '../../../images/icons/icon-Dollar.svg';
import iconSquareFeet from '../../../images/icons/icon-square-feet.svg';
import iconSortBy from '../../../images/icons/icon-sortby.svg';
import { bathrooms, bedrooms, sortBy } from '../../../constants';
import { AppDispatch } from '../../../redux/rootReducer';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getUnitsByLocationId } from '../../../redux/unit/actions/unitsByLocationId.action';

interface props {
    setOpen: Dispatch<SetStateAction<boolean>>;
    filters: {
        bed: (string | number)[];
        bath: number[];
        sortBy: string;
        sortOrder: string;
        minPrice: number;
        maxPrice: number;
        minSize: number;
        maxSize: number;
    };
    setFilters: (arg0: {
        bed: (string | number)[] | never[] | (string | number)[];
        bath: never[] | number[] | (string | number)[];
        sortBy: string;
        sortOrder: string;
        minPrice: string | number;
        maxPrice: string | number;
        minSize: string | number;
        maxSize: string | number;
    }) => void;
    open: boolean;
}

const UnitsFilterPopup = ({ setOpen, filters, setFilters, open }: props) => {
    const resetObj = {
        minPrice: '',
        maxPrice: '',
        bed: [],
        minSize: '',
        maxSize: '',
        bath: [],
        sortBy: 'price',
        sortOrder: 'ASC',
    };

    useEffect(() => {
        if (open === false) {
            setFilters(resetObj);
        }
    }, [open]);

    const dispatch: AppDispatch = useDispatch();
    const locationId = useParams().id;

    const handleBedroomChecked = (value: number | string, key: string) => {
        let prev = key === 'bedrooms' ? filters.bed : filters.bath;
        const findIndex = prev.indexOf(value);
        if (findIndex !== -1) {
            prev.splice(findIndex, 1);
        } else {
            if (value === '') {
                prev = [];
            } else {
                prev.push(value);
            }
        }
        key === 'bedrooms' ? setFilters({ ...filters, bed: [...prev] }) : setFilters({ ...filters, bath: [...prev] });
    };

    const resetHandler = () => {
        setFilters(resetObj);
    };

    const submitHandler = async () => {
        dispatch(getUnitsByLocationId(locationId, filters));
        await setOpen(false);
    };

    return (
        <div className={styles.filterWrapper}>
            <div className={styles.header}>
                <h2>Filter</h2>
                <img
                    className={styles.iconClose}
                    onClick={() => {
                        setOpen(false);
                    }}
                    src={iconClose}
                    alt="Close"
                />
            </div>
            <div className={styles.body}>
                <div className={styles.filterContainer}>
                    <h3 className={styles.filterTitle}>
                        <img className={styles.filterIcon} src={iconSortBy} alt="Beds" /> Sort By
                    </h3>
                    <div className={styles.sortWrapper}>
                        {sortBy.map(({ name, sort, sortOrder }, index) => (
                            <span
                                onClick={() => setFilters({ ...filters, sortBy: sortOrder, sortOrder: sort })}
                                // className={styles.sortTitle}
                                className={filters.sortBy === sortOrder && filters.sortOrder === sort ? styles.sort : `${styles.sortTitle}`}
                                key={index}>
                                {name}
                            </span>
                        ))}
                    </div>
                </div>
                <div className={styles.filterContainer}>
                    <h3 className={styles.filterTitle}>
                        <img className={styles.filterIcon} src={iconBeds} alt="Beds" /> Beds
                    </h3>
                    <div className={styles.checkboxContainer}>
                        {bedrooms.map(({ name, id, value }, index) => (
                            <div key={id} className={styles.boxes}>
                                <input
                                    type="checkbox"
                                    id={`${id}+${index}`}
                                    checked={filters.bed.length > 0 ? filters.bed?.includes(value) : value === ''}
                                    onChange={() => handleBedroomChecked(value, 'bedrooms')}
                                />
                                <label htmlFor={`${id}+${index}`}>{name}</label>
                            </div>
                        ))}
                    </div>
                </div>

                <div className={styles.filterContainer}>
                    <h3 className={styles.filterTitle}>
                        <img className={styles.filterIcon} src={iconBathroom} alt="bathroom" /> Bathroom
                    </h3>
                    <div className={styles.checkboxContainer}>
                        {bathrooms.map(({ id, name, value }, index) => (
                            <div key={index} className={styles.boxes}>
                                <input
                                    type="checkbox"
                                    id={`${id}+${index}`}
                                    checked={filters.bath?.includes(value)}
                                    onChange={() => handleBedroomChecked(value, 'bathrooms')}
                                />
                                <label htmlFor={`${id}+${index}`}>{name}</label>
                            </div>
                        ))}
                    </div>
                </div>

                <div className={styles.filterContainer}>
                    <h3 className={styles.filterTitle}>
                        <img className={styles.filterIcon} src={iconDollar} alt="Beds" /> Select Price
                    </h3>
                    <div className={styles.rangeSliderWrapper}>
                        <ReactSlider
                            className="horizontal-slider"
                            thumbClassName="thumb"
                            trackClassName="track"
                            min={0}
                            max={10000}
                            onAfterChange={(e) => setFilters({ ...filters, minPrice: e[0], maxPrice: e[1] })}
                            value={[filters?.minPrice || 0, filters?.maxPrice || 10000]}
                            ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                            renderThumb={(props, state) => <div {...props}>{`${state.valueNow}`}</div>}
                            pearling
                            minDistance={10}
                        />
                        <p></p>
                    </div>
                </div>
                <div className={styles.filterContainer}>
                    <h3 className={styles.filterTitle}>
                        <img className={styles.filterIcon} src={iconSquareFeet} alt="Square Feet" /> Select Square Foot
                    </h3>
                    <div className={styles.rangeSliderWrapper}>
                        <ReactSlider
                            className="horizontal-slider"
                            thumbClassName="thumb"
                            trackClassName="track"
                            min={0}
                            max={10000}
                            onAfterChange={(e) => setFilters({ ...filters, minSize: e[0], maxSize: e[1] })}
                            value={[filters?.minSize || 0, filters?.maxSize || 10000]}
                            ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                            renderThumb={(props, state) => <div {...props}>{`${state.valueNow}`}</div>}
                            pearling
                            minDistance={10}
                        />
                        <p></p>
                    </div>
                </div>
                <div className={styles.btnWrapper}>
                    <button onClick={submitHandler}>Apply Filter</button>
                    <button onClick={resetHandler} className={styles.resetFilter}>
                        Reset Filter
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UnitsFilterPopup;
