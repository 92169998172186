import React, { useContext, useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
// import Sidebar from './sidebar/Sidebar';
import Footer from './footer/Footer';
import Header from './header/Header';
import styles from './Layout.module.css';
import { AppContext } from '../../components/context/AppContect';
import Popup from '../../components/common/popup/Popup';
import Terms from '../../components/auth/Terms';
import axios from 'axios';
import logoSm from '../../images/pineapple-logo.svg';

const Layout = () => {
  // const [open, setOpen] = useState(false);
  const appContect = useContext(AppContext);
  const [isOpen, setIsTnCOpen] = useState(false);
  const [isAcceptTermsAndConditions, setIsAcceptTermsAndConditions] = useState(true);
  const { search, pathname, } = useLocation();

  const updateTCCheck = async (status: boolean) => {
    if (status) {
      setIsTnCOpen(false);
      setIsAcceptTermsAndConditions(true);
      appContect.visitor.terms = status
      try {
        const response = await axios.patch('/visitor/update-terms');
        const visitor: string | null = localStorage.getItem('visitor');
        localStorage.setItem('visitor', JSON.stringify({ ...JSON.parse(visitor || ''), terms: true }));
      } catch (error) {
        console.log(error);
      }
    } else {
      setIsTnCOpen(false);
      setIsAcceptTermsAndConditions(false);
    }
  };
  useEffect(() => {
    if (!appContect.visitor.terms) {
      setIsTnCOpen(true);
      setIsAcceptTermsAndConditions(false);
    }
  }, [appContect.visitor.terms])

  useEffect(() => {
    getVisitor();
  }, [pathname, appContect]);


  const getVisitor = async () => {
    try {
      const response = await axios.get('/visitor/me', {
        headers: {
          'Cache-Control': 'no-cache'
        }
      });
      appContect.visitor = response.data.visitor;
      if (!response.data.visitor.terms) {
        setIsTnCOpen(true);
        setIsAcceptTermsAndConditions(false);
      } else {
        setIsTnCOpen(false);
        setIsAcceptTermsAndConditions(true);
      }
      localStorage.setItem('visitor', JSON.stringify(response.data.visitor));
    } catch (error) {
      console.log(error);
    }
  };



  return (
    <div className={`${styles.layoutContainer} ${styles.layoutBg}`}>
      {
        // create link in center of screen and on click of link open the popup
        !isOpen && !isAcceptTermsAndConditions ?
          <div className={styles.screenContainer}>
            <Header />
            <div className={styles.container}>
              <img className={styles.notFoundImage} src={logoSm} alt="Pineapple" height={120} width={60} />
              <p>Please accept the terms and conditions to continue</p>
              <button onClick={(e) => {
                e.preventDefault();
                setIsTnCOpen(true);
                setIsAcceptTermsAndConditions(false);
              }} className={styles.btnGreen}>
                Click Here
              </button>
            </div>
            <Footer />
          </div> : null

      }
      {
        isOpen && !isAcceptTermsAndConditions ? <Popup open={isOpen} setOpen={setIsTnCOpen} bodyData={<Terms setOpen={setIsTnCOpen} handleBtnClick={updateTCCheck} />} />
          : null
      }
      {
        isAcceptTermsAndConditions && !isOpen ? <>
          <Header />
          <Outlet />
          <Footer />
        </> : null
      }

    </div>
  );
};

export default Layout;
