import { AnyIfEmpty } from 'react-redux';
import {
    GET_LOCATION_FAILURE,
    GET_LOCATION_REQUEST,
    GET_LOCATION_SUCCESS,
    GET_LOCATION_BY_ID_FAILURE,
    GET_LOCATION_BY_ID_REQUEST,
    GET_LOCATION_BY_ID_SUCCESS,
    CLEAR_PREVIOUS_LOCATION_DATA,
    GET_STATE_LOCATION_REQUEST,
    GET_STATE_LOCATION_SUCCESS,
    GET_STATE_LOCATION_FAILURE,
} from '../constants/location.constant';

const initialState = {
    loading: false,
    data: [],
    error: '',
    locationById: {
        loading: false,
        data: [],
        error: '',
    },
    stateLocations: {
        loading: false,
        data: [],
        error: '',
    }
};

const locationReducer = (state = initialState, action: AnyIfEmpty<object>) => {
    switch (action.type) {
        case GET_LOCATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case GET_LOCATION_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: '',
            };
        case GET_LOCATION_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.payload,
            };
        case GET_LOCATION_BY_ID_REQUEST:
            return {
                ...state,
                locationById: {
                    ...state.locationById,
                    loading: true,
                    error: '',
                },
            };
        case CLEAR_PREVIOUS_LOCATION_DATA:
            return {
                ...state,
                locationById: {
                    ...state.locationById,
                    loading: false,
                    data: [],
                    error: '',
                },
            };
        case GET_LOCATION_BY_ID_SUCCESS:
            return {
                ...state,
                locationById: {
                    ...state.locationById,
                    loading: false,
                    data: action.payload,
                    error: '',
                },
            };
        case GET_LOCATION_BY_ID_FAILURE:
            return {
                ...state,
                locationById: {
                    ...state.locationById,
                    loading: false,
                    error: action.payload,
                },
            };

        case GET_STATE_LOCATION_REQUEST:
            return {
                ...state,
                stateLocations: {
                    data: [],
                    error: '',
                    loading: true,
                }
            };

        case GET_STATE_LOCATION_SUCCESS:
            return {
                ...state,
                stateLocations: {
                    data: action.payload,
                    error: '',
                    loading: false,
                }
            };
        case GET_STATE_LOCATION_FAILURE:
            return {
                ...state,
                stateLocations: {
                    data: [],
                    error: action.payload,
                    loading: false,
                }
            };

        default:
            return state;
    }
};

export default locationReducer;
