import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import banner from '../../../images/no-unit-image.svg';
import Lightbox from '../lightbox/Lightbox';

export const SliderWithLightbox = ({ imgs, setShowLightBox, showLightBox, startIndex, onClickHandler }: any) => {
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    useEffect(() => {
        setSelectedImageIndex(0);
    }, []);

    useEffect(() => {
        const selectedThumb = document.getElementsByClassName('thumb');
        for (let i = 0; i < selectedThumb?.length; i++) {
            selectedThumb[i].addEventListener('click', () => onClickHandler(i));
        }

        return () => document.removeEventListener('click', () => onClickHandler(selectedImageIndex));
    }, [selectedImageIndex]);

    return (
        <div>
            {!!imgs?.length && (
                <Lightbox
                    images={imgs}
                    setShowLightBox={setShowLightBox}
                    showLightBox={showLightBox}
                    startIndex={startIndex}
                    setSelectedImageIndex={setSelectedImageIndex}
                />
            )}
            <Carousel
                showArrows={true}
                autoPlay={true}
                infiniteLoop={true}
                emulateTouch
                onChange={(e) => setSelectedImageIndex(e)}
                preventMovementUntilSwipeScrollTolerance={true}
                onClickItem={() => {
                    imgs?.length && onClickHandler(selectedImageIndex)
                }}
                selectedItem={selectedImageIndex}
                swipeScrollTolerance={50}>
                {imgs?.length ? (
                    imgs.map((img: string, index: number) => (
                        <div className="tempppppp" key={index}>
                            <img src={img} alt="location-image" />
                        </div>
                    ))
                ) : (
                    <div>
                        <img src={banner} alt="" />
                    </div>
                )}
            </Carousel>
        </div>
    );
};
