import React, { useEffect, useState } from 'react';
import styles from './OnBoard.module.css';
import { getStripeIDVerificationSecret, getVerificationSession } from '../../../../redux/stripe/actions/stripe.action';
import { useStripe } from '@stripe/react-stripe-js';
import { AnyIfEmpty, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Popup from '../../../common/popup/Popup';
import Terms from '../../Terms';
import ComponentLoader from '../../../common/component-loader/ComponentLoader';
import GuestCard from '../../guestcard/GuestCard';
import Toast from '../../../toast/Toast';
import { getLocationById, locationClickEvent } from '../../../../redux/location/actions/location.action';
import { AppDispatch, IRootState } from '../../../../redux/rootReducer';
import Loader from '../../../common/loader/Loader';
import { props } from '../../interfaces/auth.interface';


const OnBoard = ({
  setSignupDetails,
  signupDetails,
  setOnBoardComponent,
  setPersonalComponent,
  setWebhookWaiting,
  idVerification,
  setIdVerification,
  destinationURL,
  setShowToast,
  showToast,
  setToastMessage,
  toastMessage,
  setShowRentalTerm,
  showRentalTerm,
  setCCVerification,
  creditCardDetail,
  credentials,
  setCredentialComponent,
  setCreditCardDetail,
  setLocationId,
  locationId,
  setErrorMessage,
  setVerifyUserMessage,
  setConfirmPass,
  confirmPass,
  errorMessage,
  CCVerification,
  setPhoneNum,
  phoneNum,
  modifiedAppointment,
  setUnitId,
  unitId,
  setIsOpen,
  open,
  maxRoom
}: props) => {
  const stripe: AnyIfEmpty<object> = useStripe();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [showDocumentVerification, setShowDocumentVerification] = useState(true);
  const [showCardVerification, setShowCardVerification] = useState(true);
  const locationName = useSelector((state: IRootState) => state.locationReducer.locationById.data?.location?.name);
  const locationInfo = useSelector((state: IRootState) => state.locationReducer.locationById.data?.location);
  const locationLoading = useSelector((state: IRootState) => state.locationReducer.locationById.loading);
  const contactLink = useSelector((state: IRootState) => state.locationReducer.locationById.data?.location?.contactLink);

  useEffect(() => {
    if (locationInfo) {
      setShowDocumentVerification(locationInfo.isDocumentRequired);
      setIdVerification(!locationInfo.isDocumentRequired);
      setShowRentalTerm(!locationInfo.isDocumentRequired);
      setShowCardVerification(locationInfo.isCardRequired);
    }
  }, [locationInfo])
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    let locationId = '';
    if (destinationURL && !modifiedAppointment && destinationURL.includes('/location/')) {
      const [, splittedLocationUrl] = destinationURL.split('/location/');

      if (splittedLocationUrl.includes('/unit/')) {
        const [id, unitId] = splittedLocationUrl.split('/unit/');
        unitId && setUnitId(unitId);
        locationId = id;
      } else {
        locationId = splittedLocationUrl;
      }
      setLocationId(locationId);
    }

    locationId && dispatch(getLocationById(locationId));
  }, [destinationURL, dispatch, modifiedAppointment]);

  useEffect(() => {
    if (locationId) {
      dispatch(locationClickEvent(locationId, 'marketingLinkBeforeLogin'))
    }
  }, [locationId])


  const verifyStripeDocument = async (response: any) => {
    const verificationData = await getVerificationSession(response.id);
    setIdVerification(verificationData.status === 'verified' ? true : false);
    await setSignupDetails({
      ...signupDetails,
      stripeDocVerificationClientId: verificationData.last_verification_report,
      // stripeCustomerId: verificationData.id,
    });

    if (verificationData.status === 'verified') {
      setLoading(false);
      setShowRentalTerm(true);
    } else if (verificationData.status === 'processing') {
      setTimeout(() => {
        verifyStripeDocument(response);
      }, 10000);
    } else {
      setLoading(false);
      setToastMessage('Document verification failed');
    }
  };

  const verifyId = async () => {
    if (idVerification) {
      return;
    } else {
      setWebhookWaiting(true);
      setToastMessage('');
      const response = await getStripeIDVerificationSecret();

      if (locationId) {
        dispatch(locationClickEvent(locationId, 'stripeVerified'))
      }

      // Show the verification .
      const data = await stripe.verifyIdentity(response.client_secret);

      if (data.error) {
        setWebhookWaiting(false);
        return;
      } else {
        setLoading(true);
        verifyStripeDocument?.(response);
      }
      setWebhookWaiting(false);
    }
  };

  const redirect = async () => {
    locationId && await dispatch(locationClickEvent(locationId, 'selfGuided'))
    window.open(contactLink, '_blank', 'noreferrer');
  };

  return (
    <div className="onBoardWrapper">
      {/* {showToast && <Toast message={toastMessage} type="error" />} */}
      {locationLoading ? (
        <Loader className="loaderWrapper" />
      ) : (
        <div className={styles.onBoardContainer}>
          <div className={styles.header}>
            <h3 className={styles.title}>Welcome to {locationName ? locationName : 'Pineapple'} </h3>
            <p className={styles.description}>
              To schedule a tour, you will be asked to verify your identity via Stripe and then confirm your rental details.
            </p>
          </div>
          <div className={`${styles.onBoardBody} onBoardBody`}>
            {loading && (
              <div>
                <ComponentLoader showMsg={true} />
              </div>
            )}
            <ul className={styles.listItems}>
              {
                !!showDocumentVerification &&
                <li className={idVerification ? `${styles.item} ${styles.disabled}` : styles.item} onClick={verifyId}>
                  <span className={styles.num}>
                    <label>1</label>
                  </span>

                  <p className={styles.itemDesc}>
                    <span className={styles.title}>Verify Your Documents with Stripe™</span>
                  </p>
                </li>
              }


              <li className={idVerification && !creditCardDetail ? styles.item : `${styles.item} ${styles.disabled}`}>
                <span className={styles.num}>
                  <label>{(showDocumentVerification) ? '2' : '1'}</label>
                </span>
                <p className={styles.itemDesc}>
                  <span className={styles.title}>Confirm Details of Rental Term</span>
                </p>
              </li>
              {
                !!showCardVerification &&
                <li className={creditCardDetail ? styles.item : `${styles.item} ${styles.disabled}`}>
                  <span className={styles.num}>
                    <label>{(showDocumentVerification) ? '3' : '2'}</label>
                  </span>

                  <p className={styles.itemDesc}>
                    <span className={styles.title}>Verify Your Credit Card Details</span>
                  </p>
                </li>
              }

            </ul>
          </div>

          <div
            className={!showRentalTerm ? ` ${styles.showRentalTerm} ` : `${styles.showRentalTerm} ${styles.active}`}
          // className={`${!showRentalTerm} ? ${styles.showRentalTerm}: ${styles.showRentalTerm} ${styles.active}`}
          >
            {/* {!CCVerification && ( */}
            <GuestCard
              setSignupDetails={setSignupDetails}
              signupDetails={signupDetails}
              setOnBoardComponent={setOnBoardComponent}
              setPersonalComponent={setPersonalComponent}
              setToastMessage={setToastMessage}
              setShowToast={setShowToast}
              setIsOpen={setIsOpen}
              locationId={locationId}
              setCreditCardDetail={setCreditCardDetail}
              setShowRentalTerm={setShowRentalTerm}
              setErrorMessage={setErrorMessage}
              setVerifyUserMessage={setVerifyUserMessage}
              setConfirmPass={setConfirmPass}
              confirmPass={confirmPass}
              setCCVerification={setCCVerification}
              setPhoneNum={setPhoneNum}
              phoneNum={phoneNum}
              maxRoom={maxRoom}
            />
            {/* )} */}
          </div>

          {!idVerification && (
            <div>
              <div className={styles.signBefore}>
                <div
                  onClick={async () => {
                    locationId && await dispatch(locationClickEvent(locationId, 'pageSeen'))
                    await navigate('/login', { state: { destinationURL, modifiedAppointment } });
                  }}>
                  Seen this page before? Sign In to your account.
                </div>
              </div>
              {contactLink && (
                <div className={styles.signBefore} onClick={redirect}>
                  <div>Not looking for a self-guided tour? Contact the property.</div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {/* {showToast && <Toast type="delete" message={toastMessage} />} */}
    </div>
  );
};

export default OnBoard;
