import { useContext, useEffect, useRef, useState } from 'react';
import Webcam, { WebcamProps } from 'react-webcam';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, IRootState } from '../../redux/rootReducer';
import Select, { components } from 'react-select';
import moment from 'moment';
import PhoneInput from 'react-phone-input-2';
import { AppContext } from '../context/AppContect';
import { editProfile, resetProfileMessage } from '../../redux/auth/actions/editProfile.action';
import Toast from '../toast/Toast';
import axios from 'axios';
import { uploadImages } from '../../redux/auth/actions/auth.action';
import CamIcon from "../../images/icons/cam.svg";
import Avatar from '../../images/avatar-placeholder.jpg';
import drop from '../../images/icons/icon-drop-down.svg';
import Popup from '../common/popup/Popup';
import TinyLoader from '../tiny-loader/TinyLoader';
import CloseIcon from '../../images/icon-close.svg';
import CamLayer from '../../images/cam-layer.svg';
import style from './Profile.module.css'

const bed = [
  { value: 'Studio', label: 'Studio' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
];

const Profile = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const appContext = useContext(AppContext);
  const { firstName, lastName, countryCode, desiredNumBedrooms, expectedMoveInDate, phoneNumber, email, photo_url } = appContext.visitor;
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const today = new Date().toISOString().split('T')[0];

  const profileError = useSelector((state: IRootState) => state.editProfileReducer.errMsg);
  const profileResponse = useSelector((state: IRootState) => state.editProfileReducer.data);
  const profileLoading = useSelector((state: IRootState) => state.editProfileReducer?.loading);
  const [showWebCam, setShowWebCam] = useState(false);
  const [uploadFiles, setUploadFiles] = useState('');
  const [newProfile, setNewProfile] = useState('');
  const [hasMediaAccess, setHasMediaAccess] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const webcamRef = useRef<Webcam>(null);

  const videoConstraints: MediaTrackConstraints | boolean = {
    facingMode: 'user', // or 'environment' for rear camera
  };

  const capturePhoto = () => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      setNewProfile(imageSrc);
    };
  };

  const {
    handleSubmit,
    getValues,
    formState: { isValid, errors, isDirty },
    control,
    reset
  } = useForm({
    defaultValues: {
      email: '',
      phoneNumber: '',
      firstName: '',
      lastName: '',
      countryCode: 1,
      desiredNumBedrooms: '',
      expectedMoveInDate: '',
      oldExpectedMoveInDate: '',
    },
    mode: "onChange",
  });

  useEffect(() => {
    if (appContext?.visitor) {
      reset({
        'firstName': firstName,
        'lastName': lastName,
        'expectedMoveInDate': expectedMoveInDate,
        'oldExpectedMoveInDate': expectedMoveInDate,
        'phoneNumber': countryCode + phoneNumber,
        'desiredNumBedrooms': desiredNumBedrooms,
        'email': email
      });
      setUploadFiles(photo_url);
      setNewProfile('');
    }
  }, [appContext]);

  useEffect(() => {
    if (profileError !== '') {
      setTimeout(() => {
        resetProfileMessage()
      }, 4000);
    }
  }, [profileError])

  const onSuccess = async () => {
    try {
      const response = await axios.get('/visitor/me', {
        headers: { 'Cache-Control': 'no-cache' }
      });
      appContext.visitor = response.data.visitor;
      localStorage.setItem('visitor', JSON.stringify(response.data.visitor));

      const { firstName, lastName, countryCode, desiredNumBedrooms, expectedMoveInDate, phoneNumber, email, photo_url } = response.data.visitor;
      reset({
        'firstName': firstName,
        'lastName': lastName,
        'expectedMoveInDate': expectedMoveInDate,
        'oldExpectedMoveInDate': expectedMoveInDate,
        'phoneNumber': countryCode + phoneNumber,
        'desiredNumBedrooms': desiredNumBedrooms,
        'email': email
      });
      setUploadFiles(photo_url);
      setNewProfile('');
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  const base64ToFile = (base64String: string, fileName: string, contentType: string): File => {
    const trimmedBase64String = base64String.trim();

    if (!trimmedBase64String.startsWith('data:image/jpeg;base64,')) {
      throw new Error('Invalid base64 string format');
    }
    const byteCharacters = atob(trimmedBase64String.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: contentType });
    return new File([blob], fileName, { type: contentType });
  }

  const uploadProfile = async () => {
    if (uploadFiles !== photo_url) {
      const file = base64ToFile(uploadFiles, 'user', 'image/jpeg');
      const response = await uploadImages(file, '/user-profile');
      return response;
    } else {
      return photo_url
    }
  }

  const onSubmit = async (data: { firstName: string, lastName: string, expectedMoveInDate: string, phoneNumber: string, desiredNumBedrooms: string, email: string }) => {
    setIsLoading(true);
    const response = await uploadProfile();
    const profileDetails = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      countryCode: 1,
      phoneNumber: Number(data.phoneNumber.toString().substring(1)),
      expectedMoveInDate: data?.expectedMoveInDate,
      desiredNumBedrooms: data.desiredNumBedrooms,
      photo_url: response
    }
    dispatch(editProfile(profileDetails, onSuccess, setIsLoading));
  };

  const requestCameraPermission = () => {
    const askCameraPermission = async (): Promise<MediaStream | null> => await navigator.mediaDevices.getUserMedia({ video: true });

    let localStream: MediaStream | null;
    askCameraPermission().then(() => {
    }).then(() => {
      setHasMediaAccess(true);
      localStream?.getTracks().forEach(track => {
        track.stop();
      });
    }).catch((error) => {
      if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
        setHasMediaAccess(false);
        setToastMessage('Camera access denied! Please grant access to your camera to continue.');
      }
    })
  };

  useEffect(() => {
    if (toastMessage) {
      setTimeout(() => {
        setToastMessage('');
      }, 4000);
    }
  }, [toastMessage]);

  useEffect(() => {
    requestCameraPermission();
    dispatch(resetProfileMessage())
  }, [])

  const submitPhoto = () => {
    const newUserProfile = newProfile;
    setUploadFiles(newUserProfile);
    setNewProfile('');
    setShowWebCam(false);
  }

  const ModalBody = () => {
    return (
      <div className={style.modalBodyContainer}>
        <div className={style.modalHeader}>
          <h3>Upload Selfie</h3>

          <div className={style.closeIcon} onClick={() => {
            setShowWebCam(false);
            setNewProfile('');
          }}>
            <img src={CloseIcon} alt='close-icon' width={20} height={20} />
          </div>
        </div>
        <div className={style.modalBodyInnerContainer}>

          {!newProfile &&
            <>
              <div className={style.camLayer}>
                <img src={CamLayer} alt='layer' width={684} height={480} />
              </div>
              <Webcam
                audio={false}
                className={style.webCamContainer}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                ref={webcamRef}
                autoFocus
              />
            </>
          }

          {newProfile && <div className={style.capturedImage}>
            <img src={newProfile} alt='' width={640} height={480} />
          </div>}
        </div>
        <div className={style.modalFooter}>
          {newProfile && <button className={style.retakeBtn} onClick={() => setNewProfile('')}>Recapture Photo</button>}
          <button className={newProfile ? style.submitBtn : style.captureBtn} onClick={newProfile ? submitPhoto : capturePhoto}>{!!newProfile ? 'Submit my picture' : 'Capture photo'}</button>
        </div>
      </div>
    )
  }

  return (
    <div className={style.profileContainer}>
      <div className={style.innerContainer}>

        <form onSubmit={handleSubmit(onSubmit)} className={style.formWrapper} noValidate>

          <h2 className={style.authTitle}>Edit Profile</h2>

          <div className={style.imageUploaderContainer}>
            <div className={style.imageInnerContainer}>
              <img src={uploadFiles ? uploadFiles : Avatar} width={120} height={120} alt="avatar" className={style.userAvatar} />
              <div role='button' className={style.camIconContainer} onClick={() => {
                if (!hasMediaAccess) {
                  setToastMessage('Camera access denied! Please grant access to your camera to continue.')
                } else {
                  setShowWebCam(true)
                }
              }}>
                <img src={CamIcon} alt="camera-icon" width={20} height={20} />
              </div>
            </div>
          </div>

          {/* First Name */}
          <Controller
            name="firstName"
            control={control}
            rules={{
              required: "First Name is required"
            }}
            render={({ field: { onChange, value } }) => {
              return (<div className={style.fieldWrapper}>
                <label>First Name</label>
                <div className={style.passwordWrapper}>
                  <input
                    value={value}
                    onChange={(e) => onChange(e)}
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="Enter First Name"
                  />
                </div>
                {errors?.firstName && <p className={style.errorText}>{errors?.firstName?.message}</p>}
              </div>)
            }}
          />

          {/* Last Name */}
          <Controller
            name="lastName"
            control={control}
            rules={{
              required: "Last Name is required"
            }}
            render={({ field: { onChange, value } }) => {
              return (<div className={style.fieldWrapper}>
                <label>Last Name</label>
                <div className={style.passwordWrapper}>
                  <input
                    value={value}
                    type="text"
                    onChange={onChange}
                    id="lastName"
                    name="lastName"
                    placeholder="Enter Last Name"
                  />
                </div>
                {errors?.lastName && <p className={style.errorText}>{errors?.lastName?.message}</p>}
              </div>)
            }}
          />

          {/* Email */}
          <Controller
            name="email"
            control={control}
            render={({ field: { value } }) => {
              return (<div className={style.fieldWrapper}>
                <label>Email</label>
                <div className={style.passwordWrapper}>
                  <input
                    value={value}
                    type="email"
                    disabled
                    id="email"
                    name="email"
                    placeholder="Enter your email address"
                  />
                </div>
              </div>)
            }}
          />

          {/* Phone Number */}
          <Controller
            name="phoneNumber"
            control={control}
            rules={{
              required: 'Phone number should not be empty',
              minLength: {
                value: 11,
                message: 'Phone number must be 10 characters'
              },
              maxLength: {
                value: 11,
                message: 'Phone number must be 10 characters'
              }
            }}
            render={({ field: { onChange, value } }) => {
              return (<div className={`${style.fieldWrapper} ${style.sm} ${style.phoneNum}`}>
                <label>
                  Phone Number <sup>*</sup>
                </label>
                <PhoneInput
                  country={'us'}
                  value={value}
                  onChange={(value: string) => { onChange(value) }}
                  inputStyle={{
                    marginLeft: '30px',
                  }}
                  onlyCountries={['us']}
                  inputProps={{
                    maxLength: 17,
                    required: true
                  }}
                  countryCodeEditable={false}
                  enableLongNumbers={true}
                />
                {errors?.phoneNumber && <p className={style.errorText}>{errors?.phoneNumber?.message}</p>}
              </div>)
            }}
          />

          {/* Expected Move in date */}
          <Controller
            name="expectedMoveInDate"
            control={control}
            defaultValue=""
            rules={{
              required: 'Move in date should not be empty',
              validate: value => {
                const selectedDate = new Date(value);
                const oldExpectedMoveInDate = new Date(getValues('oldExpectedMoveInDate'));
                if (moment(moment(selectedDate).format('YYYY-MM-DD')).isSame(moment(oldExpectedMoveInDate).format('YYYY-MM-DD'))) {
                  return true;
                } else if (moment(moment(selectedDate).format('YYYY-MM-DD')).isSameOrAfter(moment(new Date(today)).format('YYYY-MM-DD'))) {
                  return true;
                } else {
                  return 'Move in date should be the old expected move-in date, today or later';
                }
              }
            }}
            render={({ field: { onChange, value } }) => {
              return (<div className={style.fieldWrapper}>
                <label>
                  Expected Move In Date <sup>*</sup>
                </label>
                <input
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  type="date"
                  name="expectedMoveInDate"
                  max="9999-12-31"
                  placeholder="YYYY-MM-DD"
                  value={value}
                  min={today}
                />
                {errors?.expectedMoveInDate && <p className={style.errorText}>{errors?.expectedMoveInDate?.message}</p>}
              </div>)
            }}
          />

          {/* Bedrooms */}
          <Controller
            name="desiredNumBedrooms"
            control={control}
            rules={{
              required: "Last Name is required"
            }}
            render={({ field: { onChange, value } }) => {
              return (<div className={`${style.fieldWrapper} ${style.dropDownOption}`}>
                <label>
                  Desired Number of Bedrooms<sup>*</sup>
                </label>
                <div className='profile-custom-dropdown'>
                  <Select
                    onChange={(selectedOption) => {
                      onChange(selectedOption?.value)
                    }}
                    // isSearchable={false}

                    classNamePrefix='custom-profile-dropdown'
                    options={bed}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9, fontSize: 14, fontWeight: 400 }),
                      menuList: base => ({
                        ...base,
                        maxHeight: "124px",
                        height: '100%'
                      }),
                      option: (base) => ({ ...base, cursor: 'pointer' })
                    }}
                    placeholder={<div className="selectPlaceholderText">Select</div>}
                    value={value ? { value: value, label: value } : null}
                  />
                  <span className='custom-dropdown-indicator'>
                    <img src={drop} alt="dropdown icon" />
                  </span>
                </div>
              </div>)
            }}
          />

          <div className={style.buttonContainer}>
            <div className={style.cancelWrapper} onClick={() => navigate('/appointments')}>
              <button type="button">Cancel</button>
            </div>

            <div className={style.submitWrapper}>
              <button type="submit" disabled={((isValid && !isDirty) && (photo_url === uploadFiles)) || profileLoading || isLoading}>{<span className={isLoading || profileLoading ? style.loader : ''}>{profileLoading || isLoading ? <TinyLoader /> : 'Update'}</span>}</button>
            </div>
          </div>

        </form>
      </div >
      <Popup open={showWebCam} setOpen={setShowWebCam} bodyData={<ModalBody />} />
      {(profileError !== '' || profileResponse?.statusCode === 200 || toastMessage) && <Toast message={profileError || profileResponse?.message || toastMessage} type={profileError || toastMessage ? 'error' : 'success'} />}
    </div >
  )
}

export default Profile