import React from 'react';
import styles from './DeleteAppointmentPopup.module.css';
import iconClose from '../../images/icons/icon-close.svg';

interface DeleteAppointmentPopupProps {
    setOpen: (open: boolean) => void;
    handleDelete: () => void;
}

const DeleteAppointmentPopup = ({ setOpen, handleDelete }: DeleteAppointmentPopupProps) => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h2 className={styles.title}>Delete Appointment</h2>
                <img className={styles.iconClose} src={iconClose} alt="close" onClick={() => setOpen(false)} />
            </div>
            <div className={`${styles.body} ${styles.textCenter}`}>
                <h3 className={styles.bodyTitle}>Are you sure you want to cancel this appointment?</h3>
                <p className={styles.deleteDetails}>
                    This appointment will be cancelled. Another one can be booked.
                </p>
                <div className={styles.btnWrapper}>
                    <button className={`${styles.delete} ${styles.btn}`} onClick={() => handleDelete()}>
                        Delete
                    </button>
                    <button className={`${styles.cancel} ${styles.btn}`} onClick={() => setOpen(false)}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeleteAppointmentPopup;
