import { AnyIfEmpty } from 'react-redux';
import {
    CLEAR_APPOINTMENT_STATE,
    CREATE_APPOINTMENT_FAILURE,
    CREATE_APPOINTMENT_REQUEST,
    CREATE_APPOINTMENT_SUCCESS,
    GET_APPOINTMENTS_FAILURE,
    GET_APPOINTMENTS_REQUEST,
    GET_APPOINTMENTS_SUCCESS,
    GET_APPOINTMENT_DETAILS_FAILURE,
    GET_APPOINTMENT_DETAILS_SUCCESS,
    GET_APPOINTMENT_DETAILS_REQUEST,
    SET_APPOINTMENT_CHAT_SESSION_FAILURE,
    SET_APPOINTMENT_CHAT_SESSION_REQUEST,
    SET_APPOINTMENT_CHAT_SESSION_SUCCESS
} from '../constants/appointment.constant';

const initialState = {
    appointment: {
        loading: false,
        data: {},
        error: '',
    },
    appointments: {
        loading: false,
        data: [],
        error: '',
    },
    appointmentDetail: {
        loading: false,
        data: [],
        error: '',
    },
    appointmentChatSession: {
        loading: false,
        data: [],
        error: '',
    }
};

const appointmentReducer = (state = initialState, action: AnyIfEmpty<object>) => {
    switch (action.type) {
        case CREATE_APPOINTMENT_REQUEST:
            return {
                ...state,
                appointment: {
                    ...state.appointment,
                    loading: true,
                    error: ''
                },
            };

        case CREATE_APPOINTMENT_SUCCESS:
            return {
                ...state,
                appointment: {
                    ...state.appointment,
                    loading: false,
                    data: action.payload,
                    error: '',
                },
            };

        case CREATE_APPOINTMENT_FAILURE:
            return {
                ...state,
                appointment: {
                    ...state.appointment,
                    loading: false,
                    data: {},
                    error: action.payload,
                },
            };

        case CLEAR_APPOINTMENT_STATE:
            return {
                appointment: {
                    loading: false,
                    data: {},
                    error: '',
                },
                appointments: {
                    loading: false,
                    data: [],
                    error: '',
                },
                appointmentDetail: {
                    loading: false,
                    data: [],
                    error: '',
                },
                appointmentChatSession: {
                    loading: false,
                    data: [],
                    error: '',
                }
            };

        case GET_APPOINTMENTS_REQUEST:
            return {
                ...state,
                appointments: {
                    ...state.appointments,
                    loading: true,
                },
            };

        case GET_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                appointments: {
                    ...state.appointments,
                    loading: false,
                    data: action.payload,
                    error: '',
                },
            };

        case GET_APPOINTMENTS_FAILURE:
            return {
                ...state,
                appointments: {
                    ...state.appointments,
                    loading: false,
                    data: [],
                    error: action.payload,
                },
            };

        case GET_APPOINTMENT_DETAILS_REQUEST:
            return {
                ...state,
                appointmentDetail: {
                    ...state.appointmentDetail,
                    loading: true,
                },
            };

        case GET_APPOINTMENT_DETAILS_SUCCESS:
            return {
                ...state,
                appointmentDetail: {
                    ...state.appointmentDetail,
                    loading: false,
                    data: action.payload,
                    error: '',
                },
            };

        case GET_APPOINTMENT_DETAILS_FAILURE:
            return {
                ...state,
                appointmentDetail: {
                    ...state.appointmentDetail,
                    loading: false,
                    data: [],
                    error: action.payload,
                },
            };

        case SET_APPOINTMENT_CHAT_SESSION_REQUEST:
            return {
                ...state,
                appointmentChatSession: {
                    ...state.appointmentChatSession,
                    loading: true,
                },
            };

        case SET_APPOINTMENT_CHAT_SESSION_SUCCESS:
            return {
                ...state,
                appointmentChatSession: {
                    ...state.appointmentChatSession,
                    loading: false,
                    data: action.payload,
                    error: '',
                },
            };

        case SET_APPOINTMENT_CHAT_SESSION_FAILURE:
            return {
                ...state,
                appointmentChatSession: {
                    ...state.appointmentChatSession,
                    loading: false,
                    data: [],
                    error: action.payload,
                },
            };

        default:
            return state;
    }
};

export default appointmentReducer;
