import React from 'react';

interface OpenViaIconProps {
  width?: string | number;
  height?: string | number;
  fill?: string;
  className?: string;
}

const OpenViaIcon: React.FC<OpenViaIconProps> = ({
  width = 24,
  height = 24,
  fill = "#8C8D90",
  className = ''
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7.14643 16.8536C7.43932 17.1465 7.91419 17.1465 8.20709 16.8536L16.7071 8.35355C17 8.06065 17 7.58585 16.7071 7.29295C16.4142 7.00005 15.9393 7.00005 15.6464 7.29295L7.14643 15.7929C6.85353 16.0858 6.85353 16.5607 7.14643 16.8536Z"
      fill={fill}
    />
    <path
      d="M16.1768 17.5732C16.591 17.5732 16.9268 17.2375 16.9268 16.8232V7.8232C16.9268 7.409 16.591 7.0732 16.1768 7.0732H7.17676C6.76254 7.0732 6.42676 7.409 6.42676 7.8232C6.42676 8.2375 6.76254 8.5732 7.17676 8.5732H15.4268V16.8232C15.4268 17.2375 15.7625 17.5732 16.1768 17.5732Z"
      fill={fill}
    />
  </svg>
);

export default OpenViaIcon;
