import React, { useEffect, useRef, useState } from 'react';
import { AnyIfEmpty, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { locationProps } from '../../interface/LocationInterface';
import { clearPreviousLocation, getLocation, getLocationbySelectedStates } from '../../redux/location/actions/location.action';
import { AppDispatch, IRootState } from '../../redux/rootReducer';
import Loader from '../common/loader/Loader';
import LocationCard from './LocationCard/LocationCard';
import styles from './Locations.module.css';
import { selecteStates, SelectProps } from '../../utils/constants/constants';
import NoPropertyImg from '../../images/noPropertyImg.svg';
import SorryPineapple from '../../images/pineapple-sorry.svg';
import Select, { SelectItemRenderer } from 'react-dropdown-select';

export interface locationAvilability {
  id: string;
  locker: number;
  startDate: string;
  endDate?: string;
  openTime: string;
  closeTime: string;
  createdAt: Date;
  updatedAt: Date;
}

const Locations = () => {
  const navigate = useNavigate();
  const [selectedState, setSelectedState] = useState<SelectProps[] | null>(null);
  const dispatch: AppDispatch = useDispatch();
  const hasMounted = useRef(false);

  const [isAllStateSelected, setIsAllStateSelected] = useState(false)

  const locations = useSelector((state: IRootState) => state.locationReducer.stateLocations.data);
  const loading = useSelector((state: IRootState) => state.locationReducer.stateLocations.loading);

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
    }
  }, []);

  const getLocations = async () => {
    if (selectedState) {
      let getSelectedStateNames: string[] = [];
      const selectedProperties: SelectProps[] = [...selectedState];

      await selectedProperties.forEach((value: SelectProps) => {
        getSelectedStateNames.push(value.label);
      });

      await dispatch(getLocationbySelectedStates([...getSelectedStateNames]));
    }
  }

  useEffect(() => {
    if (selectedState !== null)
      getLocations();
  }, [selectedState]);

  useEffect(() => {
    if (selecteStates.length) {
      setSelectedState([...selecteStates]);
      setIsAllStateSelected(true);
    }
  }, [selecteStates])

  const onClickHandler = (location: locationProps) => {
    dispatch(clearPreviousLocation());
    navigate(`/location/${location.id}`, { state: location.name })
  };

  const customStateContentRenderer = ({ props, state }: any) => (
    state.values?.length ?
      <div style={{ cursor: 'pointer' }}>
        {state.values.length} of {props.options.length} States selected
      </div>
      :
      <div className={styles.placeholder}><span>Select States</span></div>
  );

  const itemRenderer = (props: SelectItemRenderer<SelectProps>) => {
    const { item } = props;
    return (<div className={styles.boxes}>
      <input type="checkbox" id={item?.label} />
      <label htmlFor={item?.label}>{item?.value}</label>
    </div>
    );
  };

  const customStateDropdownRenderer = ({ props, state, methods }: any) => {
    const regexp = new RegExp(state.search, 'i');

    if (state?.values?.length !== state?.searchResults?.length) {
      setIsAllStateSelected(false)
    }
    if (state?.values?.length === state?.searchResults?.length) {
      setIsAllStateSelected(true)
    }

    return (
      <div className={styles.customDropdownMenu}>
        {<div className={styles.actionButtons}>
          <div className={styles.boxes} >
            <input type="checkbox" id='selectAll' checked={isAllStateSelected} onChange={(e) => {
              setIsAllStateSelected(e?.target?.checked)
              e?.target?.checked ? methods?.selectAll() : methods?.clearAll();
            }} />
            <label htmlFor='selectAll'>Select All</label>
          </div>
          <button className={styles.clearBtn} onClick={() => {
            setSelectedState([])
            methods?.clearAll()
          }} >
            Clear All
          </button>
        </div>
        }
        <div className={styles.listContainer}>
          {props.options
            .filter((item: any) => regexp.test(item[props.searchBy] || item[props.labelField]))
            .map((option: any) => {
              if (!props.keepSelectedInList && methods.isSelected(option)) {
                return null;
              }

              return (
                <div
                  key={option[props.valueField]}
                  className={styles.boxes}
                >
                  <input
                    type="checkbox"
                    id={option[props.labelField]}
                    onChange={() => (option.disabled ? undefined : methods.addItem(option))}
                    checked={state.values.indexOf(option) !== -1}
                  />
                  <label htmlFor={option[props.labelField]}>{option[props.labelField]}</label>
                </div>
              );
            })}
        </div>
      </div >
    );
  };


  const handleChangeState = (values: SelectProps[]) => {
    if (hasMounted) {
      setSelectedState(values);
    }
  }


  return (
    <>
      {loading && <Loader />}
      <div className={`${styles.container} location-listing`}>
        <div className={styles.inlineItems}>
          <h1 className={styles.title}>Properties</h1>

          <div className={styles.timeZoneWrapper}>
            <label>Select state</label>
            <Select
              className={styles.customReactSelectDropdown}
              options={selecteStates}
              values={selectedState || []}
              keepSelectedInList
              multi
              onChange={handleChangeState}
              clearable={selectedState === null ? false : selectedState && selectedState.length > 0 ? true : false}
              placeholder='States'
              contentRenderer={customStateContentRenderer}
              itemRenderer={itemRenderer}
              closeOnScroll
              dropdownRenderer={customStateDropdownRenderer}
            />
          </div>
        </div>

        {(!loading && locations.length === 0) && <div className={styles.noPropertyWrapper}>
          <img src={selecteStates !== null ? SorryPineapple : NoPropertyImg} alt='no property' width={132} height={selecteStates === null ? 260 : 212} />
          <p>{selecteStates !== null ? `No Locations Found.` : "Please select state to view properties."}</p>
        </div>}

        {(locations && locations.length > 0 && !loading) && <div className={styles.locationCards}>
          {locations &&
            locations.map((location: locationProps) => (
              <div
                onClick={() => onClickHandler(location)}
                key={location.id}
                className={location.isAvailable ? styles.cardWrapper : styles.hidden}>
                <LocationCard location={location} />
              </div>
            ))}
        </div>}
      </div>
    </>
  );
};

export default Locations;
