import { AnyAction } from 'redux';
import { EDIT_PROFILE_REQUEST, EDIT_PROFILE_SUCCESS, EDIT_PROFILE_FAILURE, RESET_PROFILE_MESSAGE } from '../constants/auth.constant';

const initialState = {
  loading: false,
  data: [],
  errMsg: '',
};

export const editProfileReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case EDIT_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        errMsg: '',
      };
    case EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case EDIT_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        errMsg: action.payload,
      };
    case RESET_PROFILE_MESSAGE:
      return {
        ...state,
        loading: false,
        errMsg: '',
        data: []
      }
    default:
      return state;
  }
};
