import { AnyIfEmpty } from 'react-redux';
import {
    GET_UNITS_FAILURE,
    GET_UNITS_REQUEST,
    GET_UNITS_SUCCESS,
    GET_UNIT_FAILURE,
    GET_UNIT_REQUEST,
    GET_UNIT_SUCCESS,
    CLEAR_UNIT_STATE,
    GET_TIME_ID_REQUEST,
    GET_TIME_ID_SUCCESS,
    GET_TIME_ID_FAILURE,
    GET_TOUR_EXPERIENCE_MESSAGE_REQUEST,
    GET_TOUR_EXPERIENCE_MESSAGE_SUCCESS,
    GET_TOUR_EXPERIENCE_MESSAGE_FAILURE,
    CLEAR_PREVIOUS_UNIT_DATA,
} from '../constants/unit.constant';

const initialState = {
    units: {
        loading: false,
        data: [],
        error: '',
    },
    unit: {
        loading: false,
        data: {},
        error: '',
    },
};

const unitReducer = (state = initialState, action: AnyIfEmpty<object>) => {
    switch (action.type) {
        // Get Units
        case GET_UNITS_REQUEST:
            return {
                ...state,
                units: {
                    ...state.units,
                    loading: true,
                },
            };
        case CLEAR_PREVIOUS_UNIT_DATA:
            return {
                ...state,
                unit: {
                    ...state.unit,
                    loading: false,
                    data: {},
                    error: '',
                },
            };

        case GET_UNITS_SUCCESS:
            return {
                ...state,
                units: {
                    ...state.units,
                    loading: false,
                    data: action.payload,
                    error: '',
                },
            };
        case GET_UNITS_FAILURE:
            return {
                ...state,
                units: {
                    ...state.units,
                    loading: false,
                    data: [],
                    error: action.payload,
                },
            };
        ///////////////////////////

        case GET_TIME_ID_REQUEST:
            return {
                ...state,
                units: {
                    ...state.units,
                    loading: true,
                },
            };

        case GET_TIME_ID_SUCCESS:
            return {
                ...state,
                units: {
                    ...state.units,
                    loading: false,
                    data: action.payload,
                    error: '',
                },
            };
        case GET_TIME_ID_FAILURE:
            return {
                ...state,
                units: {
                    ...state.units,
                    loading: false,
                    data: [],
                    error: action.payload,
                },
            };
        // Get Unit
        case GET_UNIT_REQUEST:
            return {
                ...state,
                unit: {
                    ...state.unit,
                    loading: true,
                },
            };

        case GET_UNIT_SUCCESS:
            return {
                ...state,
                unit: {
                    ...state.unit,
                    loading: false,
                    data: action.payload,
                    error: '',
                },
            };
        case GET_UNIT_FAILURE:
            return {
                ...state,
                unit: {
                    ...state.unit,
                    loading: false,
                    data: {},
                    error: action.payload,
                },
            };

        case CLEAR_UNIT_STATE:
            return {
                units: {
                    loading: false,
                    data: [],
                    error: '',
                },
                unit: {
                    loading: false,
                    data: {},
                    error: '',
                },
            };
        case GET_TOUR_EXPERIENCE_MESSAGE_REQUEST:
            return {
                ...state,
                units: {
                    ...state.units,
                    loading: true,
                },
            };

        case GET_TOUR_EXPERIENCE_MESSAGE_SUCCESS:
            return {
                ...state,
                units: {
                    ...state.units,
                    loading: false,
                    data: action.payload,
                    error: '',
                },
            };
        case GET_TOUR_EXPERIENCE_MESSAGE_FAILURE:
            return {
                ...state,
                units: {
                    ...state.units,
                    loading: false,
                    data: [],
                    error: action.payload,
                },
            };

        default:
            return state;
    }
};

export default unitReducer;
