import axios from 'axios';
// import {
//     STRIPE_IDENTIFICATION_VERIFICATION_REQUEST,
//     STRIPE_IDENTIFICATION_VERIFICATION_SUCCESS,
//     STRIPE_IDENTIFICATION_VERIFICATION_FAILURE,
// } from '../constants/stripe.constant';

// const stripeVerificationRequest = () => {
//     return {
//         type: STRIPE_IDENTIFICATION_VERIFICATION_REQUEST,
//     };
// };

// const stripeVerificationSuccess = (data: any) => {
//     return {
//         type: STRIPE_IDENTIFICATION_VERIFICATION_SUCCESS,
//         payload: data,
//     };
// };

// const stripeVerificationFailure = (error: any) => {
//     return {
//         type: STRIPE_IDENTIFICATION_VERIFICATION_FAILURE,
//         payload: error,
//     };
// };

export const getStripeIDVerificationSecret = async () => {
    return await axios
        .post(`/verification/create-verification-session`)
        .then((response) => response.data)
        .catch((error) => console.log(error));
};

export const getVerificationSession = async (id: string) => {
    return await axios
        .get(`/verification/verification-report/${id}`)
        .then((response) => response.data)
        .catch((error) => console.log(error));
};

export const getCreditCardSecret = async () => {
    return await axios
        .get(`/verification/create-creditcard-secret`)
        .then((response) => response.data)
        .catch((error) => error);
};

export const getSetupIntent = async (id: string) => {
    return await axios
        .get(`/verification/verify-setup-intent/${id}`)
        .then((response) => response.data)
        .catch((error) => console.log(error));
};

export const createStripeCustomer = async (token: string, firstName: string, lastName: string, email: string) => {
    return await axios
        .post('transactions/create-stripe-customer', { token, firstName, lastName, email })
        .then((response) => response.data)
        .catch((error) => console.log(error));
};
