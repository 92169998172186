import React from 'react';

interface EditIconProps {
  width?: number;
  height?: number;
  fill?: string;
}

const EditIcon = ({ width = 18, height = 18, fill = "#8C8D90" }: EditIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1906_10814)">
      <path
        d="M3.23958 15.1556C3.41958 15.1556 3.45558 15.1376 3.61758 15.1016L6.85758 14.4536C7.19958 14.3636 7.54158 14.2016 7.81158 13.9316L15.6596 6.08362C16.8656 4.87762 16.8656 2.80762 15.6596 1.60162L14.9936 0.899617C13.7876 -0.306383 11.6996 -0.306383 10.4936 0.899617L2.64558 8.76562C2.39358 9.01762 2.21358 9.37762 2.12358 9.71962L1.43958 12.9956C1.34958 13.6076 1.52958 14.2016 1.96158 14.6336C2.30358 14.9756 2.80758 15.1556 3.23958 15.1556ZM3.85158 10.0616L11.6996 2.19562C12.2216 1.67362 13.1756 1.67362 13.6796 2.19562L14.3636 2.87962C14.9756 3.49162 14.9756 4.35562 14.3636 4.94962L6.53358 12.8156L3.20358 13.3736L3.85158 10.0616Z"
        fill={fill}
      />
      <path
        d="M15.5875 16.2715H2.30345C1.78145 16.2715 1.43945 16.6135 1.43945 17.1355C1.43945 17.6575 1.87145 17.9995 2.30345 17.9995H15.5155C16.0375 17.9995 16.4695 17.6575 16.4695 17.1355C16.4515 16.6135 16.0195 16.2715 15.5875 16.2715Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_1906_10814">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default EditIcon;
