import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import ReactCalendar from 'react-calendar';
import { AnyIfEmpty } from 'react-redux';

interface availabilities {
    startDate: moment.MomentInput;
    endDate: moment.MomentInput;
}
interface availabilitiesId extends availabilities {
    id: number;
}
interface PropsCalendar {
    setAvailabilityId: React.Dispatch<React.SetStateAction<never[]>>;
    setSelectedDate: React.Dispatch<React.SetStateAction<string>>;
    setSelectedSlots: React.Dispatch<React.SetStateAction<string>>;
    availabilities: AnyIfEmpty<object>;
    setShowTime: (arg: boolean) => void;
    selectedDate: string;
}

const Calendar = ({ setAvailabilityId, setSelectedDate, availabilities, setShowTime, selectedDate, setSelectedSlots }: PropsCalendar) => {
    const [value, onChange] = useState();

    const sortedAvailabilities = useMemo(() => {
        if (availabilities && availabilities.length) {
            return [...availabilities].sort(
                (a: { startDate: string }, b: { startDate: string }) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
            );
        }
        return [];
    }, [availabilities]);

    useEffect(() => {
        const startDate = moment(selectedDate ? selectedDate : sortedAvailabilities[0]?.startDate).toDate();
        if (moment(startDate, 'YYYY-MM-DD').isBefore(moment())) {
            handleChange(moment().toDate());
        } else {
            handleChange(startDate);
        }
    }, [sortedAvailabilities]);

    const handleChange = (e: AnyIfEmpty<object>) => {
        setShowTime(true);
        onChange(e);
        setSelectedDate(e);
        setSelectedSlots('');

        const currentDate = moment(e).format('YYYY-MM-DD');
        const availabilityIds = availabilities.filter((availability: availabilitiesId) => {
            const startDate = moment(availability.startDate).subtract(1, 'days').format('YYYY-MM-DD');
            const endDate = moment(availability.endDate).add(1, 'days').format('YYYY-MM-DD');
            const isBetween = moment(currentDate).isBetween(startDate, endDate);
            if (isBetween) {
                return availability.id;
            }
            return false;
        });
        setAvailabilityId(availabilityIds);
    };

    const checkIsAvailable = (date: Date) => {
        const currentDate = moment(date).format('YYYY-MM-DD');
        if (currentDate < moment().format('YYYY-MM-DD')) return true;
        const filterDate =
            availabilities &&
            availabilities.filter((item: availabilitiesId) => {
                const startDate = moment(item.startDate).subtract(1, 'days').format('YYYY-MM-DD');
                const endDate = moment(item.endDate).add(1, 'days').format('YYYY-MM-DD');
                const isBetween = moment(currentDate).isBetween(startDate, endDate);
                return isBetween;
            });
        if (filterDate && filterDate.length > 0) {
            return false;
        } else {
            return true;
        }
    };

    return (
        <div>
            <ReactCalendar
                tileDisabled={({ date }) => checkIsAvailable(date)}
                next2Label={null}
                prev2Label={null}
                onChange={handleChange}
                value={selectedDate ? new Date(moment(selectedDate).format()) : value}
                allowPartialRange={true}
            />
        </div>
    );
};

export default Calendar;
