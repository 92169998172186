import React from 'react';

interface UpArrowIconProps {
  width?: number;
  height?: number;
  fill?: string;
}

const UpArrowIcon = ({ width = 24, height = 24, fill = 'black' }: UpArrowIconProps) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_30_4)">
      <path d="M13.06 3.28304C12.7787 3.00214 12.3975 2.84436 12 2.84436C11.6025 2.84436 11.2212 3.00214 10.94 3.28304L5.28097 8.93904C4.99958 9.22044 4.84149 9.60209 4.84149 10C4.84149 10.398 4.99958 10.7796 5.28097 11.061C5.56237 11.3424 5.94402 11.5005 6.34197 11.5005C6.73992 11.5005 7.12158 11.3424 7.40297 11.061L10.5 7.96504V19.5C10.5 19.8979 10.658 20.2794 10.9393 20.5607C11.2206 20.842 11.6021 21 12 21C12.3978 21 12.7793 20.842 13.0606 20.5607C13.3419 20.2794 13.5 19.8979 13.5 19.5V7.96504L16.596 11.061C16.7353 11.2004 16.9007 11.3109 17.0828 11.3863C17.2648 11.4617 17.4599 11.5005 17.657 11.5005C17.854 11.5005 18.0491 11.4617 18.2312 11.3863C18.4132 11.3109 18.5786 11.2004 18.718 11.061C18.8573 10.9217 18.9678 10.7563 19.0432 10.5742C19.1186 10.3922 19.1575 10.1971 19.1575 10C19.1575 9.80299 19.1186 9.60788 19.0432 9.42583C18.9678 9.24378 18.8573 9.07837 18.718 8.93904L13.06 3.28304Z" fill={fill} />
    </g>
    <defs>
      <clipPath id="clip0_30_4">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default UpArrowIcon;
