import React, { useContext, useEffect, useRef, useState } from 'react';
import style from './Login.module.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import iconShow from '../../../images/icons/icon-show.svg';
import iconHide from '../../../images/icons/icon-hide.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth, resetValidationMessage } from '../../../redux/auth/actions/auth.action';
import { AppContext } from '../../context/AppContect';
import { AppDispatch, IRootState } from '../../../redux/rootReducer';
import ComponentLoader from '../../common/component-loader/ComponentLoader';
import Toast from '../../toast/Toast';
import { onSuccessProps } from '../interfaces/auth.interface';
import { isLoggedIn } from '../../../utils/utils';
import { CustomizedState } from '../signup/Signup';

const Login = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();

  const state = (location.state as CustomizedState) || false;
  const { destinationURL, modifiedAppointment } = state;

  const loginError = useSelector((state: IRootState) => state.authReducer.loginError);
  const loading = useSelector((state: { authReducer: { loadingLogin: boolean } }) => state.authReducer.loadingLogin);

  const appContext = useContext(AppContext);
  const [showPass, setShowPass] = useState(false);
  const [loginDetails, setLoginDetails] = useState({
    email: '',
    password: '',
  });

  const [unvalid, setValidation] = useState(false);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);


  useEffect(() => {
    isLoggedIn()
      ? navigate(destinationURL && !modifiedAppointment ? destinationURL : `/appointments${modifiedAppointment || ''}`)
      : navigate('/login', { state: { destinationURL, modifiedAppointment } });
  }, [modifiedAppointment]);

  // remove extra spaces at starting, ending and in between the input value
  const removeExtraSpace = (value: string) => value.trim().split(/ +/).join('');

  // set form values
  const handleLoginDetails = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();

    const { name, value } = event.target as HTMLInputElement;

    if (name === 'email') {
      setLoginDetails({ ...loginDetails, email: removeExtraSpace(value) });
    } else {
      setLoginDetails({ ...loginDetails, password: removeExtraSpace(value) });
    }
  };

  const handleSignIn = (e: React.FormEvent) => {
    e.preventDefault();

    if (loginDetails.email.length === 0 || loginDetails.password.length === 0 || loginDetails.password.length > 15) {
      setValidation(true);
      emailRef.current?.focus();
      loginDetails.email.length !== 0 && passwordRef.current?.focus();
      return;
    } else {
      dispatch(getAuth(loginDetails, onSuccess));
      setValidation(false);
    }
  };

  const onSuccess = (data: onSuccessProps) => {
    appContext.visitorAccessToken = data.token;
    appContext.visitor = data.visitor;
    if (destinationURL && typeof destinationURL === 'string' && !modifiedAppointment) {
      navigate(destinationURL);
    } else {
      if (data?.appointmentId && data?.appointmentId?.length) {
        navigate(`/map-view/${data?.appointmentId}`);

      } else {
        navigate(`/appointments${modifiedAppointment || ''}`, { replace: true });
      }
    }
  };

  useEffect(() => {
    dispatch(resetValidationMessage());
  }, []);

  return (
    <>
      {loginError && <Toast message={loginError} />}
      {loading && <ComponentLoader />}
      <div className={style.container}>
        <h2 className={style.authTitle}>Welcome!</h2>
        <p className={style.authDesc}>Enter your login credentials to sign in</p>
        <form onSubmit={handleSignIn} className={style.formWrapper}>
          <div className={style.fieldWrapper}>
            <label>Email</label>
            <div className={style.passwordWrapper}>
              <input
                value={loginDetails.email}
                type="email"
                id="email"
                name="email"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                placeholder="Enter your email address"
                onChange={handleLoginDetails}
                autoFocus
                ref={emailRef}
              />
            </div>
            {loginDetails.email.length === 0 && unvalid && <span className={style.invalidCred}>email should not be empty</span>}
          </div>
          <div className={style.fieldWrapper}>
            <label>Password</label>
            <div className={style.passwordWrapper}>
              <input
                value={loginDetails.password}
                type={showPass ? 'text' : 'password'}
                id="password"
                name="password"
                placeholder="Enter your password"
                onChange={handleLoginDetails}
                minLength={8}
                ref={passwordRef}
              />
              <div onClick={() => setShowPass(!showPass)} className={style.iconToggle}>
                <img src={!showPass ? iconHide : iconShow} alt="Eye" />
              </div>
            </div>
            {loginDetails.password.length > 15 && unvalid && (
              <span className={style.invalidCred}>password must be shorter than or equal to 15 characters</span>
            )}
            {loginDetails.password.length === 0 && unvalid && (
              <span className={style.invalidCred}>password should not be empty</span>
            )}
          </div>
          <div className={style.linkWrapper}>
            <Link className={style.forgetPassword} to="/forgot-password">
              Forgot Password?
            </Link>
          </div>
          <div className={style.submitWrapper}>
            <button type="submit">Sign In</button>
          </div>
          <div className={style.textCenter}>
            <div className={style.forgetPassword} onClick={() => navigate('/signup', { state: { destinationURL } })}>
              Create New account
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
