import { useState, useEffect, MouseEvent } from 'react';
import { LightboxProps } from './interface/lightbox.interface';
import styles from './lightbox.module.css';

const Lightbox: React.FC<LightboxProps> = (props) => {
    const { images, startIndex, showLightBox, setShowLightBox, setSelectedImageIndex } = props;
    const [selectedIndex, setSelectedIndex] = useState(startIndex);
    const [imageToShow, setImageToShow] = useState('');

    useEffect(() => {
        setSelectedIndex(startIndex);
        if (images) {
            setImageToShow(images[startIndex]);
        }
    }, [startIndex, images]);

    const hideLightBox = () => {
        setShowLightBox(false);
        setSelectedImageIndex(selectedIndex)
        document.body.classList.remove('disable-scrolling');
    };

    const showNext = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setSelectedIndex((selectedIndex + 1) % images.length);
        setImageToShow(images[(selectedIndex + 1) % images.length]);
    };

    const showPrev = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setSelectedIndex((selectedIndex - 1 + images.length) % images.length);
        setImageToShow(images[(selectedIndex - 1 + images.length) % images.length]);
    };

    return (
        <>
            {showLightBox ? (
                <div className={styles.lightbox} onClick={hideLightBox}>
                    <div className={styles.lightboxWrapper}>
                        <button className={`${styles.btn} ${styles.btnLeft}`} onClick={showPrev}>
                            {'<'}
                        </button>
                        <img className={styles.lightboxImg} src={imageToShow} alt="lightbox" />
                        <button className={`${styles.btn} ${styles.btnRight}`} onClick={showNext}>
                            {'>'}
                        </button>
                        <button className={`${styles.btn} ${styles.btnClose}`} onClick={hideLightBox}>
                            {'x'}
                        </button>
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default Lightbox;
