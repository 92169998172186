import ChangePassword from "../components/changePassword/ChangePassword"

const ChangePasswordPage = () => {
  return (
    <div >
      <ChangePassword />
    </div>
  )
}

export default ChangePasswordPage