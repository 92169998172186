import React from 'react';

interface LockIconProps {
  width?: number;
  height?: number;
  fill?: string;
}

const LockIcon = ({
  width = 26, height = 25, fill = "white" }: LockIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5625 9.375V6.80469C18.5604 5.41501 18.0075 4.08284 17.0248 3.10018C16.0422 2.11753 14.71 1.56457 13.3203 1.5625H12.8672C11.4775 1.56457 10.1453 2.11753 9.16268 3.10018C8.18003 4.08284 7.62707 5.41501 7.625 6.80469V9.375C7.0034 9.375 6.40726 9.62193 5.96772 10.0615C5.52818 10.501 5.28125 11.0971 5.28125 11.7188V18.9844C5.28332 20.1648 5.75315 21.2963 6.58782 22.1309C7.4225 22.9656 8.55397 23.4354 9.73438 23.4375H16.4531C17.6335 23.4354 18.765 22.9656 19.5997 22.1309C20.4344 21.2963 20.9042 20.1648 20.9062 18.9844V11.7188C20.9062 11.0971 20.6593 10.501 20.2198 10.0615C19.7802 9.62193 19.1841 9.375 18.5625 9.375ZM9.1875 6.80469C9.1875 5.82877 9.57518 4.89283 10.2653 4.20276C10.9553 3.51268 11.8913 3.125 12.8672 3.125H13.3203C14.2962 3.125 15.2322 3.51268 15.9222 4.20276C16.6123 4.89283 17 5.82877 17 6.80469V9.375H9.1875V6.80469ZM19.3438 18.9844C19.3438 19.751 19.0392 20.4863 18.4971 21.0284C17.955 21.5705 17.2198 21.875 16.4531 21.875H9.73438C8.96773 21.875 8.23249 21.5705 7.69039 21.0284C7.1483 20.4863 6.84375 19.751 6.84375 18.9844V11.7188C6.84375 11.5115 6.92606 11.3128 7.07257 11.1663C7.21909 11.0198 7.4178 10.9375 7.625 10.9375H18.5625C18.7697 10.9375 18.9684 11.0198 19.1149 11.1663C19.2614 11.3128 19.3438 11.5115 19.3438 11.7188V18.9844Z"
        fill={fill}
      />
      <path
        d="M13.875 16.1876V18.7501C13.875 18.9573 13.7927 19.1561 13.6462 19.3026C13.4997 19.4491 13.301 19.5314 13.0938 19.5314C12.8866 19.5314 12.6878 19.4491 12.5413 19.3026C12.3948 19.1561 12.3125 18.9573 12.3125 18.7501V16.1876C12.0146 16.0157 11.7818 15.7502 11.6502 15.4324C11.5186 15.1146 11.4955 14.7623 11.5845 14.4301C11.6735 14.0978 11.8697 13.8042 12.1426 13.5949C12.4154 13.3855 12.7498 13.272 13.0938 13.272C13.4377 13.272 13.7721 13.3855 14.0449 13.5949C14.3178 13.8042 14.514 14.0978 14.603 14.4301C14.692 14.7623 14.6689 15.1146 14.5373 15.4324C14.4057 15.7502 14.1729 16.0157 13.875 16.1876Z"
        fill={fill}
      />
    </svg>
  );
};

export default LockIcon;
