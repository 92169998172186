import React, { useEffect, useRef, useState } from 'react';
import style from '../login/Login.module.css';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch, IRootState } from '../../../redux/rootReducer';
import { useSelector } from 'react-redux';
import { forgotPassword, resetValidationMessage } from '../../../redux/auth/actions/forgotPassword.action';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [unvalid, setValidation] = useState(false);
    const emailRef = useRef<HTMLInputElement>(null);

    const dispatch: AppDispatch = useDispatch();

    const data = useSelector((state: IRootState) => state.forgotPassword.data);

    // remove extra spaces at starting, ending and in between the input value
    const removeExtraSpace = (value: string) => value.trim().split(/ +/).join("");

    const handleForgotDetails = (event: React.FormEvent<HTMLInputElement>) => {
        dispatch(resetValidationMessage());
        setEmail(removeExtraSpace((event.target as HTMLInputElement).value))
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (email.length === 0) {
            setValidation(true);
            emailRef.current?.focus();
            return;
        } else {
            unvalid && setValidation(false);
            dispatch(forgotPassword({ email }));
        }
    };

    useEffect(() => {
        dispatch(resetValidationMessage());
    }, []);

    return (
        <>
            <div className={style.container}>
                <h2 className={style.authTitle}>Forgot Password?</h2>
                <p className={style.authDesc}>Enter your email to reset password.</p>
                <form onSubmit={submitHandler} className={style.formWrapper}>
                    <div className={style.fieldWrapper}>
                        <label>Email</label>
                        <input
                            value={email}
                            onChange={handleForgotDetails}
                            type="email"
                            name="email"
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                            placeholder="Enter your email address"
                            autoFocus
                            ref={emailRef}
                        />
                    </div>
                    <p className={data.statusCode === 200 ? style.success : style.error}>
                        {data.statusCode === undefined ? ''
                            :
                            data.statusCode === 200 ? `Link has been shared with your email.`
                                :
                                data.statusCode === 404 && email.length !== 0 ? `This email address is not registered with pineapple! Kindly enter a registered email address` : ''
                        }
                    </p>
                    {email.length === 0 && unvalid && <p className={style.error}>email should not be empty</p>}
                    <Link className={style.forgetPassword} to="/login">
                        Want to sign in?
                    </Link>
                    <div className={style.submitWrapper}>
                        <button type="submit">Submit</button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default ForgotPassword;
