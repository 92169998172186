import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import ComingSoon from '../../components/comingSoon/ComingSoon'
import logo from '../../images/core/pineapple-logo-black-text.svg';
import './ComingSoon.css'

const ComingSoonPage = () => {

  return (
    <div>
        <ComingSoon />
    </div>
  )
}

export default ComingSoonPage