import axios from 'axios';
import { Dispatch } from 'react';
import { AnyIfEmpty } from 'react-redux';
import {
    GET_UNITS_BY_LOCATION_ID_FAILURE,
    GET_UNITS_BY_LOCATION_ID_REQUEST,
    GET_UNITS_BY_LOCATION_ID_SUCCESS,
} from '../constants/unitsByLocationId.constant';

const getUnitsByLocationIdRequest = () => {
    return {
        type: GET_UNITS_BY_LOCATION_ID_REQUEST,
    };
};

const getUnitsByLocationIdSuccess = (unitsByLocationIdData: AnyIfEmpty<object>) => {
    return {
        type: GET_UNITS_BY_LOCATION_ID_SUCCESS,
        payload: unitsByLocationIdData,
    };
};

const getUnitsByLocationIdFailure = (unitsByLocationIdError: string) => {
    return {
        type: GET_UNITS_BY_LOCATION_ID_FAILURE,
        payload: unitsByLocationIdError,
    };
};

export const getUnitsByLocationId = (locationId: string | undefined, filters?: AnyIfEmpty<object>) => {
    return async (dispatch: Dispatch<AnyIfEmpty<object>>) => {
        dispatch(getUnitsByLocationIdRequest());
        return await axios
            .get(`units/location/${locationId}`, {
                params: filters,
            })
            .then((response) => dispatch(getUnitsByLocationIdSuccess(response.data)))
            .catch((error) => dispatch(getUnitsByLocationIdFailure(error.message)));
    };
};
