import React from 'react';

interface ThreeDotsIconProps {
  width?: number;
  height?: number;
  fill?: string;
}

const ThreeDotsIcon = ({ width = 17, height = 17, fill = "#D1D2D3" }: ThreeDotsIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8.5" cy="1.5" r="1.5" fill={fill} />
    <circle cx="8.5" cy="8.5" r="1.5" fill={fill} />
    <circle cx="8.5" cy="15.5" r="1.5" fill={fill} />
  </svg>
);

export default ThreeDotsIcon;
