import React from 'react';
import Footer from '../../template/layout/footer/Footer';
import Header from '../../template/layout/header/Header';
import { Link } from 'react-router-dom';
import styles from './NotFound.module.css';
import icon404 from '../../images/core/pineapple-404.gif';

const NotFound = () => {
    return (
        <>
            <Header />
            <div className={styles.container}>
                <img className={styles.notFoundImage} src={icon404} alt="404" />
                <h2 className={styles.textLg}>404</h2>
                <p>Ooops!...Page not found.</p>
                <Link to="/" className={styles.btn}>
                    Back to Home
                </Link>
            </div>
            <Footer />
        </>
    );
};

export default NotFound;
