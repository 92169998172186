import axios from 'axios';
import { Dispatch } from 'react';
import { AnyIfEmpty } from 'react-redux';
import { FORGOT_PASSWORD_FAILURE, FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, RESET_VALIDATION_MESSAGE } from '../constants/auth.constant';

const forgotPasswordRequest = () => {
    return {
        type: FORGOT_PASSWORD_REQUEST,
    };
};

const forgotPasswordSuccess = (data: AnyIfEmpty<object>) => {
    return {
        type: FORGOT_PASSWORD_SUCCESS,
        payload: data,
    };
};

const forgotPasswordFailure = (errMsg: AnyIfEmpty<object>) => {
    return {
        type: FORGOT_PASSWORD_FAILURE,
        payload: errMsg,
    };
};

export const resetValidationMessage = () => {
    return {
        type: RESET_VALIDATION_MESSAGE,
    };
};

export const forgotPassword = (loginDetails: AnyIfEmpty<object>) => {
    return async (dispatch: Dispatch<AnyIfEmpty<object>>) => {
        dispatch(forgotPasswordRequest());
        await axios
            .post('/visitor/forgot-password', loginDetails)
            .then((response) => {
                dispatch(forgotPasswordSuccess(response.data));
            })
            .catch((err) => dispatch(forgotPasswordFailure(err)));
    };
};
