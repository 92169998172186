import moment from "moment";

export const generateTimeSlots = (startTime: string, endTime: string): { value: string; label: string }[] => {
    const timeSlots: { value: string; label: string }[] = [];
    let currentTime = new Date(`2000-01-01T${moment(startTime, 'HH:mm').format('HH:mm')}`);

    while (currentTime <= new Date(`2000-01-01T${endTime}`)) {
        const hour = currentTime.getHours().toString().padStart(2, '0');
        const minute = currentTime.getMinutes().toString().padStart(2, '0');
        const timeLabel = `${hour}:${minute}`;

        timeSlots.push({ value: timeLabel, label: timeLabel });

        currentTime.setTime(currentTime.getTime() + 60 * 60 * 1000);
    }

    return timeSlots;
};
export const authToken = () => {
    return localStorage.getItem('visitorAccessToken');
};

export const isLoggedIn = () => {
    return authToken() ? true : false;
};
