import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from "react-hook-form";
import { AppDispatch, IRootState } from '../../redux/rootReducer';
import Toast from '../toast/Toast';
import { REGEX } from '../../constants/regex';
import iconShow from '../../images/icons/icon-show.svg';
import iconHide from '../../images/icons/icon-hide.svg';
import { changePassword, resetChangePasswordMessage } from '../../redux/auth/actions/changePassword.action';
import style from './changePassword.module.css'

const ChangePassword = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const changePasswordError = useSelector((state: IRootState) => state.changePasswordReducer.errMsg);
  const changePasswordResponse = useSelector((state: IRootState) => state.changePasswordReducer.data);

  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { isValid, errors },
    control,
    trigger,
    reset,
  } = useForm({
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    mode: "onChange",
  });

  const [showPass, setShowPass] = useState<boolean>(false);
  const [showNewPass, setShowNewPass] = useState<boolean>(false);
  const [showConfirmPass, setShowConfirmPass] = useState<boolean>(false);

  const onSuccess = () => {
    localStorage.clear()
    setTimeout(() => {
      navigate('/login');
    }, 1000);
  }

  useEffect(() => {
    dispatch(resetChangePasswordMessage());
  }, []);

  const onSubmit = (data: { oldPassword: string, confirmPassword: string, newPassword: string }) => {
    dispatch(changePassword({ currentPassword: data.oldPassword, confirmedPassword: data.confirmPassword, newPassword: data.newPassword }, onSuccess));
  }

  return (
    <div className={style.changePasswordContainer}>
      <div className={style.innerContainer}>

        <form onSubmit={handleSubmit(onSubmit)} className={style.formWrapper}>
          <h2 className={style.h2}>Change Password</h2>
          <Controller
            name="oldPassword"
            control={control}
            rules={{
              required: "Current password is required"
            }}
            render={({ field: { onChange, value } }) => {
              return (<div className={style.fieldWrapper}>
                <label>Password</label>
                <div className={style.passwordWrapper}>
                  <input
                    value={value}
                    onChange={(e) => onChange(e)}
                    type={showPass ? 'text' : 'password'}
                    id="oldPassword"
                    name="oldPassword"
                    placeholder="Enter your current password"
                  />
                  <div onClick={() => setShowPass(!showPass)} className={style.iconToggle}>
                    <img src={!showPass ? iconHide : iconShow} alt="Eye" />
                  </div>
                </div>
                {errors?.oldPassword && <p className={style.invalidCred}>{errors?.oldPassword?.message}</p>}
              </div>)
            }}
          />



          <Controller
            name="newPassword"
            control={control}
            rules={{
              required: "New password is required",
              pattern: {
                value: REGEX.PASSWORD,
                message:
                  "The password must contain at least one character, one number, one special character, and be between 8 and 12 characters in length",
              },
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <div className={`${style.fieldWrapper} ${style.newPasswordFieldWrapper}`}>
                  <label>New Password</label>
                  <div className={style.passwordWrapper}>
                    <input
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        setValue("newPassword", e.target.value);
                        getValues("confirmPassword") !== "" &&
                          trigger("confirmPassword");
                      }}
                      type={showNewPass ? 'text' : 'password'}
                      id="newPassword"
                      name="newPassword"
                      placeholder="Enter new password"
                    />
                    <div onClick={() => setShowNewPass(!showNewPass)} className={style.iconToggle}>
                      <img src={!showNewPass ? iconHide : iconShow} alt="Eye" />
                    </div>
                  </div>
                  {errors?.newPassword && <p className={style.invalidCred}>{errors?.newPassword?.message}</p>}
                </div>
              )
            }}
          />

          <Controller
            name="confirmPassword"
            control={control}
            rules={{
              required: "Confirm password is required",
              validate: (value) =>
                value == getValues("newPassword") || "The passwords do not match",
            }}
            render={({ field: { value } }) => {
              return (
                <div className={style.fieldWrapper}>
                  <label>Password</label>
                  <div className={style.passwordWrapper}>
                    <input
                      value={value}
                      type={showConfirmPass ? 'text' : 'password'}
                      id="confirmPassword"
                      name="confirmPassword"
                      placeholder="Confirm new password"
                      onChange={(e) => {
                        setValue("confirmPassword", e.target.value, {
                          shouldValidate:
                            getValues("newPassword") !== "" ? true : false,
                        });
                      }}
                    />
                    <div onClick={() => setShowConfirmPass(!showConfirmPass)} className={style.iconToggle}>
                      <img src={!showConfirmPass ? iconHide : iconShow} alt="Eye" />
                    </div>
                  </div>
                  {errors?.confirmPassword && <p className={style.invalidCred}>{errors?.confirmPassword?.message}</p>}
                </div>
              )
            }}
          />

          <div className={style.buttonContainer}>

            <div className={style.cancelWrapper}>
              <button type="button" onClick={() => { reset(); navigate('/appointments'); }}>Cancel</button>
            </div>

            <div className={style.submitWrapper}>
              <button disabled={!isValid} type="submit">Update</button>
            </div>
          </div>


        </form>
      </div>
      {changePasswordError && <Toast message={changePasswordError} />}
      {changePasswordResponse?.statusCode === 200 && <Toast message={changePasswordResponse?.message} type='success' />}
    </div>
  )
}

export default ChangePassword