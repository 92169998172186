import axios from 'axios';
import { Dispatch } from 'react';
import { AnyIfEmpty } from 'react-redux';
import {
    GET_USER_TOUR_FAILURE,
    GET_USER_TOUR_REQUEST,
    GET_USER_TOUR_SUCCESS,
    POST_USER_TOUR_FAILURE,
    POST_USER_TOUR_REQUEST,
    POST_USER_TOUR_SUCCESS,
} from '../constants/tour.constants';

const getuserTourRequest = () => {
    return {
        type: GET_USER_TOUR_REQUEST,
    };
};

const getuserTourSuccess = (userTourSuccess: AnyIfEmpty<object>) => {
    return {
        type: GET_USER_TOUR_SUCCESS,
        payload: userTourSuccess,
    };
};

const getuserTourFailure = (userTourFailure: string) => {
    return {
        type: GET_USER_TOUR_FAILURE,
        payload: userTourFailure,
    };
};

const postUserTourRequest = () => {
    return {
        type: POST_USER_TOUR_REQUEST,
    };
};

const postUserTourSuccess = (userTourData: AnyIfEmpty<object>) => {
    return {
        type: POST_USER_TOUR_SUCCESS,
        payload: userTourData,
    };
};

const postUserTourFailure = (userTourFailure: string) => {
    return {
        type: POST_USER_TOUR_FAILURE,
        payload: userTourFailure,
    };
};

export const getUserTour = (locationId: string | undefined | null, unitId: string | undefined | null) => {
    return async (dispatch: Dispatch<AnyIfEmpty<object>>) => {
        dispatch(getuserTourRequest());
        await axios
            .get(`/user-tour/${locationId}/${unitId}`)
            .then((response) => dispatch(getuserTourSuccess(response.data)))
            .catch((err) => dispatch(getuserTourFailure(err.message)));
    };
};

export const postUserTour = (obj: AnyIfEmpty<object>) => {
    return async (dispatch: Dispatch<AnyIfEmpty<object>>) => {
        dispatch(postUserTourRequest());
        await axios
            .post('/user-tour', obj)
            .then((response) => dispatch(postUserTourSuccess(response.data)))
            .catch((err) => dispatch(postUserTourFailure(err)));
    };
};
