import axios from 'axios-observable';
import { AnyIfEmpty } from 'react-redux';

axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;

// Request interceptor
axios.interceptors.request.use(
  (config: AnyIfEmpty<object>) => {
    const accessToken = localStorage.getItem('visitorAccessToken');

    // Do something before request is sent
    const Authorization = 'Authorization';
    // const Accept = 'Accept';
    // const ContentType = 'Content-Type';
    if (!config.url.includes('https://api2.beans.ai/enterprise/v2/search/beans')) {
      config.headers[Authorization] = 'Bearer ' + accessToken;
    } else {
      const beansBasicToken = process.env.REACT_APP_BEANS_BASIC_TOKEN;
      const headers = {
        Authorization: `Basic ${beansBasicToken}`,
        'X-Beansai-Include-Notes-And-Markers': 'true',
        'X-Beansai-Include-Points-Of-Interest': 'true',
      };

      config.headers = headers;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Response interceptor
axios.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  (error) => {
    const isLoginAPI = error?.config?.url?.includes('visitor/login');
    if (error?.response?.data?.statusCode == 401 && !isLoginAPI) {
      localStorage.removeItem('visitorAccessToken');
      localStorage.removeItem('visitor');
      window.location.href = '/';
    } else {
      return Promise.reject(error);
    }
  },
);
