import axios from 'axios';
import { Dispatch } from 'redux';
import { VERIFY_USER_FAILURE, VERIFY_USER_REQUEST, VERIFY_USER_SUCCESS } from '../constants/verifyUser.constants';

const verifyUserRequest = () => {
    return {
        type: VERIFY_USER_REQUEST,
    };
};

const verifyUserSuccess = (data: any) => {
    return {
        type: VERIFY_USER_SUCCESS,
        payload: data,
    };
};

const verifyUserFailure = (errorData: any) => {
    return {
        type: VERIFY_USER_FAILURE,
        payload: errorData,
    };
};

export const verifyUser = (
    verifyUserObj: any,
    setCreditCardDetail: React.Dispatch<React.SetStateAction<boolean>> | undefined,
    setShowRentalTerm: React.Dispatch<React.SetStateAction<boolean>> | undefined,
    setVerifyUserMessage: React.Dispatch<React.SetStateAction<string>> | undefined,
    setErrorMessage: React.Dispatch<React.SetStateAction<string>> | undefined,
    setCCVerification: React.Dispatch<React.SetStateAction<boolean>> | undefined,
    setOnBoardComponent: React.Dispatch<React.SetStateAction<boolean>> | undefined,
) => {
    return async (dispatch: Dispatch<any>) => {
        dispatch(verifyUserRequest());
        setVerifyUserMessage?.('');
        return await axios
            .post('/visitor/verify-email-phone', verifyUserObj)
            .then((response) => {
                dispatch(verifyUserSuccess(response.data));
                if (response.data?.statusCode === 200) {
                    setCreditCardDetail?.(true);
                    setShowRentalTerm?.(false);
                    setErrorMessage?.('');
                    setCCVerification?.(true);
                    setOnBoardComponent?.(false);
                } else {
                    setVerifyUserMessage?.(response.data?.message);
                }
            })
            .catch((error) => {
                dispatch(verifyUserFailure(error));
            });
    };
};
