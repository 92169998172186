import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import iconHide from '../../../images/icons/icon-hide.svg';
import iconShow from '../../../images/icons/icon-show.svg';
import { resetPassword } from '../../../redux/auth/actions/resetPassword.action';
import { AppDispatch } from '../../../redux/rootReducer';
import style from './ResetPassword.module.css';
import { toast, ToastContainer } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import 'react-toastify/dist/ReactToastify.css';
import { REGEX } from '../../../constants/regex';

// Define the type for form inputs
interface FormInputs {
    password: string;
    confirmPassword: string;
}

const ResetPassword: React.FC = () => {
    const [showPass, setShowPass] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { token } = useParams();
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    // Initialize react-hook-form
    const {
        handleSubmit,
        setValue,
        getValues,
        formState: { isValid, errors },
        control,
        trigger,
    } = useForm({
        defaultValues: {
            password: '',
            confirmPassword: '',
        },
        mode: 'onChange',
    });

    // Handle form submission
    const onSubmit = (data: { password: string }) => {
        setIsLoading(true);
        dispatch(resetPassword({ password: data.password, resetToken: token }, onSuccess, onError));
    };

    const onSuccess = (data: any) => {
        setIsLoading(false);
        if (data.statusCode === 200) {
            toast.success(data.message);
            navigate('/login');
        } else {
            toast.error(data.message);
        }
    };

    const onError = (errorMessage: string) => {
        setIsLoading(false);
        toast.error(errorMessage);
    };

    return (
        <>
            <div className={style.container}>
                <h2 className={style.authTitle}>Reset Your Password</h2>
                <p className={style.authDesc}>You need to recreate a password to log in again.</p>
                <form onSubmit={handleSubmit(onSubmit)} className={style.formWrapper}>
                    {/* <div className={style.fieldWrapper}>
                    <label>Create Password</label>
                    <div className={style.passwordWrapper}>
                        <input
                            type={showPass ? 'text' : 'password'}
                            placeholder="Enter your password"
                            {...register('password', {
                                required: 'Password is required',
                                minLength: {
                                    value: 8,
                                    message: 'Password must be at least 8 characters',
                                },
                                pattern: {
                                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                    message:
                                        'Password must include at least one uppercase letter, one lowercase letter, one number, and one special character.',
                                },
                            })}
                        />
                        <div onClick={() => setShowPass(!showPass)} className={style.iconToggle}>
                            <img src={showPass ? iconShow : iconHide} alt="Eye" />
                        </div>
                    </div>
                    {errors.password && <span className={style.invalidCred}>{errors.password.message}</span>}
                </div>
                <div className={style.fieldWrapper}>
                    <label>Confirm Password</label>
                    <div className={style.passwordWrapper}>
                        <input
                            type={showConfirmPass ? 'text' : 'password'}
                            placeholder="Confirm your password"
                            {...register('confirmPassword', {
                                required: 'Confirm Password is required',
                                validate: (value) => value === password || 'Passwords do not match',
                            })}
                        />
                        <div onClick={() => setShowConfirmPass(!showConfirmPass)} className={style.iconToggle}>
                            <img src={showConfirmPass ? iconShow : iconHide} alt="Eye" />
                        </div>
                    </div>
                    {errors.confirmPassword && <span className={style.invalidCred}>{errors.confirmPassword.message}</span>}
                </div> */}

                    <Controller
                        name="password"
                        control={control}
                        rules={{
                            required: 'New password is required',
                            pattern: {
                                value: REGEX.PASSWORD,
                                message:
                                    'The password must contain at least one character, one number, one special character, and be between 8 and 12 characters in length',
                            },
                        }}
                        render={({ field: { onChange, value } }) => {
                            return (
                                <div className={`${style.fieldWrapper} ${style.newPasswordFieldWrapper}`}>
                                    <label>New Password</label>
                                    <div className={style.passwordWrapper}>
                                        <input
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e);
                                                setValue('password', e.target.value);
                                                getValues('confirmPassword') !== '' && trigger('confirmPassword');
                                            }}
                                            type={showPass ? 'text' : 'password'}
                                            id="newPassword"
                                            name="newPassword"
                                            placeholder="Enter new password"
                                        />
                                        <div onClick={() => setShowPass(!showPass)} className={style.iconToggle}>
                                            <img src={!showPass ? iconHide : iconShow} alt="Eye" />
                                        </div>
                                    </div>
                                    {errors?.password && <p className={style.invalidCred}>{errors?.password?.message}</p>}
                                </div>
                            );
                        }}
                    />

                    <Controller
                        name="confirmPassword"
                        control={control}
                        rules={{
                            required: 'Confirm password is required',
                            validate: (value) => value == getValues('password') || 'The passwords do not match',
                        }}
                        render={({ field: { value } }) => {
                            return (
                                <div className={style.fieldWrapper}>
                                    <label>Password</label>
                                    <div className={style.passwordWrapper}>
                                        <input
                                            value={value}
                                            type={showConfirmPass ? 'text' : 'password'}
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            placeholder="Confirm new password"
                                            onChange={(e) => {
                                                setValue('confirmPassword', e.target.value, {
                                                    shouldValidate: getValues('password') !== '' ? true : false,
                                                });
                                            }}
                                        />
                                        <div onClick={() => setShowConfirmPass(!showConfirmPass)} className={style.iconToggle}>
                                            <img src={!showConfirmPass ? iconHide : iconShow} alt="Eye" />
                                        </div>
                                    </div>
                                    {errors?.confirmPassword && <p className={style.invalidCred}>{errors?.confirmPassword?.message}</p>}
                                </div>
                            );
                        }}
                    />

                    <div className={style.submitWrapper}>
                        <button type="submit" disabled={!isValid || isLoading}>
                            Reset Password
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default ResetPassword;
