import React from 'react';
import styles from './ComponentLoader.module.css';
import loader from '../../../images/core/loader.gif';

interface ComponentLoaderProps {
    showMsg?: boolean;
    fullpageLoader?: boolean;
}

const ComponentLoader = ({ showMsg, fullpageLoader=false }: ComponentLoaderProps) => {
    return (
        <div className={`${styles.wrapper} ${fullpageLoader ?  styles.fullpageLoader : styles.noFullpageLoader} loader `}>
            <img className={styles.loaderImg} src={loader} alt="Loader" />
            {showMsg && <p className={styles.title}>This might take some time. Please wait.</p>}
        </div>
    );
};

export default ComponentLoader;
