import React, { useEffect, useRef, useState } from 'react';
import PineappleDoor from "../../images/pineapple-door.jpg";
import PineappleChill from "../../images/pineapple-chill.jpg";
import PineappleCapture from "../../images/pineapple-capture-photo.jpg";
import PineappleInterviewee from "../../images/pineapple-interviewee.jpg";
import PineappleLogo from '../../images/core/pineapple-logo-black-text.svg';
import styles from './TourCompletionStepper.module.css';
import CameraIcon from '../icons/CameraIcon';
import Popup from '../common/popup/Popup';
import Webcam from 'react-webcam';
import CloseIcon from '../../images/icon-close.svg';
import CamLayer from '../../images/cam-layer.svg';
import Toast from '../toast/Toast';
import { useParams } from 'react-router-dom';
import Loader from '../common/loader/Loader';
import { AccessCodeProps, TourDetails, requestAccessCode, storeTourDetails } from '../../redux/appointment/actions/appointment.action';
import { AppDispatch } from '../../redux/rootReducer';
import { useDispatch } from 'react-redux';
import { access } from 'fs/promises';

const TourCompletionStepper = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [showWebCam, setShowWebCam] = useState(false);
  const [newProfile, setNewProfile] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('');
  const [hasMediaAccess, setHasMediaAccess] = useState(false);
  const [accessCode, setAccessCode] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const dispatch: AppDispatch = useDispatch();
  const webcamRef = useRef<Webcam>(null);

  const appointmentId = useParams().id;

  const videoConstraints: MediaTrackConstraints | boolean = {
    facingMode: 'user', // or 'environment' for rear camera
  };

  const capturePhoto = () => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      setNewProfile(imageSrc);
    };
  };


  const submitPhoto = () => {
    const newUserProfile = newProfile;
    setFormData({
      ...formData,
      kioskImage: newUserProfile
    });
    setNewProfile('')
    setShowWebCam(false)
  }

  const requestCameraPermission = () => {

    const askCameraPermission = async (): Promise<MediaStream | null> => await navigator.mediaDevices.getUserMedia({ video: true });

    let localStream: MediaStream | null;
    askCameraPermission().then(() => {
    }).then(() => {
      setHasMediaAccess(true);
      localStream?.getTracks().forEach(track => {
        track.stop();
      });
    }).catch((error) => {
      if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
        setShowToast && setShowToast(true);
        setHasMediaAccess(false);
        setToastMessage('Camera access denied! Please grant access to your camera to continue.')
      }
    })
  };


  const [formData, setFormData] = useState({
    closedDoors: "",
    kioskImage: "",
    returnedKeys: ""
  });

  const ModalBody = () => {
    return (
      <div className={styles.modalBodyContainer}>
        <div className={styles.modalHeader}>
          <h3>Upload Selfie</h3>

          <div className={styles.closeIcon} onClick={() => {
            setShowWebCam(false);
            setNewProfile('');
          }}>
            <img src={CloseIcon} alt='close-icon' width={20} height={20} />
          </div>
        </div>
        <div className={styles.modalBodyInnerContainer}>

          {!newProfile &&
            <>
              <div className={styles.camLayer}>
                <img src={CamLayer} alt='layer' width={684} height={480} />
              </div>
              <Webcam
                audio={false}
                className={styles.webCamContainer}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                ref={webcamRef}
              />
            </>
          }

          {newProfile && <div className={styles.capturedImage}>
            <img src={newProfile} alt='' width={640} height={480} />
          </div>}
        </div>
        <div className={styles.modalFooter}>
          {newProfile && <button className={styles.retakeBtn} onClick={() => setNewProfile('')}>Recapture Photo</button>}
          <button className={newProfile ? styles.submitBtn : styles.captureBtn} onClick={newProfile ? submitPhoto : capturePhoto}>{!!newProfile ? 'Submit my picture' : 'Capture photo'}</button>
        </div>
      </div>
    )
  }

  const onDoorClose = (value: string) => {
    setFormData({
      ...formData,
      closedDoors: value
    });
    if (value === 'true') {
      setActiveStep(activeStep + 1);
    }
  }

  const onTourSuccess = () => {
    setIsLoading(false);
    setActiveStep(activeStep + 1);
  }

  const onTourFailure = (message: string) => {
    setIsLoading(false);
    setShowToast(true);
    setToastMessage(message);
  }

  const onReturnKeys = async (value: string) => {
    setFormData({
      ...formData,
      returnedKeys: value
    });

    setIsLoading(true);
    const tourInfo: TourDetails = {
      appointmentId: appointmentId?.toString() as string,
      isDoorClosed: formData?.closedDoors === 'true' ? true : false,
      isKeyReturn: value === 'true' ? true : false
    }
    await dispatch(storeTourDetails(tourInfo, onTourSuccess, onTourFailure));


  }

  useEffect(() => {
    requestCameraPermission();
  }, []);

  const base64ToFile = (base64String: string, fileName: string, contentType: string): File => {
    const trimmedBase64String = base64String.trim();

    if (!trimmedBase64String.startsWith('data:image/jpeg;base64,')) {
      throw new Error('Invalid base64 string format');
    }
    const byteCharacters = atob(trimmedBase64String.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: contentType });
    return new File([blob], fileName, { type: contentType });
  }

  const onSuccess = (accessCode: string) => {
    setIsLoading(false);
    setAccessCode(accessCode);
    setActiveStep(activeStep + 1);
  }

  const onFailure = (errMsg: string) => {
    setShowToast(true);
    setToastMessage(errMsg);
    setIsLoading(false)
  }

  const onUploadImage = async () => {
    setIsLoading(true);
    const newUserProfile = formData.kioskImage;

    const file = await base64ToFile(newUserProfile, 'user', 'image/jpeg');
    const appointmentObj: AccessCodeProps = {
      file: file,
      appointmentId: appointmentId && appointmentId as string || "",
    }

    dispatch(requestAccessCode(appointmentObj, onSuccess, onFailure));
  }

  useEffect(() => {
    if (showToast) {
      setTimeout(() => {
        setShowToast(false)
        setToastMessage('');
        setToastType('error');
      }, 4000);
    }
  }, [showToast]);

  const onTourCompletion = async () => {

  }

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.tourCompletionStepper}>
        <div className={styles.innerContainer}>
          <div className={styles.imageContainer}>
            <img src={activeStep === 1 ? PineappleDoor : activeStep === 2 ? formData?.kioskImage ? formData?.kioskImage : PineappleCapture : activeStep === 3 ? formData?.kioskImage : activeStep === 4 ? PineappleInterviewee : activeStep === 5 ? PineappleChill : ''}
              alt='pineapple-images'
              width={684}
              height={449}
            />
          </div>

          <div className={styles.lowerContainer}>
            <div className={styles.pineappleLogo}>
              <img src={PineappleLogo} width={237} height={72} alt="pineapple-logo" />
            </div>

            {/* Step 1 */}
            {activeStep === 1 && <div className={styles.formStepper}>
              <div className={styles.formControl}>
                <p>Did you close and lock the door to all apartment units you visited today?</p>
              </div>

              <div className={styles.btnWrapper}>
                <button className={formData.closedDoors === 'true' ? styles.active : ''} onClick={() => onDoorClose('true')}>Yes, I closed</button>
                <button className={formData.closedDoors === 'false' ? styles.active : ''} onClick={() => onDoorClose('false')}>Umm, I forget</button>
              </div>
            </div>}


            {/* step 2 */}
            {activeStep === 2 && <div className={styles.formStepper}>
              <div className={styles.formControl}>
                <p><span>Please, put your keys back in the KIOSK
                  and take a picture  </span><CameraIcon fillColor='#E59236' /></p>
              </div>

              <div className={styles.uploadImageButton} onClick={() => {
                if (hasMediaAccess) {
                  setShowWebCam(true)
                } else {
                  setToastMessage('Camera access denied! Please grant access to your camera to continue.')
                  setShowToast(true);
                  setToastType('error')
                }
              }}>
                <CameraIcon height={51} width={51} fillColor='#3674EE' />
                <p>Click to capture the image</p>
              </div>

              <button className={styles.uploadBtn} onClick={onUploadImage}
                disabled={!(!showWebCam && !!formData?.kioskImage)}
              >Upload the image</button>
            </div>}

            {/* step 3  */}
            {activeStep === 3 && <div className={styles.formStepper}>
              <div className={styles.formControl}>
                <p>Done! We’ve captured and verified your pictured</p>
                <p className={styles.accessCode}>Your Access code is : <b>{accessCode}</b></p>
              </div>

              <div className={styles.btnWrapper}>
                <button onClick={() => setActiveStep(activeStep + 1)}>Next</button>
              </div>
            </div>}

            {/* step 4 */}
            {activeStep === 4 && <div className={styles.formStepper}>
              <div className={styles.formControl}>
                <p>Do you confirm that you have returned all keys?</p>
              </div>

              <div className={styles.btnWrapper}>
                <button className={formData.returnedKeys === 'true' ? styles.active : ''} onClick={() => onReturnKeys('true')}>Yes, I confirm</button>
                <button className={formData.returnedKeys === 'false' ? styles.active : ''} onClick={() => onReturnKeys('false')}>No, Let me return</button>
              </div>

            </div>}

            {/* step 5 */}
            {activeStep === 5 && <div className={styles.formStepper}>
              <div className={styles.formControl}>
                <p>Thank you for visiting property!</p>
              </div>

              <div className={styles.btnWrapper}>
                <a className={formData.returnedKeys === 'true' ? styles.active : ''} href='/'>Back to appointments</a>
              </div>

            </div>}

          </div>
        </div>
        <Popup open={showWebCam} setOpen={setShowWebCam} bodyData={<ModalBody />} />
        {showToast && <Toast message={toastMessage} type={toastType} />}
      </div >
    </>
  )
}

export default TourCompletionStepper