import React from 'react';
import styles from './OuterHeader.module.css';
import logo from '../../../images/core/pineapple-logo-black-text.svg';
import { useNavigate } from 'react-router-dom';
import telePhoneIcon from '../../../images/icons/telephone-call.svg'

const OuterHeader = () => {
    // const location = useLocation();
    const navigate = useNavigate();
    // const path = location.pathname;
    // const handleClick = () => {
    //     navigate(path === '/signup' ? '/login' : '/signup');
    // };
    return (
        <div className={styles.fluid}>
            <div className={`${styles.outerContainer} ${styles.flexContainer}`}>
                <div className={styles.logo}>
                    <img
                        onClick={() => {
                            navigate('/login');
                        }}
                        src={logo}
                        alt="pineapple logo"></img>
                </div>
                <div className={styles.btnWrapper}>
                    <a href='tel:+18887511211p800'>
                        <div className={styles.callIcon}>
                            <img src={telePhoneIcon} alt='call' width={16} height={16} />
                            <span> Help</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default OuterHeader;
