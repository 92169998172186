import React from 'react';

interface CameraIconProps {
  width?: number;
  height?: number;
  fillColor?: string;
}

const CameraIcon: React.FC<CameraIconProps> = ({
  width = 29,
  height = 29,
  fillColor = "#E59236"
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1200_59855)">
        <path
          d="M18.0117 11.9985C17.0594 11.9985 16.1344 12.1832 15.2626 12.5474C14.9739 12.668 14.8377 12.9997 14.9582 13.2884C15.0788 13.5771 15.4106 13.7131 15.6992 13.5927C16.432 13.2865 17.21 13.1313 18.0117 13.1313C21.3223 13.1313 24.0156 15.8247 24.0156 19.1353C24.0156 22.4458 21.3223 25.1392 18.0117 25.1392C14.7011 25.1392 12.0078 22.4458 12.0078 19.1353C12.0078 18.3348 12.1626 17.5578 12.4678 16.826C12.5882 16.5373 12.4518 16.2056 12.1631 16.0852C11.8745 15.965 11.5427 16.1012 11.4223 16.3899C11.0591 17.2607 10.875 18.1843 10.875 19.1353C10.875 23.0705 14.0765 26.272 18.0117 26.272C21.9469 26.272 25.1484 23.0705 25.1484 19.1353C25.1484 15.2 21.9469 11.9985 18.0117 11.9985Z"
          fill={fillColor}
        />
        <path
          d="M18.0117 23.7798C20.5727 23.7798 22.6563 21.6963 22.6563 19.1353C22.6563 16.5742 20.5727 14.4907 18.0117 14.4907C15.4507 14.4907 13.3672 16.5742 13.3672 19.1353C13.3672 21.6963 15.4508 23.7798 18.0117 23.7798ZM18.0117 15.6235C19.9481 15.6235 21.5234 17.1989 21.5234 19.1353C21.5234 21.0716 19.9481 22.647 18.0117 22.647C16.0753 22.647 14.5 21.0716 14.5 19.1353C14.5 17.1989 16.0753 15.6235 18.0117 15.6235Z"
          fill={fillColor}
        />
        <path
          d="M26.1274 9.27979H24.4044L22.4268 5.93306C22.3249 5.76059 22.1395 5.65479 21.9392 5.65479H14.085C13.8847 5.65479 13.6993 5.76059 13.5974 5.93306L11.6198 9.27979H8.38281V8.6001C8.38281 7.47572 7.46812 6.56104 6.34375 6.56104C5.21938 6.56104 4.30469 7.47572 4.30469 8.6001V9.27979H2.87259C1.28863 9.27979 0 10.5684 0 12.1524V26.1181C0 27.7021 1.28863 28.9907 2.87259 28.9907H26.1274C27.7113 28.9907 28.9999 27.7021 28.9999 26.1181V12.1524C29 10.5684 27.7113 9.27979 26.1274 9.27979ZM14.4082 6.7876H21.6159L23.0886 9.27979H12.9355L14.4082 6.7876ZM5.4375 8.6001C5.4375 8.10041 5.84407 7.69385 6.34375 7.69385C6.84343 7.69385 7.25 8.10041 7.25 8.6001V9.27979H5.4375V8.6001ZM27.8672 26.1181C27.8672 27.0775 27.0867 27.8579 26.1274 27.8579H2.87259C1.91326 27.8579 1.13281 27.0774 1.13281 26.1181V12.1524C1.13281 11.193 1.91326 10.4126 2.87259 10.4126H26.1274C27.0867 10.4126 27.8671 11.193 27.8671 12.1524V26.1181H27.8672Z"
          fill={fillColor}
        />
        <path
          d="M18.0117 18.0308C18.6208 18.0308 19.1162 18.5263 19.1162 19.1353C19.1162 19.4481 19.3698 19.7017 19.6826 19.7017C19.9954 19.7017 20.249 19.4481 20.249 19.1353C20.249 17.9016 19.2454 16.8979 18.0117 16.8979C17.6989 16.8979 17.4453 17.1516 17.4453 17.4644C17.4453 17.7771 17.6989 18.0308 18.0117 18.0308Z"
          fill={fillColor}
        />
        <path
          d="M18.0117 4.16468C18.3245 4.16468 18.5781 3.91105 18.5781 3.59828V0.575195C18.5781 0.262426 18.3245 0.00878906 18.0117 0.00878906C17.6989 0.00878906 17.4453 0.262369 17.4453 0.575195V3.59828C17.4453 3.9111 17.6989 4.16468 18.0117 4.16468Z"
          fill={fillColor}
        />
        <path
          d="M20.707 4.16463C20.852 4.16463 20.997 4.10929 21.1075 3.99873L23.1857 1.92053C23.4069 1.69934 23.4069 1.3407 23.1857 1.11946C22.9645 0.898332 22.6058 0.898332 22.3847 1.11946L20.3065 3.19771C20.0853 3.4189 20.0853 3.77754 20.3065 3.99878C20.4171 4.10935 20.5621 4.16463 20.707 4.16463Z"
          fill={fillColor}
        />
        <path
          d="M14.9159 3.99871C15.0266 4.10927 15.1715 4.16461 15.3164 4.16461C15.4614 4.16461 15.6064 4.10927 15.7169 3.99871C15.9381 3.77752 15.9382 3.41888 15.717 3.19764L13.6388 1.11944C13.4176 0.898367 13.059 0.898311 12.8378 1.11944C12.6166 1.34062 12.6166 1.69927 12.8377 1.9205L14.9159 3.99871Z"
          fill={fillColor}
        />
        <path
          d="M7.81641 13.9243H4.87109C4.55827 13.9243 4.30469 14.178 4.30469 14.4907C4.30469 14.8035 4.55827 15.0571 4.87109 15.0571H7.81641C8.12923 15.0571 8.38281 14.8035 8.38281 14.4907C8.38281 14.1779 8.12923 13.9243 7.81641 13.9243Z"
          fill={fillColor}
        />
        <path
          d="M13.3652 15.0571C13.5142 15.0571 13.6603 14.9965 13.7657 14.8912C13.871 14.7858 13.9316 14.6402 13.9316 14.4907C13.9316 14.3418 13.871 14.1956 13.7657 14.0903C13.6603 13.985 13.5142 13.9243 13.3652 13.9243C13.2163 13.9243 13.0701 13.9849 12.9648 14.0903C12.8589 14.1956 12.7988 14.3418 12.7988 14.4907C12.7988 14.6397 12.8589 14.7858 12.9648 14.8912C13.0701 14.9965 13.2157 15.0571 13.3652 15.0571Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_1200_59855">
          <rect width="29" height="29" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CameraIcon;
