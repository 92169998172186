import { AnyAction } from 'redux';
import { GUEST_CARD_FAILURE, GUEST_CARD_REQUEST, GUEST_CARD_SUCCESS } from '../constants/guestCard.constants';

const initialState = {
    loading: false,
    data: [],
    errMsg: '',
};

export const guestCardReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case GUEST_CARD_REQUEST:
            return {
                ...state,
                loading: true,
                errMsg: '',
            };
        case GUEST_CARD_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case GUEST_CARD_FAILURE:
            return {
                ...state,
                loading: false,
                errMsg: action.payload,
            };
        default:
            return state;
    }
};
