import axios from 'axios';
import { Dispatch } from 'react';
import {
    GET_UNIT_FAILURE,
    GET_UNIT_SUCCESS,
    GET_UNIT_REQUEST,
    CLEAR_UNIT_STATE,
    GET_TIME_ID_REQUEST,
    GET_TIME_ID_SUCCESS,
    GET_TIME_ID_FAILURE,
    GET_TOUR_EXPERIENCE_MESSAGE_REQUEST,
    GET_TOUR_EXPERIENCE_MESSAGE_SUCCESS,
    GET_TOUR_EXPERIENCE_MESSAGE_FAILURE,
    CLEAR_PREVIOUS_UNIT_DATA,
} from '../constants/unit.constant';
import { AnyIfEmpty } from 'react-redux';

// Get Unit Location Request

const getTimeIdRequest = () => {
    return {
        type: GET_TIME_ID_REQUEST,
    };
};

const getTimeIdSuccess = (locationByIdData: AnyIfEmpty<object>) => {
    return {
        type: GET_TIME_ID_SUCCESS,
        payload: locationByIdData,
    };
};

const getTimeIdFailure = (locationByIdError: string) => {
    return {
        type: GET_TIME_ID_FAILURE,
        payload: locationByIdError,
    };
};

const getUnitRequest = () => {
    return {
        type: GET_UNIT_REQUEST,
    };
};

const getUnitSuccess = (locations: AnyIfEmpty<object>) => {
    return {
        type: GET_UNIT_SUCCESS,
        payload: locations,
    };
};

const getUnitFailure = (locationError: AnyIfEmpty<object>) => {
    return {
        type: GET_UNIT_FAILURE,
        payload: locationError,
    };
};

const getTourMessageRequest = () => {
    return {
        type: GET_TOUR_EXPERIENCE_MESSAGE_REQUEST,
    };
};

const getTourMessageSuccess = (getTourData: AnyIfEmpty<object>) => {
    return {
        type: GET_TOUR_EXPERIENCE_MESSAGE_SUCCESS,
        payload: getTourData,
    };
};

const getTourMessageFailure = (getTourError: string) => {
    return {
        type: GET_TOUR_EXPERIENCE_MESSAGE_FAILURE,
        payload: getTourError,
    };
};

const clearUnitStateAction = () => {
    return {
        type: CLEAR_UNIT_STATE,
    };
};

const clearPreviosUnitDataRequest = () => {
    return {
        type: CLEAR_PREVIOUS_UNIT_DATA,
    };
};

export const clearPreviosUnitData = () => {
    return (dispatch: Dispatch<any>) => {
        dispatch(clearPreviosUnitDataRequest());
    };
};

// Get Unit Location Dispatch
export const getUnit = (id: string | undefined | null) => {
    return (dispatch: Dispatch<AnyIfEmpty<object>>) => {
        dispatch(getUnitRequest());
        return axios
            .get(`/units/${id}`)
            .then((response) => {
                dispatch(getUnitSuccess(response.data));
            })
            .catch((error) => dispatch(getUnitFailure(error)));
    };
};
export const getTime = (locationId: string | undefined) => {
    return async (dispatch: AnyIfEmpty<object>) => {
        dispatch(getTimeIdRequest());
        return await axios
            .get(`/Property-office-hour/${locationId}`)
            .then((response) => {
                dispatch(getTimeIdSuccess(response.data));
            })
            .catch((err) => dispatch(getTimeIdFailure(err.message)));
    };
};
export const getTourMessage = (locationId: string | undefined) => {
    return async (dispatch: AnyIfEmpty<object>) => {
        dispatch(getTourMessageRequest());
        return await axios
            .get(`/tour-experience-message/${locationId}`)
            .then((response) => {
                dispatch(getTourMessageSuccess(response.data));
            })
            .catch((err) => dispatch(getTourMessageFailure(err.message)));
    };
};
export const clearUnitState = () => {
    return (dispatch: Dispatch<AnyIfEmpty<object>>) => dispatch(clearUnitStateAction());
};
