import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import OuterFooter from './OuterFooter/OuterFooter';
import OuterHeader from './OuterHeader/OuterHeader';
import styles from './OuterLayout.module.css';

const OuterLayout = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }, [location]);
    return (
        <div className={`${styles.outerContainer} ${styles.outerbg}`}>
            <OuterHeader />
            <Outlet />
            <OuterFooter />
        </div>
    );
};

export default OuterLayout;
