import React, {useEffect} from 'react';
import styles from './Loader.module.css';
import imgLoader from '../../../images/core/loader.gif';

interface LoaderProps {
    className?: string;
}

const Loader = ({ className }: LoaderProps) => {

    const removeScrolling = () => {
        document.body.classList.add('disable-scrolling');
    }

    useEffect(() =>{
        removeScrolling();
        
        return () => {
            document.body.classList.remove('disable-scrolling');
        };
    },[])

    return (
        <div className={className ? styles.loaderWrapper : styles.loader}>
            <img src={imgLoader} alt="Loader" />
        </div>
    );
};

export default Loader;
