import { AnyAction } from 'redux';
import { RESET_PASSWORD_FAILURE, RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS } from '../constants/auth.constant';

const initialState = {
    loading: false,
    data: [],
    errMsg: '',
};

export const resetPasswordReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case RESET_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
                data: [],
                errMsg: '',
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case RESET_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                errMsg: action.payload,
            };
        default:
            return state;
    }
};
