import { combineReducers } from 'redux';
import appointmentReducer from './appointment/reducers/appointmentReducer';
import authReducer from './auth/reducers/authReducer';
import { forgotPasswordReducer } from './auth/reducers/forgotPasswordReducer';
import { resetPasswordReducer } from './auth/reducers/resetPasswordReducer';
import { guestCardReducer } from './guestCard/reducer/guestCardReducer';
import locationReducer from './location/reducers/locationReducers';
import store from './store';
import stripeReducer from './stripe/reducers/stripeReducer';
import tourReducer from './tour/reducers/tourReducer';
import unitReducer from './unit/reducers/unitReducer';
import unitsByLocationIdReducer from './unit/reducers/unitsByLocationIdReducer';
import { AnyIfEmpty } from 'react-redux';
import { verifyUserReducer } from './verify-user/reducer/verifyUserReducer';
import { leadSourceReducer } from './lead-sources/reducer/leadSourcesReducer';
import { changePasswordReducer } from './auth/reducers/changePasswordReducer';
import { editProfileReducer } from './auth/reducers/editProfileReducer';

const appReducer = combineReducers({
    authReducer: authReducer,
    locationReducer: locationReducer,
    unitReducer: unitReducer,
    appointmentReducer: appointmentReducer,
    stripeReducer: stripeReducer,
    unitsByLocationId: unitsByLocationIdReducer,
    userTour: tourReducer,
    forgotPassword: forgotPasswordReducer,
    resetPassword: resetPasswordReducer,
    guestCard: guestCardReducer,
    verifyUser: verifyUserReducer,
    leadSourceReducer: leadSourceReducer,
    changePasswordReducer: changePasswordReducer,
    editProfileReducer: editProfileReducer
});

const rootReducer = (state: AnyIfEmpty<object>, action: AnyIfEmpty<object>) => {
    if (action.type === 'LOGOUT') {
        state = undefined;
    }
    return appReducer(state, action);
};

export const logout = () => {
    return {
        type: 'LOGOUT',
    };
};
export default rootReducer;

export type IRootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;
