import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styles from './Slider.module.css';
import { AnyIfEmpty } from 'react-redux';
import noUnitImg from '../../../images/no-unit-image.svg';

const Slider = ({ images }: AnyIfEmpty<object>) => {
    return (
        <Carousel
            className={styles.carouselStyle}
            showThumbs={false}
            autoPlay={true}
            swipeable={true}
            infiniteLoop={true}
            showArrows={false}
            showStatus={false}
            interval={3000}
            transitionTime={3000}
            emulateTouch={true}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={50}>
            {images && !!images.length ? (
                images.map((img: string, index: number) => <img className={styles.slider} key={index} src={img} alt="location" />)
            ) : (
                <img className={styles.slider} src={noUnitImg} alt="no unit" />
            )}
        </Carousel>
    );
};

export default Slider;
