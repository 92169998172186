import React from 'react';

interface TrashIconProps {
  width?: string | number;
  height?: string | number;
  fill?: string;
}

const TrashIcon = ({ width = 18, height = 18, fill = "#8C8D90" }: TrashIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.25 5.25C14.0511 5.25 13.8603 5.32902 13.7197 5.46967C13.579 5.61032 13.5 5.80109 13.5 6V14.3932C13.4785 14.7725 13.308 15.1279 13.0256 15.382C12.7433 15.6362 12.3719 15.7684 11.9925 15.75H6.0075C5.62807 15.7684 5.25674 15.6362 4.97438 15.382C4.69202 15.1279 4.52151 14.7725 4.5 14.3932V6C4.5 5.80109 4.42098 5.61032 4.28033 5.46967C4.13968 5.32902 3.94891 5.25 3.75 5.25C3.55109 5.25 3.36032 5.32902 3.21967 5.46967C3.07902 5.61032 3 5.80109 3 6V14.3932C3.02141 15.1704 3.34994 15.9074 3.91364 16.4429C4.47733 16.9783 5.23025 17.2686 6.0075 17.25H11.9925C12.7697 17.2686 13.5227 16.9783 14.0864 16.4429C14.6501 15.9074 14.9786 15.1704 15 14.3932V6C15 5.80109 14.921 5.61032 14.7803 5.46967C14.6397 5.32902 14.4489 5.25 14.25 5.25Z"
      fill={fill}
    />
    <path
      d="M15 3H12V1.5C12 1.30109 11.921 1.11032 11.7803 0.96967C11.6397 0.829018 11.4489 0.75 11.25 0.75H6.75C6.55109 0.75 6.36032 0.829018 6.21967 0.96967C6.07902 1.11032 6 1.30109 6 1.5V3H3C2.80109 3 2.61032 3.07902 2.46967 3.21967C2.32902 3.36032 2.25 3.55109 2.25 3.75C2.25 3.94891 2.32902 4.13968 2.46967 4.28033C2.61032 4.42098 2.80109 4.5 3 4.5H15C15.1989 4.5 15.3897 4.42098 15.5303 4.28033C15.671 4.13968 15.75 3.94891 15.75 3.75C15.75 3.55109 15.671 3.36032 15.5303 3.21967C15.3897 3.07902 15.1989 3 15 3ZM7.5 3V2.25H10.5V3H7.5Z"
      fill={fill}
    />
    <path
      d="M8.25 12.75V7.5C8.25 7.30109 8.17098 7.11032 8.03033 6.96967C7.88968 6.82902 7.69891 6.75 7.5 6.75C7.30109 6.75 7.11032 6.82902 6.96967 6.96967C6.82902 7.11032 6.75 7.30109 6.75 7.5V12.75C6.75 12.9489 6.82902 13.1397 6.96967 13.2803C7.11032 13.421 7.30109 13.5 7.5 13.5C7.69891 13.5 7.88968 13.421 8.03033 13.2803C8.17098 13.1397 8.25 12.9489 8.25 12.75Z"
      fill={fill}
    />
    <path
      d="M11.25 12.75V7.5C11.25 7.30109 11.171 7.11032 11.0303 6.96967C10.8897 6.82902 10.6989 6.75 10.5 6.75C10.3011 6.75 10.1103 6.82902 9.96967 6.96967C9.82902 7.11032 9.75 7.30109 9.75 7.5V12.75C9.75 12.9489 9.82902 13.1397 9.96967 13.2803C10.1103 13.421 10.3011 13.5 10.5 13.5C10.6989 13.5 10.8897 13.421 11.0303 13.2803C11.171 13.1397 11.25 12.9489 11.25 12.75Z"
      fill={fill}
    />
  </svg>
);

export default TrashIcon;
