import React, { useRef } from 'react';
import hideAccordion from '../../../images/icons/icon-hide-accordion.svg';
import showAccordion from '../../../images/icons/icon-show-accordion.svg';
import styles from '../../tourPortal/faq/Faq.module.css';
import { AnyIfEmpty } from 'react-redux';

interface AccordianProps {
    title: string;
    content: string;
    active: boolean;
    onToggle: (index: AnyIfEmpty<object>) => void;
}

const Accordian = ({ title, content, active, onToggle }: AccordianProps) => {
    const contentEl: AnyIfEmpty<object> = useRef();

    return (
        <div className={styles.questionnarieContent}>
            <button className={`${styles.accordion} ${active ? styles.active : ''}`} onClick={onToggle}>
                <p className={styles.accordion__title}>{title}</p>
                <span>
                    {active ? (
                        <img src={hideAccordion} alt="hide" className={styles.iconAccordion} />
                    ) : (
                        <img src={showAccordion} alt="hide" className={styles.iconAccordion} />
                    )}
                </span>
            </button>
            <div
                ref={contentEl}
                style={{ maxHeight: `${active ? `${contentEl.current?.scrollHeight}px` : '0px'}` }}
                className={styles.accordionContent}>
                <div ><p>{content}</p></div>
            </div>
        </div>
    );
};

export default Accordian;
