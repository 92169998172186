import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import iconBath from '../../../images/icons/icon-bathtub.svg';
import iconBeds from '../../../images/icons/icon-beds.svg';
import iconSqfeet from '../../../images/icons/icon-square-feet.svg';
import iconArrow from '../../../images/icons/slant-arrow.svg';
import { getLocationById } from '../../../redux/location/actions/location.action';
import { AppDispatch, IRootState } from '../../../redux/rootReducer';
import { getUserTour } from '../../../redux/tour/actions/tour.actions';
import { getTourMessage, getUnit } from '../../../redux/unit/actions/unit.action';
import Loader from '../../common/loader/Loader';
import Faq from './../faq/Faq';
import styles from './TourPortal.module.css';
import Dollar from '../../../images/dollar-sign.svg';
import { onChangeHandler, PointOfInterest, SelectPOIHandler } from '../interfaces/TourPortal.interface';

const TourPortal = () => {
    const apiKey = process.env.REACT_APP_BEANS_SECRET_KEY;

    const locationId = useParams().id;
    const unitId = useParams().unitid;

    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const { appointmentId } = useParams();

    const locationData = useSelector((state: IRootState) => state.locationReducer.locationById.data?.location);
    const locationFullData = useSelector((state: IRootState) => state.locationReducer.locationById.data);
    const unitsData = useSelector((state: IRootState) => state.unitReducer.unit.data?.units);
    const unitsFullData = useSelector((state: IRootState) => state.unitReducer.unit.data);
    const userTourData = useSelector((state: IRootState) => state.userTour.data?.result);
    const loading = useSelector((state: IRootState) => state.userTour.loading);
    const mapUrl = useSelector((state: IRootState) => state.locationReducer.locationById.data?.location?.mapUrl);

    const [beansAddressUrl, setBeansAddressUrl] = useState('');
    const [listOfPOI, setListOfPOI] = useState('');
    const [selectedPoi, setSelectedPoi] = useState({
        startIndex: 0,
        endIndex: 0,
        startPOI: {},
        endPOI: {},
    });
    const [allListOfPOI, setAllListOfPOI]: any = useState([]);

    useEffect(() => {
        if (unitsFullData && Object.keys(unitsFullData).length && unitsFullData.status === false && unitsFullData.statusCode === 404) {
            navigate(`/location/${locationId}`);
        } else if (
            locationFullData &&
            Object.keys(locationFullData).length &&
            locationFullData.status === false &&
            locationFullData.statusCode === 404
        ) {
            navigate('/locations');
        }
    }, [unitsFullData, locationFullData]);

    useEffect(() => {
        const address = mapUrl?.split('?')[1];
        setBeansAddressUrl(address);
    }, [mapUrl]);

    useEffect(() => {
        const fetchPOIs = async () => {
            const defaultPoiArray = [
                { label: 'Entrance', value: 'ENTRANCE', index: 0 },
                { label: 'Parking', value: 'PARKING', index: 1 },
                { label: 'Unit', value: 'UNIT', index: 2 },
            ];

            try {
                if (unitsData && beansAddressUrl && Object.keys(unitsData).length) {
                    const response = await axios.get(
                        `https://api2.beans.ai/enterprise/v2/search/beans?${beansAddressUrl}&unit=${unitsData.name}`,
                    );

                    let temp: onChangeHandler[] = [];

                    if (response?.data?.pointsOfInterest) {
                        const allAvailablePOIs: { name: string }[] = response.data.pointsOfInterest.map((obj: { name: string }) => {
                            const name = obj.name.split(':')[0].replace(/_/g, ' ');
                            return { name };
                        });
                        const allPoisName: any = allAvailablePOIs.map(({ name }) => name);
                        const uniqPOIs = allAvailablePOIs.filter(({ name }, index) => !allPoisName.includes(name, index + 1));

                        const defaultPoiArrayLength = defaultPoiArray.length;

                        let uniqPoiIndex: number = 0;

                        temp = uniqPOIs
                            .map(({ name }: PointOfInterest) => {
                                const poiWithoutUnderscore = name.includes('_') ? name.split('_').join(' ') : name;
                                const capitalizedValue =
                                    poiWithoutUnderscore?.charAt(0)?.toUpperCase() + poiWithoutUnderscore.slice(1).toLowerCase();

                                const isDuplicatePOI = defaultPoiArray.some(
                                    ({ label }) => label.toLowerCase() === capitalizedValue.toLowerCase(),
                                );

                                if (!isDuplicatePOI) {
                                    const poiObj = {
                                        label: capitalizedValue.includes(':') ? capitalizedValue.split(':')[0] : capitalizedValue,
                                        value: name.includes(' ') ? name.split(' ').join('_') : name,
                                        index: defaultPoiArrayLength + uniqPoiIndex,
                                    };
                                    uniqPoiIndex += 1;
                                    return poiObj;
                                } else {
                                    return null;
                                }
                            })
                            .filter((poi) => poi !== null) as onChangeHandler[];
                    }

                    const mergedArray = [...defaultPoiArray, ...temp];

                    setAllListOfPOI(mergedArray);
                }
            } catch (error) {
                setAllListOfPOI(defaultPoiArray);
            }
        };

        fetchPOIs();
    }, [unitsData, beansAddressUrl]);

    useEffect(() => {
        dispatch(getLocationById(locationId));
        dispatch(getUnit(unitId));
        dispatch(getUserTour(locationId, unitId));
        dispatch(getTourMessage(locationId));
    }, [locationId, unitId]);

    useEffect(() => {
        if (userTourData?.l_listOfPOI) {
            setListOfPOI(userTourData.l_listOfPOI);
        }
    }, [userTourData]);

    const officeLink = () => {
        const officeLink = locationData?.contactLink;
        window.open(officeLink, '__blank', 'noreferrer');
    };

    const appLink = async () => {
        const appLink = locationData?.appLink;
        window.open(appLink, '_blank', 'noreferrer');
        await axios.get(`appointments/ReadyToApplyClicked/${appointmentId}`);
    };

    const selfGuidedTourHandler = () => {
        const oldList = [...allListOfPOI];

        let temp = allListOfPOI.slice(selectedPoi.startIndex, selectedPoi.endIndex + 1);
        if (selectedPoi.startIndex > selectedPoi.endIndex) {
            temp = allListOfPOI.slice(selectedPoi.endIndex, selectedPoi.startIndex + 1).reverse();
        }

        const path = temp.map((el: any) => el.value).toString();

        setAllListOfPOI(oldList);
        const url = `${mapUrl}&unit=${unitsData?.name}&apiKeySecret=${apiKey}&showUnitShape=true&showPath=true&showDirections=true&path=${path}`;
        Object.keys(selectedPoi.startPOI).length && Object.keys(selectedPoi.endPOI).length && window.open(url, '__blank', 'noreferrer');
    };

    const defaultRoute = () => {
        const list = listOfPOI.split(',');
        const res = [...new Set(list)].toString();

        const url = `${mapUrl}&unit=${unitsData?.name}&apiKeySecret=${apiKey}&showUnitShape=true&showPath=true&showDirections=true&path=${res}`;

        window.open(url, '__blank', 'noopener,noreferrer');
    };

    const selectPOIHandler: SelectPOIHandler = (newValue, actionMeta) => {
        const { name } = actionMeta;

        setSelectedPoi((prevState: { startIndex: number; endIndex: number; startPOI: {}; endPOI: {} }) => ({
            ...prevState,
            [name === 'startIndex' ? 'startIndex' : 'endIndex']: newValue?.index,
            [name === 'startIndex' ? 'startPOI' : 'endPOI']: newValue,
        }));
    };

    const navigateToMarketingLink = () => navigate(`/location/${locationId}`);

    return (
        <div className={styles.container}>
            {loading ? (
                <Loader />
            ) : (
                <React.Fragment>
                    <div className={styles.tourPortalContainer}>
                        <div className={styles.innerContainer}>
                            <div className={styles.locationDetailContainer}>
                                <h2>{locationData?.name}</h2>
                                <h3>{unitsData?.name}</h3>
                                <span className={styles.propertyName}>{unitsData?.nickName}</span>
                                <div className={styles.facilities}>
                                    <span className={styles.facility}>
                                        <img src={iconSqfeet} alt="Square Feet" />
                                        {unitsData && `${unitsData.size} Ft`}
                                    </span>
                                    <span className={styles.facility}>
                                        <img src={iconBeds} alt="beds" />
                                        {unitsData &&
                                            `${+unitsData?.rooms ? +unitsData?.rooms : ''} ${!+unitsData?.rooms ? 'Studio' : +unitsData?.rooms === 1 ? 'Bed' : 'Beds'
                                            }`}
                                    </span>
                                    <span className={styles.facility}>
                                        <img src={iconBath} alt="Bath" />
                                        {(+unitsData?.baths || +unitsData?.baths === 0) && (
                                            <span>{`${+unitsData?.baths} ${+unitsData?.baths === 1 ? 'Bath' : 'Baths'}`}</span>
                                        )}
                                    </span>
                                    <span className={styles.facility}>
                                        <img src={Dollar} alt="price" />
                                        {unitsData?.price}
                                    </span>
                                </div>
                                <div className={styles.inlineItems}>
                                    {/* <button>${unitsData?.price}</button> */}
                                    <div onClick={defaultRoute} className={styles.selfGuide}>
                                        <span>Navigate to unit and amenities</span>
                                        <img src={iconArrow} alt="arrow" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.lowerContainer}>
                        <div className={styles.linkRow}>
                            <div onClick={appLink} className={styles.linkCol}>
                                <div>{`Ready to apply?\n Click Here.`}</div>
                            </div>

                            <div className={styles.linkCol}>
                                <div onClick={officeLink}>{`Questions?\n Contact the Office.`}</div>
                            </div>

                            <div className={styles.linkCol}>
                                <div onClick={navigateToMarketingLink}>{`Schedule another tour\n at this property.`}</div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.chooseLocation}>
                        <h2>Lost? Enter where you are and where you want to go.</h2>

                        <div className={`${styles.selectWrapper} selectWrapper`}>
                            <Select
                                name="startIndex"
                                options={allListOfPOI}
                                maxMenuHeight={250}
                                onChange={selectPOIHandler}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999, fontSize: 14, fontWeight: 400 }),
                                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                                }}
                                placeholder={<div className={styles.selectPlaceholderText}>Start Location</div>}
                            />
                        </div>
                        <div className={`${styles.selectWrapper} selectWrapper`}>
                            <Select
                                name="endIndex"
                                options={allListOfPOI}
                                maxMenuHeight={250}
                                onChange={selectPOIHandler}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999, fontSize: 14, fontWeight: 400 }),
                                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                                }}
                                placeholder={<div className={styles.selectPlaceholderText}>End Location</div>}
                            />
                        </div>
                        <button onClick={selfGuidedTourHandler} className={styles.btnGreen}>
                            Go
                        </button>
                    </div>

                    <div className={styles.faqContainer}>
                        <h2 className={styles.faqHeader}>Information from the property</h2>
                        <Faq />
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

export default TourPortal;
