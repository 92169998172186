import { AnyAction } from 'redux';
import {
    GET_UNITS_BY_LOCATION_ID_FAILURE,
    GET_UNITS_BY_LOCATION_ID_REQUEST,
    GET_UNITS_BY_LOCATION_ID_SUCCESS,
} from '../constants/unitsByLocationId.constant';

const initialState = {
    loading: false,
    data: [],
    errorMsg: '',
};

const unitsByLocationIdReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case GET_UNITS_BY_LOCATION_ID_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_UNITS_BY_LOCATION_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case GET_UNITS_BY_LOCATION_ID_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: action.payload,
            };
        default:
            return state;
    }
};

export default unitsByLocationIdReducer;
