// Units Constants
export const GET_UNITS_REQUEST = 'GET_UNITS_REQUEST';
export const GET_UNITS_SUCCESS = 'GET_UNITS_SUCCESS';
export const GET_UNITS_FAILURE = 'GET_UNITS_FAILURE';

//Unit Constants
export const GET_UNIT_REQUEST = 'GET_UNIT_REQUEST';
export const GET_UNIT_SUCCESS = 'GET_UNIT_SUCCESS';
export const GET_UNIT_FAILURE = 'GET_UNIT_FAILURE';

export const CLEAR_UNIT_STATE = 'CLEAR_UNIT_STATE';

export const GET_TIME_ID_REQUEST = 'GET_TIME_ID_REQUEST';
export const GET_TIME_ID_SUCCESS = 'GET_TIME_ID_SUCCESS';
export const GET_TIME_ID_FAILURE = 'GET_TIME_ID_FAILURE';

//get tour
export const GET_TOUR_EXPERIENCE_MESSAGE_REQUEST = 'GET_TOUR_EXPERIENCE_MESSAGE_REQUEST';
export const GET_TOUR_EXPERIENCE_MESSAGE_SUCCESS = 'GET_TOUR_EXPERIENCE_MESSAGE_SUCCESS';
export const GET_TOUR_EXPERIENCE_MESSAGE_FAILURE = 'GET_TOUR_EXPERIENCE_MESSAGE_FAILURE';

export const CLEAR_PREVIOUS_UNIT_DATA = 'CLEAR_PREVIOUS_UNIT_DATA';
