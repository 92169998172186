import React, { useEffect, useState } from 'react';
import Calendar from '../../../common/calendar/Calendar';
import styles from './ReserveCalendar.module.css';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../redux/rootReducer';
import moment from 'moment';
import { SlotsProps } from '../BookAppointment';

interface propsReserveCalendar {
    selectedDate: string;
    setAvailabilityId: React.Dispatch<React.SetStateAction<never[]>>;
    setSelectedDate: React.Dispatch<React.SetStateAction<string>>;
    availabilities: string;
    setShowCalendar: (arg: boolean) => void;
    setShowTime: (arg: boolean) => void;
    slots: SlotsProps[];
    setShowConfirm: React.Dispatch<React.SetStateAction<boolean>>;
    selectedSlots: string;
    setSelectedSlots: React.Dispatch<React.SetStateAction<string>>;
    availabilityId: never[];
    setAvailableIds: React.Dispatch<React.SetStateAction<never[]>>;
    appoinmentStartTime: string;
    appointmentCloseTime: string;
    appointmentDate: string;
}

const ReserveCalendar = ({
    selectedDate,
    setAvailabilityId,
    setSelectedDate,
    availabilities,
    setShowCalendar,
    setShowTime,
    setShowConfirm,
    slots,
    selectedSlots,
    setSelectedSlots,
    availabilityId,
    setAvailableIds,
    appoinmentStartTime,
    appointmentDate,
    appointmentCloseTime,
}: propsReserveCalendar) => {
    const unit = useSelector((state: IRootState) => state.unitReducer.unit.data.units);
    const officetime = useSelector((state: IRootState) => state.unitReducer.units.data.officeHours);

    const day = moment(selectedDate).format('dddd');

    const [officeDuration, setOfficeDuration]: any = useState();

    useEffect(() => {
        officetime &&
            officetime?.filter((office: any) => {
                const startTime = moment(office?.openTime, 'HH:mm');
                const endTime = moment(office.closeTime, 'HH:mm');

                const hours: any = [];

                while (startTime.isBefore(endTime)) {
                    hours.push(startTime.format('HH:mm'));
                    startTime.add(1, 'hour');
                }

                if (office?.day === day) {
                    setOfficeDuration(hours);
                    if (office?.isOpen === 0) {
                        setOfficeDuration();
                    }
                }
            });
    }, [day]);

    const isBooked = () => {
        const availabilitiesSlot = availabilityId.filter(
            (availability: { openTime: moment.MomentInput; closeTime: moment.MomentInput }) =>
                moment(moment(selectedSlots, 'HH:mm').format('hh:mm A'), 'hh:mm A').isBetween(
                    moment(availability.openTime, 'hh:mm A'),
                    moment(availability.closeTime, 'hh:mm A'),
                    undefined,
                    '[]',
                ) &&
                moment(moment(selectedSlots, 'HH:mm').format('hh:mm A'), 'hh:mm A').isBetween(
                    moment(availability.openTime, 'hh:mm A'),
                    moment(availability.closeTime, 'hh:mm A'),
                    undefined,
                    '[]',
                ),
        );
        if (availabilitiesSlot.length) {
            if (unit.appointments && unit.appointments.length > 0) {
                const existingAppointments = unit.appointments.filter((appointment: { availabilityId: string }) =>
                    availabilitiesSlot.map((availability: { id: string }) => appointment.availabilityId === availability.id),
                );
                const conflictedAppointments = existingAppointments.filter((appointment: any) => {
                    const selectedDateTime = moment(`${moment(selectedDate).format('YYYY-MM-DD')} ${selectedSlots}`, 'YYYY-MM-DD HH:mm');
                    const appointmentStartTime = moment(
                        `${moment(appointment.reservedDate).format('YYYY-MM-DD')} ${appointment.startTime}`,
                        'YYYY-MM-DD hh:mm A',
                    ).subtract(1, 'hour');
                    const appointmentEndTime = moment(
                        `${moment(appointment.reservedDate).format('YYYY-MM-DD')} ${appointment.endTime}`,
                        'YYYY-MM-DD hh:mm A',
                    ).add(1, 'hour');

                    return selectedDateTime.isBetween(appointmentStartTime, appointmentEndTime, undefined, '[]');
                });
                const conflictedAvailabilities = [
                    ...new Set(conflictedAppointments.map((appointment: { availabilityId: string }) => appointment.availabilityId)),
                ];

                let availableAvailabilities = [];
                if (conflictedAvailabilities.length > 0 && conflictedAvailabilities.length < availabilitiesSlot.length) {
                    availableAvailabilities = availabilitiesSlot.filter((availability: { id: number }) =>
                        conflictedAvailabilities.some((conflictedAvailability) => conflictedAvailability !== availability.id),
                    );

                    return availableAvailabilities;
                }
                // else if (conflictedAvailabilities.length > 0 && conflictedAvailabilities.length === availabilitiesSlot.length) {
                //     return;
                // }
                else {
                    return availabilitiesSlot;
                }
            } else {
                return availabilitiesSlot;
            }
        } else {
            return;
        }
    };

    const slotsHandler = (value: string) => {
        if (selectedSlots?.includes(value)) {
            setSelectedSlots('');
        } else {
            setSelectedSlots(value);
        }
    };

    const scheduleAppointmentHandler = async () => {
        const availability = await isBooked();

        if (availability && availability.length > 0) {
            setAvailableIds(availability);
            setShowTime(false);
            setShowConfirm(true);
            setShowCalendar(false);
        } else {
            return;
        }
    };

    const Date = moment(selectedDate).format('MM-DD-YYYY');

    return (
        <div className={styles.calendarWrapper}>
            <div className={styles.calendarOuter}>
                <div className={styles.calendarContent}>
                    <Calendar
                        setAvailabilityId={setAvailabilityId}
                        setSelectedDate={setSelectedDate}
                        availabilities={availabilities}
                        setShowTime={setShowTime}
                        selectedDate={selectedDate}
                        setSelectedSlots={setSelectedSlots}
                    />
                </div>

                <div className={styles.selectTimeOuter}>
                    <h2 className={`${appointmentDate && Date === moment(appointmentDate).format('MM-DD-YYYY') ? styles.appointment : ''}`}>
                        Select a time to tour apartment {unit?.unitYardiId ? unit?.unitYardiId : unit?.name} on{' '}
                        <span>{Date === 'Invalid date' ? moment().format('MM-DD-YYYY') : Date}</span>{' '}
                    </h2>

                    {appointmentDate && Date === moment(appointmentDate).format('MM-DD-YYYY') && (
                        <>
                            <p>Appointment already exists</p>
                            <div className={styles.bookedSlotsSection}>
                                <div className={styles.bookedSlot}>
                                    <span>{moment(appoinmentStartTime, 'HH:mm').format('hh:mm A')}</span>-
                                    <span>{moment(appointmentCloseTime, 'HH:mm').format('hh:mm A')}</span>
                                </div>
                            </div>
                        </>
                    )}

                    <p>Available One-Hour Appointments</p>

                    <div>
                        {slots?.length > 0 ? (
                            <div
                                // className={
                                //     appointmentDate && Date === moment(appointmentDate).format('MM-DD-YYYY')
                                //         ? styles.modifiedAppointment
                                //         : styles.selectTimeWrapper
                                // }
                                className={`${styles.selectTimeWrapper} ${appointmentDate && Date === moment(appointmentDate).format('MM-DD-YYYY') ? styles.minTimeWrapper : ''}`}>
                                {slots?.map((option: SlotsProps, index: number) => (
                                    <p
                                        onClick={() =>
                                            slotsHandler(`${option.value}-${moment(option.value, 'HH:mm').add(1, 'hours').format('HH:mm')}`)
                                        }
                                        key={index}
                                        className={
                                            `${option.value}-${moment(option.value, 'HH:mm').add(1, 'hours').format('HH:mm')}` ===
                                                selectedSlots
                                                ? styles.slotSelected
                                                : officeDuration?.includes(option.value)
                                                    ? `${styles.activeOfficeTime} ${styles.selectTime} ${styles.officeHours}`
                                                    : `${styles.selectTime} ${styles.officeHours}`
                                        }>
                                        <span>{moment(option.value, 'HH:mm').format('h:mm A')}</span>-
                                        <span>{moment(option.value, 'HH:mm').add(1, 'hours').format('h:mm A')}</span>
                                    </p>
                                ))}
                            </div>
                        ) : (
                            <div className={styles.noRecordsWrapper}>
                                <div className={styles.noRecordsContent}>
                                    <p>No Records Found</p>
                                </div>
                            </div>
                        )}
                        <div className={styles.leasingOfficeBoxWrapper}>
                            <span className={styles.leasingOfficeBox}></span>
                            <p>Leasing office is open during this appointment</p>
                        </div>
                        <div className={styles.scheduleAppointmentWrapper}>
                            <button onClick={scheduleAppointmentHandler}>
                                {appointmentDate ? 'Reschedule Appointment' : 'Schedule Appointment'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.faqWrapper}>{/* <Faq /> */}</div>
        </div>
    );
};

export default ReserveCalendar;
