import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import axios from 'axios';
import React, { Suspense, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAuth, getRegister } from '../../../../redux/auth/actions/auth.action';
import { AppDispatch, IRootState } from '../../../../redux/rootReducer';
import ComponentLoader from '../../../common/component-loader/ComponentLoader';
import Loader from '../../../common/loader/Loader';
import { AppContext } from '../../../context/AppContect';
import { CustomizedState, onSuccessProps } from '../../interfaces/auth.interface';
import styles from './CreditCard.module.css';
import Popup from '../../../common/popup/Popup';
import Terms from '../../Terms';
import { getLocationById } from '../../../../redux/location/actions/location.action';

const CreditCard = ({
  signupDetails,
  setSignupDetails,
  setCCVerification,
  setOnBoardComponent,
  setCreditCardDetail,
  locationId,
  setErrorMessage,
  errorMessage,
  setShowRentalTerm,
  setIdVerification,
  setConfirmPass,
  confirmPass,
  setPhoneNum,
  phoneNum,
  unitId,
  setIsOpen,
  setInvalidCardMessage,
  invalidCardMessage,
  showCustomModal,
  customCardDisclaimer
}: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();

  const appContext = useContext(AppContext);

  const auth = useSelector((state: IRootState) => state.authReducer.data);

  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isOpen, setIsTnCOpen] = useState(false);
  const state = location.state as CustomizedState;
  const { destinationURL, modifiedAppointment } = state || '';

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (showCustomModal) {
      setShowModal(showCustomModal);
    }
  }, []);


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const onSuccess = useCallback(
    (data: onSuccessProps) => {
      appContext.visitorAccessToken = data.token;
      appContext.visitor = data.visitor;

      if (destinationURL && !modifiedAppointment) {
        navigate(destinationURL);
      } else {
        if (modifiedAppointment) {
          navigate(`/appointments${modifiedAppointment || ''}`);
        } else {
          navigate('/appointments');
        }
      }
    },
    [appContext, destinationURL, navigate, modifiedAppointment],
  );

  const handleSave = async (event: React.FormEvent) => {
    event.preventDefault();

    setErrorMessage('');
    setInvalidCardMessage('');

    if (!stripe || !elements) {
      return;
    }

    const cardElement: any = elements.getElement(CardElement);
    if (!cardElement) {
      const requestData = {
        ...signupDetails,
        fullName: signupDetails.firstName + signupDetails.middleName + signupDetails.lastName,
        stripeCustomerId: "",
        leadPropertyLink: locationId ? locationId : '',
        desiredNumBedrooms: signupDetails.desiredNumBedrooms === 'Studio' ? '0' : signupDetails.desiredNumBedrooms,
        leadUnitLink: unitId ? unitId : '',
      };
      await setSignupDetails(requestData);

      await dispatch(
        getRegister(
          requestData,
          onSuccess,
          setShowRentalTerm,
          setCCVerification,
          setIdVerification,
          setCreditCardDetail,
        ),
      );

    } else {
      const response = await stripe.createToken(cardElement);

      if (response?.token?.card?.funding === 'credit' || response?.token?.card?.funding === 'debit') {
        if (response.error) {
          console.error(response.error);
        } else {
          setIsLoading(true);

          const { firstName, lastName, email } = signupDetails;

          const { id } = response.token;

          await axios
            .post('transactions/create-stripe-customer', { token: id, firstName, lastName, email })
            .then(async (response) => {
              await setCreditCardDetail(true);
              await setCCVerification(false);
              await setOnBoardComponent(true);

              const requestData = {
                ...signupDetails,
                fullName: signupDetails.firstName + signupDetails.middleName + signupDetails.lastName,
                stripeCustomerId: response.data.customer_id,
                leadPropertyLink: locationId ? locationId : '',
                desiredNumBedrooms: signupDetails.desiredNumBedrooms === 'Studio' ? '0' : signupDetails.desiredNumBedrooms,
                leadUnitLink: unitId ? unitId : '',
              };
              await setSignupDetails(requestData);

              await dispatch(
                getRegister(
                  requestData,
                  onSuccess,
                  setShowRentalTerm,
                  setCCVerification,
                  setIdVerification,
                  setCreditCardDetail,
                ),
              );
            })
            .catch((error) => {
              setErrorMessage(error.response.data.message);
            });

          if (auth?.statusCode === 200) {
            dispatch(getAuth({ email: signupDetails?.email, password: signupDetails?.password }, onSuccess));
          }
        }
      } else {
        setInvalidCardMessage('Only credit and debit cards are allowed');
      }
    }
    setIsLoading(false);
  };

  const updateTCCheck = (status: boolean) => {
    setIsTnCOpen(false);
    setIsChecked(status);
    setSignupDetails({ ...signupDetails, terms: status });
  };

  const [showCardVerification, setShowCardVerification] = useState(true);
  const locationData = useSelector((state: IRootState) => state.locationReducer.locationById.data?.location);
  useEffect(() => {
    if (locationData) {
      setShowCardVerification(locationData.isCardRequired);
    }
  }, [locationData]);

  const ModalBody = () => {
    return (
      <div className={styles.CCModal}>
        <div className={styles.modalHeader}>
          <h3>Credit Card Disclaimer</h3>
        </div>
        <div className={styles.modalBody}>
          <p>{customCardDisclaimer}</p>
        </div>
        <div className={styles.modalFooter}>
          <button onClick={() => setShowModal(false)}>Continue</button>
        </div>
      </div>
    )
  }

  return (
    <div className={`${styles.detailsWrapper} creditCard ${showCardVerification ? '' : styles.noCreditCard} `}>
      <Popup open={isOpen} setOpen={setIsTnCOpen} bodyData={<Terms setOpen={setIsTnCOpen} handleBtnClick={updateTCCheck} />} />
      {isLoading && <ComponentLoader />}

      <div className={styles.detailsBody}>
        <Suspense fallback={<Loader />}>
          <form onSubmit={handleSave}>
            {
              !!showCardVerification &&
              <>
                <div className={styles.chargeInfo}>
                  <h3 className={styles.title}>Liability Preauthorization</h3>
                  <p className={styles.description}>
                    {' '}
                    As with any hotel stay, your credit card will cover the of incidence of damages resulting from your tour or
                    keys not being returned.
                  </p>
                </div>

                <CardElement />
                {errorMessage && <span className={styles.errorText}>{errorMessage}</span>}
                {invalidCardMessage && <span className={styles.errorText}>{invalidCardMessage}</span>}

              </>
            }

            <div className={`${showCardVerification ? '' : styles.customBoxes} ${styles.boxes}`}>
              <input
                type="checkbox"
                id="terms"
                name="terms"
                // value={signupDetails.terms}
                checked={isChecked}
              />
              <label onClick={() => setIsTnCOpen(true)}>Accept Terms and Conditions</label>
            </div>

            <div className={styles.btnWrapper}>
              <button disabled={!isChecked} type="submit">
                Save
              </button>
              <span
                onClick={() => {
                  setShowRentalTerm?.(true);
                  setCCVerification?.(false);
                  setIdVerification?.(true);
                  setCreditCardDetail?.(false);
                  setConfirmPass(confirmPass);
                  setPhoneNum(phoneNum);
                  setInvalidCardMessage('');
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                  });
                }}>
                Back
              </span>
            </div>
          </form>
          {!!showCustomModal && <Popup open={showModal} setOpen={showCustomModal} bodyData={<ModalBody />} className="custom-webcam-modal" />}
        </Suspense>
      </div>
    </div>
  );
};

export default CreditCard;
