import React from 'react';
import './SuccessAnimation.css'; // Import CSS file for styling (if needed)

// Define props interface
interface SuccessAnimationProps {
    className?: string; // Optional className name for styling
}

// Define the SuccessAnimation component
const SuccessAnimation = ({ className }: SuccessAnimationProps) => {
    return (
        <div className="swal2-icon swal2-success swal2-animate-success-icon" style={{ display: "flex;" }}>
            <div className="swal2-success-circular-line-left" style={{ backgroundColor: ' rgb(255, 255, 255)' }}></div>
            <span className="swal2-success-line-tip"></span>
            <span className="swal2-success-line-long"></span>
            <div className="swal2-success-ring"></div>
            <div className="swal2-success-fix" style={{ backgroundColor: 'rgb(255, 255, 255)' }}></div>
            <div className="swal2-success-circular-line-right" style={{ backgroundColor: 'rgb(255, 255, 255)' }} ></div>
        </div >
    );
}

export default SuccessAnimation;