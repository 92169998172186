import React, { useState } from 'react';
import Accordian from '../../common/accordian/Accordian';
import styles from './Faq.module.css';
import { AnyIfEmpty } from 'react-redux';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../redux/rootReducer';

const Faq = () => {
    const [clicked, setClicked] = useState('0');

    const TourData = useSelector((state: IRootState) => state.unitReducer.units.data.experienceMessage);

    const handleToggle = (index: string) => {
        if (clicked === index) {
            setClicked('0');
        } else {
            setClicked(index);
        }
    };

    return (
        <div className={styles.questionarrieWrapper}>
            {TourData?.map((item: { header: string; message: string }, index: AnyIfEmpty<object>) => {
                return (
                    <Accordian
                        key={index}
                        active={clicked === index}
                        content={item.message}
                        title={item.header}
                        onToggle={() => handleToggle(index)}
                    />
                );
            })}
        </div>
    );
};

export default Faq;
