import { AnyAction } from 'redux';
import { CHANGE_PASSWORD_FAILURE, CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS, RESET_CHANGE_PASSWORD_MESSAGE } from '../constants/auth.constant';

const initialState = {
  loading: false,
  data: [],
  errMsg: '',
};

export const changePasswordReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        errMsg: '',
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        errMsg: action.payload,
      };
    case RESET_CHANGE_PASSWORD_MESSAGE:
      return {
        ...state,
        loading: false,
        data: [],
        errMsg: '',
      }
    default:
      return state;
  }
};
