import { AnyIfEmpty } from 'react-redux';
import {
    GET_USER_TOUR_FAILURE,
    GET_USER_TOUR_REQUEST,
    GET_USER_TOUR_SUCCESS,
    POST_USER_TOUR_FAILURE,
    POST_USER_TOUR_REQUEST,
    POST_USER_TOUR_SUCCESS,
} from '../constants/tour.constants';

const initialState = {
    loading: false,
    data: [],
    errMsg: '',
    buildTour: {
        loading: false,
        data: [],
        errMsg: '',
    },
};

const tourReducer = (state = initialState, action: AnyIfEmpty<object>) => {
    switch (action.type) {
        case GET_USER_TOUR_REQUEST:
            return {
                ...state,
                loading: true,
                errMsg: '',
            };
        case GET_USER_TOUR_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case GET_USER_TOUR_FAILURE:
            return {
                ...state,
                loading: false,
                errMsg: action.payload,
            };
        case POST_USER_TOUR_REQUEST:
            return {
                ...state,
                buildTour: {
                    ...state.buildTour,
                    loading: true,
                    errMsg: '',
                },
            };
        case POST_USER_TOUR_SUCCESS:
            return {
                ...state,
                buildTour: {
                    ...state.buildTour,
                    loading: false,
                    data: action.payload,
                },
            };
        case POST_USER_TOUR_FAILURE:
            return {
                ...state,
                buildTour: {
                    ...state.buildTour,
                    loading: false,
                    errMsg: action.payload,
                },
            };
        default:
            return state;
    }
};

export default tourReducer;
