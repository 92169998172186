import React from 'react';

interface MapIconProps {
  width?: number;
  height?: number;
  stroke?: string;
}

const MapIcon = ({ width = 18, height = 18, stroke = "#8C8D90" }: MapIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_1906_11197"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="18"
      height="18"
    >
      <path d="M17.5 17.5V0.500002H0.5V17.5H17.5Z" fill="white" stroke="white" />
    </mask>
    <g mask="url(#mask0_1906_11197)">
      <path
        d="M0.351562 8.01562V6.04688C0.351562 5.56172 0.548437 5.12227 0.866601 4.8041C1.18477 4.48594 1.62422 4.28906 2.10937 4.28906C3.08004 4.28906 3.86719 5.07621 3.86719 6.04688V7.10156"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.86719 9.21094V15.8906C3.86719 14.92 3.08004 14.1328 2.10937 14.1328C1.13871 14.1328 0.351562 14.92 0.351562 15.8906C0.351562 15.9504 0.354727 16.0109 0.360703 16.072C0.452109 16.9868 1.29023 17.6484 2.20957 17.6484H3.86719"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.89062 17.6484H15.8906C16.8616 17.6484 17.6484 16.8616 17.6484 15.8906"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1328 13.4297V15.8906C14.1328 14.7674 15.1861 13.8902 16.3589 14.1933C16.9509 14.3462 17.435 14.8303 17.588 15.4223C17.6291 15.5816 17.6484 15.7384 17.6484 15.8906V6.04688C17.6484 5.07621 16.8613 4.28906 15.8906 4.28906C15.4055 4.28906 14.966 4.48594 14.6479 4.8041C14.3297 5.12227 14.1328 5.56172 14.1328 6.04688V11.3203"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.351562 10.8281V15.8906"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1325 7.10156H10.6172"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1328 11.3203V13.4297H11.1094L6.89062 17.6484H3.86719L8.29687 13.4297L3.86719 9.21094V7.10156H4.71094C4.71094 7.10156 7.92559 10.1633 9.14062 11.3203H14.1328Z"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.38246 7.10156H4.71094"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6175 7.10156C11.3966 5.8391 12.1641 4.3766 12.1641 3.51563C12.1641 1.76801 10.7476 0.351563 9 0.351563C7.25238 0.351563 5.83594 1.76801 5.83594 3.51563C5.83594 4.3766 6.6034 5.8391 7.38246 7.10156C8.18511 8.40305 9 9.49219 9 9.49219C9 9.49219 9.81492 8.40305 10.6175 7.10156Z"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 4.92188C9.77555 4.92188 10.4062 4.29117 10.4062 3.51563C10.4062 2.74008 9.77555 2.10938 9 2.10938C8.22445 2.10938 7.59375 2.74008 7.59375 3.51563C7.59375 4.29117 8.22445 4.92188 9 4.92188Z"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.203125 9.42188C0.203125 9.50395 0.269483 9.57031 0.351562 9.57031C0.433642 9.57031 0.5 9.50395 0.5 9.42188C0.5 9.3398 0.433642 9.27344 0.351562 9.27344C0.269483 9.27344 0.203125 9.3398 0.203125 9.42188Z"
        fill="black"
        stroke={stroke}
      />
    </g>
  </svg>
);

export default MapIcon;
