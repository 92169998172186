import React from 'react';
import Modal from 'react-modal';
import { AnyIfEmpty } from 'react-redux';

const Popup = (props: AnyIfEmpty<object>) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  function closeModal() {
    props.setOpen(false);
    props.onCloseModal && props.onCloseModal();
  }

  return (
    <Modal isOpen={props.open} onRequestClose={closeModal} style={customStyles} contentLabel="Modal" className={props.className || ""} ariaHideApp={false}
      overlayClassName={props.className}
      shouldCloseOnOverlayClick={false || props.shouldCloseOnOverlayClick}
    >
      {props.bodyData}
    </Modal>
  );
};

export default Popup;
