export const GET_AUTH_REQUEST = 'GET_AUTH_REQUEST';
export const GET_AUTH_SUCCESS = 'GET_AUTH_SUCCESS';
export const GET_AUTH_FAILURE = 'GET_AUTH_FAILURE';
export const RESET_VALIDATION_MESSAGE = 'RESET_VALIDATION_MESSAGE';

export const GET_SIGNUP_REQUEST = 'GET_SIGNUP_REQUEST';
export const GET_SIGNUP_SUCCESS = 'GET_SIGNUP_SUCCESS';
export const GET_SIGNUP_FAILURE = 'GET_SIGNUP_FAILURE';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const RESET_PROFILE_MESSAGE = 'RESET_PROFILE_MESSAGE';

export const EDIT_PROFILE_REQUEST = 'EDIT_PROFILE_REQUEST';
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';
export const EDIT_PROFILE_FAILURE = 'EDIT_PROFILE_FAILURE';
export const RESET_CHANGE_PASSWORD_MESSAGE = 'RESET_CHANGE_PASSWORD_MESSAGE';

export const CHANGE_PROFILE_REQUEST = 'CHANGE_PROFILE_REQUEST';
export const CHANGE_PROFILE_SUCCESS = 'CHANGE_PROFILE_SUCCESS';
export const CHANGE_PROFILE_FAILURE = 'CHANGE_PROFILE_FAILURE';
export const RESET_CHANGE_PROFILE_MESSAGE = 'RESET_CHANGE_PROFILE_MESSAGE';