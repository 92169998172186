import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { AnyIfEmpty } from 'react-redux';
import { EDIT_PROFILE_REQUEST, EDIT_PROFILE_SUCCESS, EDIT_PROFILE_FAILURE, RESET_PROFILE_MESSAGE, CHANGE_PROFILE_REQUEST, CHANGE_PASSWORD_SUCCESS, CHANGE_PROFILE_FAILURE } from '../constants/auth.constant';

const editProfileRequest = () => {
  return {
    type: EDIT_PROFILE_REQUEST,
  };
};

const editProfileSuccess = (data: AnyIfEmpty<object>) => {
  return {
    type: EDIT_PROFILE_SUCCESS,
    payload: data,
  };
};

const editProfileFailure = (errMsg: AnyIfEmpty<object>) => {
  return {
    type: EDIT_PROFILE_FAILURE,
    payload: errMsg,
  };
};

export const resetProfileMessage = () => {
  return {
    type: RESET_PROFILE_MESSAGE,
  };
}

export const editProfile = (profileDetails: AnyIfEmpty<object>, onSuccess?: () => void, setIsLoading?: Dispatch<SetStateAction<boolean>>) => {
  return async (dispatch: Dispatch<AnyIfEmpty<object>>) => {
    dispatch(editProfileRequest());
    await axios
      .patch('/visitor/edit-profile', profileDetails)
      .then((response) => {
        if (response?.data.statusCode === 200) {
          dispatch(editProfileSuccess(response.data));
          onSuccess && onSuccess()
        } else if (response?.data.statusCode === 400) {
          dispatch(editProfileFailure(response?.data?.message))
          setIsLoading && setIsLoading(false)
        }
      })
      .catch((err) => {
        setIsLoading && setIsLoading(false)
        dispatch(editProfileFailure(err?.response?.data?.message))
      });
  };
};

export const uploadProfilePicture = (photoUrl: string, onSuccess: (url: string) => void, onFailure: (errMsg: string) => void) => {
  return async () => {
    await axios
      .patch('/visitor/change-profile-image', { photo_url: photoUrl })
      .then((response) => {
        if (response?.data.statusCode === 200) {
          onSuccess(photoUrl)
        } else if (response?.data.statusCode === 400) {
          onFailure(response?.data?.message)
        }
      })
      .catch((err) => {
        onFailure(err?.data?.message)
      });
  };
};
