import React, { useEffect, useRef, useState } from 'react';
import style from './chatbot.module.css';
import CloseIcon from '../icons/CloseIcon';
import { useForm, Controller } from 'react-hook-form';
import UpArrowIcon from '../icons/UpArrowIcon';
import { AppDispatch } from '../../redux/rootReducer';
import { useDispatch } from 'react-redux';
import { askToChatBot, getChatHistory } from '../../redux/chatbot/chatbot.action';
import Lady from '../../images/lady.jpeg';
import ChatBackground from '../../images/chat-background.png';
// import LanguageSelectorDropdown from '../../components/appointments/language-selector/LanguageSelector';

export interface ChatHistory {
    id: number;
    question: string;
    answer: string | null;
}

interface ChatBotProps {
    appointmentId: string;
    getKeyWords?: (query: string) => void;
    className?: string;
}

interface OldChatHistory {
    q: string;
    a: string;
}

const ChatBot = ({ appointmentId, getKeyWords, className }: ChatBotProps) => {
    const [locationName, setLocationName] = useState<null | string>(null);

    const divRef = useRef<HTMLDivElement | null>(null);

    const scrollToBottom = () => {
        if (divRef.current) {
            divRef.current.scrollIntoView({
                behavior: 'smooth',
            });
        }
    };

    const [expandChatBot, setExpandChatBot] = useState(false);
    const [chatHistory, setChatHistory] = useState<ChatHistory[] | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch: AppDispatch = useDispatch();

    const {
        getValues,
        reset,
        formState: { isValid },
        control,
    } = useForm({
        defaultValues: {
            query: '',
        },
        mode: 'onChange',
    });

    const onSuccess = (response: string) => {
        setIsLoading(false);
        setChatHistory((prevChatHistory) => {
            if (!prevChatHistory) return null;
            const updatedHistory = [...prevChatHistory];
            updatedHistory[updatedHistory.length - 1].answer = response;
            return updatedHistory;
        });
    };

    const onFailure = (errorMessage: string) => {
        setIsLoading(false);
        setChatHistory(null);
    };

    const onAskToChatBot = () => {
        setIsLoading(true);

        const query = getValues('query');

        reset();
        const prevId = (chatHistory?.length && chatHistory[chatHistory?.length - 1]?.id) || 0;

        if (chatHistory) {
            setChatHistory([...chatHistory, { id: prevId + 1, question: query, answer: null }]);
        } else {
            setChatHistory([{ id: prevId + 1, question: query, answer: null }]);
        }
        dispatch(askToChatBot(query, appointmentId, onSuccess, onFailure));
    };

    useEffect(() => {
        scrollToBottom();
    }, [chatHistory]);

    const restoreChatHistory = (chat: OldChatHistory[]) => {
        let prevId = (chatHistory?.length && chatHistory[chatHistory?.length - 1]?.id) || 0;
        let oldHistory: ChatHistory[] = [];
        chat?.length &&
            chat?.forEach((thread: OldChatHistory, index: number) => {
                oldHistory.push({ id: prevId, answer: thread?.a, question: thread?.q });
                prevId++;
            });
        setChatHistory(oldHistory);
    };

    const onHistorySuccess = (chat: any, name: string) => {
        if (name) {
            setLocationName(name);
        }
        if (chat?.length) {
            restoreChatHistory(chat);
        } else {
            setChatHistory(null);
        }
        setIsLoading(false);
    };

    const onHistoryFailure = () => {
        setIsLoading(false);
        setChatHistory(null);
    };

    useEffect(() => {
        dispatch(getChatHistory(appointmentId, onHistorySuccess, onHistoryFailure));
        scrollToBottom();

        return () => {
            setIsLoading(true);
            setChatHistory(null);
        };
    }, []);

    return (
        <div className={`${style.chatBotContainer} chatBotContainer ${className ? className : ''}`}>
            <div className={`${style.chatBotRootContainer} ${!expandChatBot ? style.chatBotSmallContainer : ''}`}>
                <div className={style.container}>
                    <div className={style.chatClose} onClick={() => setExpandChatBot(false)}>
                        <CloseIcon fill="#9ca3af" />
                    </div>

                    <div
                        className={style.chatBotHeader}
                        style={{ backgroundImage: `url(${ChatBackground})` }}
                        onClick={() => {
                            if (!expandChatBot) {
                                setExpandChatBot(true);
                            }
                        }}>
                        <div className={style.chatAvatar} style={{ backgroundImage: `url(${Lady})` }}></div>
                    </div>

                    {/* {expandChatBot && (
                        <div className={style.langaugeSelectorWrapper}>
                            <LanguageSelectorDropdown />
                        </div>
                    )} */}

                    <div className={style.chatBotBody}>
                        {!chatHistory && (
                            <div className={style.bot}>
                                <div className={style.botProfile}>
                                    <div className={style.botImage} style={{ backgroundImage: `url(${Lady})` }}></div>
                                    <div className={style.name}>{locationName ? locationName : 'Pineapple'}</div>
                                </div>
                                <div className={style.systemMessage}>Hi! I'm here to answer any questions you have!</div>
                            </div>
                        )}

                        {!!chatHistory?.length &&
                            chatHistory?.map((chat: ChatHistory, index: number) => {
                                return (
                                    <div className={style.chatContainer}>
                                        <div className={style.userQueryContainer}>
                                            <div className={style.userProfileName}>
                                                <div className={style.userProfile}></div>
                                            </div>
                                            <div className={style.userQuery}>{chat?.question}</div>
                                        </div>
                                        {chat?.answer ? (
                                            <div className={style.chatResponseContainer}>
                                                <div className={style.botProfile}>
                                                    <div className={style.botImage} style={{ backgroundImage: `url(${Lady})` }}></div>
                                                    <div className={style.name}>{locationName ? locationName : 'Pineapple'}</div>
                                                </div>
                                                <div className={style.chatBotAnswer}>{chat?.answer}</div>
                                            </div>
                                        ) : (
                                            !chat?.answer &&
                                            isLoading && (
                                                <div className={style.loading}>
                                                    <div></div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                );
                            })}

                        <div ref={divRef}></div>
                    </div>

                    <div className={style.chatBotFooter}>
                        <div className={style.inputHolder}>
                            <form>
                                <Controller
                                    name="query"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { onChange, value } }) => {
                                        return (
                                            <div className={style.passwordWrapper}>
                                                <input
                                                    value={value}
                                                    onChange={onChange}
                                                    id="query"
                                                    name="query"
                                                    placeholder="Ask a question"
                                                />
                                            </div>
                                        );
                                    }}
                                />

                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onAskToChatBot();
                                    }}
                                    disabled={!isValid || isLoading || getValues('query').trim() === ''}
                                    type="submit">
                                    <UpArrowIcon fill="#fff" height={16} width={16} />
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChatBot;
