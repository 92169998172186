import React, { useState } from 'react';
import Accordian from '../../common/accordian/Accordian';
import styles from './Faq.module.css';
import { AnyIfEmpty } from 'react-redux';

const Faqs = () => {
    const [clicked, setClicked] = useState('0');

    const handleToggle = (index: string) => {
        if (clicked === index) {
            setClicked('0');
        } else {
            setClicked(index);
        }
    };

    const accordionData = [
        {
            title: 'What is Pineapple?',
            content: `The apartment community that you're interested in has partnered with Pineapple to offer self-guided tours.  Pineapple is a U.S. based company that offers a simple, agentless touring experience at communities throughout the country.`,
        },
        {
            title: 'What is a self-guided tour and what should I expect?',
            content: `A self-guided tour allows visitors to tour an apartment community without the need of a Leasing Agent. This gives the visitor more autonomy and flexibility.  Once you register and schedule a tour, you will receive an email confirmation with an access code and a link to tour navigation.  On the day and time or your appointment, you'll visit the kiosk located at the property and enter your access code to retrieve keys.  The tour navigation will lead you to the apartment and amenities that you may be interested in.  At the end of your tour, return to the kiosk to re-enter your access code and return your keys. `,
        },
        {
            title: 'Why do you need my ID? What do you do with my ID? ',
            content: `A picture of your Photo ID is used to verify your account details. It is stored exclusively with Stripe - we have no access to it.`,
        },
        {
            title: 'What is Stripe?',
            content: `Stripe is a SaaS company founded in 2009 in California. You've likely used Stripe before when purchasing from Amazon, Instacart, or Lyft. Stripe verifies the identity of all Pineapple users for the protection of the visitor and community. `,
        },
        {
            title: 'Why do you need my personal information? ',
            content: `You'll submit information commonly found on an apartment guest card- name, email address, phone number, ideal move in date, and preferred number of bedrooms. This information is shared with the apartment community management so they can further assist you with your apartment search.`,
        },
    ];

    return (
        <div className={styles.questionarrieWrapper}>
            {accordionData.map((item: { title: string; content: string }, index: AnyIfEmpty<object>) => {
                return (
                    <Accordian
                        key={index}
                        active={clicked === index}
                        content={item.content}
                        title={item.title}
                        onToggle={() => handleToggle(index)}
                    />
                );
            })}
        </div>
    );
};

export default Faqs;
