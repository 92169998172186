import React, { useEffect, useState } from 'react';
import style from '../login/Login.module.css';
import { useSelector } from 'react-redux';
import OnBoard from './onboard/OnBoard';
import SecureAccount from './secureAccount/SecureAccount';
import { Elements } from '@stripe/react-stripe-js';
import Toast from '../../toast/Toast';
import { useLocation, useNavigate } from 'react-router-dom';
import Faqs from '../../tourPortal/faq/Faqs';
import { IRootState } from '../../../redux/rootReducer';
import Loader from '../../common/loader/Loader';
import CreditCard from './creditcard/CreditCard';
import { loadStripe } from '@stripe/stripe-js';
import { isLoggedIn } from '../../../utils/utils';

export interface CustomizedState {
  destinationURL: string;
  modifiedAppointment: string;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK || '');

const Signup = () => {
  const navigate = useNavigate();

  const [onBoardComponent, setOnBoardComponent] = useState(true);
  const [CCVerification, setCCVerification] = useState(false);
  const [personalComponent, setPersonalComponent] = useState(false);
  const [credentialComponent, setCredentialComponent] = useState(false);
  const [idVerification, setIdVerification] = useState(false);
  const [creditCardDetail, setCreditCardDetail] = useState(false);
  const [personalDetail, setPersonalDetail] = useState(false);
  const [credentials, setCredentials] = useState(false);
  const [webhookWaiting, setWebhookWaiting] = useState(false);
  const [rentalTermStyle, setRentalTermStyle] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [showRentalTerm, setShowRentalTerm] = useState(false);
  const [locationId, setLocationId] = useState('');
  const [unitId, setUnitId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [verifyUserMessage, setVerifyUserMessage] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [phoneNum, setPhoneNum] = useState<string>('');
  const [open, setIsOpen] = useState(false);
  const [pmsLeadSource, setPMSLeadSource] = useState({
    lead_name: "",
    lead_id: "",
    lead_PMS: ""
  })

  const signUpError = useSelector((state: { authReducer: { signUpError: string } }) => state.authReducer.signUpError);
  const verifyUserLoading = useSelector((state: IRootState) => state.verifyUser.loading);

  const [signupDetails, setSignupDetails] = useState({
    fullName: '',
    email: '',
    password: '',
    phoneNumber: 0,
    stripeDocVerificationClientId: '',
    stripeCustomerId: '',
    terms: false,
    firstName: '',
    lastName: '',
    middleName: '',
    namePrefix: '.',
    addressLine1: '',
    addressLine2: '',
    country: '',
    state: '',
    city: '',
    postalCode: '',
    expectedMoveInDate: '',
    leaseFromDate: '',
    leaseToDate: '',
    desiredNumBedrooms: '',
    desiredLeaseTerms: '',
    countryCode: 0,
    pmsLeadSorce: {
      lead_name: "",
      lead_id: "",
      lead_PMS: ""
    }
  });
  const [invalidCardMessage, setInvalidCardMessage] = useState('');

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const loading = useSelector((state: { authReducer: { loading: boolean } }) => state.authReducer.loading);
  const { location: locationData, maxRoom } = useSelector((state: IRootState) => state.locationReducer.locationById.data);

  const location = useLocation();
  const state = (location?.state as CustomizedState) || false;
  const { destinationURL, modifiedAppointment } = state;

  useEffect(() => {
    if (showToast) {
      setTimeout(() => { setShowToast(false); setToastMessage('') }, 4000);
    }
  }, [showToast]);

  useEffect(() => {
    isLoggedIn()
      ? navigate(destinationURL && !modifiedAppointment ? destinationURL : `/appointments${modifiedAppointment || ''}`)
      : navigate('/signup', { state: { destinationURL, modifiedAppointment } });
  }, [modifiedAppointment]);

  return (
    <>
      {signUpError && <Toast message={signUpError} />}
      {verifyUserMessage && <Toast message={verifyUserMessage} />}
      {toastMessage && <Toast message={toastMessage} />}
      {/* {invalidCardMessage && <Toast message={invalidCardMessage} />} */}

      <div className={style.signupContainerMain}>
        <div className={!showRentalTerm ? `signupUpperContainer ${style.signupUpperContainer}` : `${style.signupUpperContainer}`}>
          <div className={style.fornWrapper}>
            <Elements stripe={stripePromise}>
              {(loading || webhookWaiting || verifyUserLoading) && <Loader />}

              {locationData && locationData?.images && !!locationData?.images?.length && (
                <>
                  <img src={locationData?.images[0]} alt="property" className={style.propertyImage} />
                  <div className={style.imgOverlay}></div>
                </>
              )}
              <div
                className={
                  !rentalTermStyle
                    ? `${style.container}  ${style.rentalTerm}`
                    : `${style.container} ${style.signupContainer} `
                }>
                <OnBoard
                  setSignupDetails={setSignupDetails}
                  signupDetails={signupDetails}
                  setOnBoardComponent={setOnBoardComponent}
                  setCCVerification={setCCVerification}
                  setPersonalComponent={setPersonalComponent}
                  setCredentialComponent={setCredentialComponent}
                  personalDetail={personalDetail}
                  creditCardDetail={creditCardDetail}
                  credentials={credentials}
                  setWebhookWaiting={setWebhookWaiting}
                  idVerification={idVerification}
                  setIdVerification={setIdVerification}
                  destinationURL={destinationURL}
                  setShowToast={setShowToast}
                  showToast={showToast}
                  setToastMessage={setToastMessage}
                  toastMessage={toastMessage}
                  setShowRentalTerm={setShowRentalTerm}
                  showRentalTerm={showRentalTerm}
                  setCreditCardDetail={setCreditCardDetail}
                  CCVerification={CCVerification}
                  setLocationId={setLocationId}
                  locationId={locationId}
                  setErrorMessage={setErrorMessage}
                  setVerifyUserMessage={setVerifyUserMessage}
                  setConfirmPass={setConfirmPass}
                  confirmPass={confirmPass}
                  errorMessage={errorMessage}
                  onBoardComponent={onBoardComponent}
                  setPhoneNum={setPhoneNum}
                  phoneNum={phoneNum}
                  modifiedAppointment={modifiedAppointment}
                  setUnitId={setUnitId}
                  unitId={unitId}
                  setIsOpen={setIsOpen}
                  open={open}
                  maxRoom={maxRoom !== null ? maxRoom : null}
                />

                {CCVerification && (
                  <CreditCard
                    setSignupDetails={setSignupDetails}
                    signupDetails={signupDetails}
                    setCCVerification={setCCVerification}
                    setCreditCardDetail={setCreditCardDetail}
                    setOnBoardComponent={setOnBoardComponent}
                    locationId={locationId}
                    setErrorMessage={setErrorMessage}
                    errorMessage={errorMessage}
                    setShowRentalTerm={setShowRentalTerm}
                    setIdVerification={setIdVerification}
                    setConfirmPass={setConfirmPass}
                    confirmPass={confirmPass}
                    setPhoneNum={setPhoneNum}
                    phoneNum={phoneNum}
                    unitId={unitId}
                    setIsOpen={setIsOpen}
                    setInvalidCardMessage={setInvalidCardMessage}
                    invalidCardMessage={invalidCardMessage}
                    showCustomModal={locationData?.showCardDisclaimer}
                    customCardDisclaimer={locationData?.customCardDisclaimer || ''}
                  />
                )}
                {credentialComponent && (
                  <SecureAccount
                    setCredentials={setCredentials}
                    setCredentialComponent={setCredentialComponent}
                    setOnBoardComponent={setOnBoardComponent}
                  />
                )}
              </div>
            </Elements>
          </div>

          <div className={style.faqContainer}>
            <Faqs />
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
