import { AnyIfEmpty } from 'react-redux';
import {
    STRIPE_IDENTIFICATION_VERIFICATION_REQUEST,
    STRIPE_IDENTIFICATION_VERIFICATION_SUCCESS,
    STRIPE_IDENTIFICATION_VERIFICATION_FAILURE,
} from '../constants/stripe.constant';

const initialState = {
    loading: false,
    data: [],
    error: '',
};

const stripeReducer = (state = initialState, action: { type: string; payload: AnyIfEmpty<object>; }) => {
    switch (action.type) {
        case STRIPE_IDENTIFICATION_VERIFICATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case STRIPE_IDENTIFICATION_VERIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: '',
            };
        case STRIPE_IDENTIFICATION_VERIFICATION_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.payload,
            };
        default:
            return state;
    }
};

export default stripeReducer;
