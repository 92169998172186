import React, { useState } from 'react';
import styles from './SecureAccount.module.css';
import iconBack from '../../../../images/icons/icon-chevron-right.svg';
import iconHide from '../../../../images/icons/icon-hide.svg';
import iconShow from '../../../../images/icons/icon-show.svg';

interface props{
    setCredentials: React.Dispatch<React.SetStateAction<boolean>>;
    setCredentialComponent: React.Dispatch<React.SetStateAction<boolean>>;
    setOnBoardComponent: React.Dispatch<React.SetStateAction<boolean>>;
}

const SecureAccount = ({ setCredentials, setCredentialComponent, setOnBoardComponent }: props) => {
    // const [error, setError] = useState('');
    const [passwords, setPasswords] = useState({
        password: '',
        confirmPassword: '',
    });
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleBack = () => {
        setCredentialComponent(false);
        setOnBoardComponent(true);
    };

    const handelChange = (event: { target: { name: string; value: string } }) => {
        setPasswords({
            ...passwords,
            [event.target.name]: event.target.value,
        });
    };

    const handleSave = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        // if (passwords.password !== passwords.confirmPassword) {
        //     setError('Passwords do not match');
        //     return;
        // }

        // setSignupDetails({
        //     ...signupDetails,
        //     password: passwords.password,
        // });
        // setIsShowGuestCard(true);
        setOnBoardComponent(true);
        setCredentialComponent(false);
        setCredentials(true);
    };

    return (
        <>
            {/* {isShowGuestCard ? (
                <GuestCard
                    setIsShowGuestCard={setIsShowGuestCard}
                    setCredentials={setCredentials}
                    setCredentialComponent={setCredentialComponent}
                    setOnBoardComponent={setOnBoardComponent}
                    signupDetails={signupDetails}
                    setSignupDetails={setSignupDetails}
                />
            ) : ( */}
            <div className={styles.accountWrapper}>
                <div className={styles.header}>
                    <span className={styles.back} onClick={handleBack}>
                        <img src={iconBack} alt="back" />
                    </span>
                    <h3 className={styles.title}>Secure Your Account</h3>
                </div>
                <div className={styles.accountBody}>
                    <form>
                        <div className={styles.fieldWrapper}>
                            <label>Password</label>
                            <div className={styles.passwordWrapper}>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    minLength={8}
                                    placeholder="Add 8 digit strong password"
                                    onChange={handelChange}
                                />
                                <div className={styles.passwordToggle} onClick={() => setShowPassword(!showPassword)}>
                                    {!showPassword ? <img src={iconHide} alt="hide" /> : <img src={iconShow} alt="show" />}
                                </div>
                            </div>
                        </div>
                        <div className={styles.fieldWrapper}>
                            <label>Confirm Password</label>
                            <div className={styles.passwordWrapper}>
                                <input
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    name="confirmPassword"
                                    minLength={8}
                                    placeholder="Re-Enter Your password"
                                    onChange={handelChange}
                                />
                                <div className={styles.passwordToggle} onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                    {!showConfirmPassword ? <img src={iconHide} alt="hide" /> : <img src={iconShow} alt="show" />}
                                </div>
                            </div>
                        </div>
                        <div className={styles.btnWrapper}>
                            <button className={`${styles.disable} ${styles.success}`} onClick={handleSave}>
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            {/* )} */}
        </>
    );
};

export default SecureAccount;
