import { Link } from 'react-router-dom';
import styles from './ComingSoon.module.css'

const ComingSoon = () => {
  return (
    <div className={styles.comingSoonContainer}>
      <div className={styles.pineapple}>
        <div className={`${styles.leaf} ${styles.middle}`}></div>
        <div className={`${styles.leaf} ${styles.left}`}></div>
        <div className={`${styles.leaf} ${styles.right}`}></div>
        <div className={styles.shadow}></div>
        <div className={styles.body}>
          <div className={`${styles.eye} ${styles.left}`}></div>
          <div className={`${styles.eye} ${styles.right}`}></div>
          <div className={styles.mouth}></div>
          <div className={`${styles.arm} ${styles.left}`}></div>
          <div className={`${styles.arm} ${styles.right}`}></div>
          <div className={`${styles.leg} ${styles.left}`}></div>
          <div className={`${styles.leg} ${styles.right}`}></div>
        </div>
      </div>

      <p>coming soon</p>
      <Link to="/" className={styles.btn}>
        Back to Home
      </Link>
    </div >
  )
}

export default ComingSoon