import React, { Dispatch, SetStateAction } from 'react';
import styles from './Terms.module.css';
import iconClose from '../../images/icons/icon-close.svg';

interface TermsProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  handleBtnClick: (status: boolean) => void;
}

const Terms = ({ setOpen, handleBtnClick }: TermsProps) => {
  return (
    <div className={styles.modalWrapper}>

      <div className={`dmNewParagraph ${styles.termsAndConditionsWrapper}`} data-version="5" data-element-type="paragraph">
        <div className={styles.font}>
          <div className={styles.termsHeader}>
            <h1 className={styles.h1}>
              uTour, Inc., d/b/a Pineapple <br />
              USER TERMS OF SERVICE
            </h1>
            <span onClick={() => handleBtnClick(false)} className={styles.closeModal}>
              <img src={iconClose} alt="Close" />
            </span>
          </div>
          <div className={styles.termsdetail}>
            <p>Last Revised: November 30, 2023</p>
            <br />
            <p>
              PLEASE READ THESE TERMS OF SERVICE (AS UPDATED FROM TIME TO TIME BY PINEAPPLE, INC (D/B/A PINEAPPLE), “THESE TERMS”)
              CAREFULLY.
            </p>
            <ul>
              <li>
                (1) ACCESSING OR USING [www.tourwithpineapple.com] (TOGETHER WITH ANY OTHER WEBSITES OFFERED BY PINEAPPLE, THE
                “WEBSITE”), OR
              </li>
              <li>
                (2) DOWNLOADING, INSTALLING, OR USING ANY PINEAPPLE MOBILE APPLICATION THAT MAY FROM TIME TO TIME BE AVAILABLE
                (THE “APP”), OR
              </li>
              <li>
                (3) ACCESSING OR USING ANY KIOSKS INSTALLED ON THE PREMISES OF ANY PARTICIPATING RESIDENTIAL BUILDINGS
                PARTNERING WITH PINEAPPLE (“KIOSKS”) OR
              </li>
              <li>
                (4) DOWNLOADING, INSTALLING, ACCESSING OR USING ANY OF THE SERVICES AVAILABLE FROM PINEAPPLE ON THE WEBSITE OR THROUGH ANY APP OR THROUGH THE KIOSKS (IN THESE TERMS WE REFER TO THESE SERVICES AS THE “SERVICES”),
              </li>
            </ul>
            <p>
              THROUGH THE KIOSKS (IN THESE TERMS WE REFER TO THESE SERVICES AS THE “SERVICES”),
              YOU ACCEPT AND AGREE TO THESE TERMS, ALONG WITH THE PINEAPPLE PRIVACY POLICY (AS UPDATED FROM TIME TO TIME BY PINEAPPLE). BY DOING ANY OF THE FOREGOING YOU ALSO REPRESENT TO US THAT YOU ARE LEGALLY AUTHORIZED TO ACCEPT AND AGREE TO THESE TERMS AND THAT YOU ARE LEGALLY COMPETENT AND OVER THE AGE OF 18 YEARS.
            </p>
            <br />
            <p>
              PLEASE ALSO READ OUR PRIVACY POLICY TO LEARN HOW WE COLLECT AND USE INFORMATION. WHEN WE REFER TO THE “PLATFORM” IN THESE TERMS WE MEAN THE WEBSITE TOGETHER WITH ANY APP, THE KIOSKS AND THE PINEAPPLE SOFTWARE, TECHNOLOGY AND OTHER INTELLECTUAL PROPERTY USED OR CONTAINED IN THE WEBSITE, ANY APP AND THE KIOSKS, INCLUDING, WITHOUT LIMITATION ALL ASSOCIATED USER INTERFACES AND KNOWLEDGE BASES AND OTHER CUSTOMIZED TOOLS, USED COLLECTIVELY TO ALLOW SUBSCRIBERS TO ACCESS OR USE THE SERVICES.
            </p>
            <br />
            <h2 className={styles.h2}>Overview of Services. </h2>
            <br />
            <ol>
              <li>
                Subject to these Terms, anyone can visit or browse the Website. If you (“you”) are being asked to accept these Terms by clicking your acceptance or through another active method of indicating electronic acceptance of the Terms, it is because you have agreed to UTour, Inc., a Delaware corporation (“we” or “us” or “PINEAPPLE”) access to the Services, the Kiosks and the Platform, as an end user of PINEAPPLE (an “Authorized User”).
              </li>
              <li>
                In connection with your access to the Platform and use of the Services, including the Kiosks, as an Authorized User, you will provide PINEAPPLE with certain data, materials and information (“User Data”). User Data may be comprised of, without limitation, name, mailing address, telephone number, email address, photo identification, credit card number, other payment information, driver’s license number and other User Data required to create an Authorized User.
              </li>
              <li>
                To become an Authorized User, you are required to complete your Registration and create an account on the Platform. Once your Registration is complete, you will have access to the Platform, which will enable you to schedule appointments to conduct self-guided tours of apartments available for lease at certain residential apartment buildings (each, a “Premises”) owned and/or operated by PINEAPPLE’s third-party partners.
              </li>
              <li>
                Once you have scheduled an appointment at a Premises, the Platform will provide you with a unique code to access the Kiosk available for your use at such Premises. Upon your arrival at the applicable Premises at the time of your scheduled appointment, you will enter your unique code into the Kiosk, and obtain from the Kiosk the keys to access the applicable residence. You may then conduct your self-guided tour and return the keys to the Kiosk by the end of your appointment time.
              </li>
              <li>
                Your general obligations and responsibilities with respect to your use and access of the Platform, the Kiosks and the Services are set forth throughout these Terms, including without limitation to the Customer Responsibilities section listed in this document. By agreeing to these terms, you expressly agree to the following in connection with your access or use of the Platform, the Kiosks and the Services:
              </li>
            </ol>
            <br />
            <h3 className={styles.h3}>Customer Responsibilities</h3>
            <br />
            <ol>
              <li>
                You agree to abide by all rules, regulations and policies set forth, posted or otherwise made available by the owners and/or operators of each Premises you visit in connection with your use of the Platform and the Services, including, without limitation, rules, regulations and policies related to: the length of your appointment time; the number of people permitted to accompany you; any prohibition on smoking, the use of other substances or pets; and access to any common areas on the Premises.
              </li>
              <li>
                You agree to return all keys to any residence on a Premises to the applicable Kiosk as promptly as possible following the completion of your self-guided tour, and in any event no later than the end of your scheduled appointment time, and to leave each Premises promptly following the end of your appointment. You acknowledge that all keys provided to you will be embedded with tracking technology to deter theft, and will be placed on a heavy-duty code locking key-ring that inhibits the removal of the key from the tracking device. A $250, non-negotiable fine will be enforced if a key is not returned at the end of the tour, or if the heavy-duty, code locking key-ring is cut, broken, or otherwise tampered with.
              </li>
              <li>
                You agree to behave in a respectful manner during your time at any Premises, whether within or without such Premises, whether inside the residence you are scheduled to tour or in any common areas on such Premises, and whether interacting with others or with the physical space. You agree not to damage, deface, harm, vandalize or in any way impair any property upon the Premises, including without limitation the Kiosks, anything located in the residence you tour, and anything in the common areas on such Premises.
              </li>
              <li>
                You acknowledge that PINEAPPLE holds your credit card on file through the Platform’s payment processing platform, to be used as security for damage or liability you cause. Your credit card will remain on file for the duration of your status as an Active User. You acknowledge that in the event that the owner and/or operator of any Premises notifies PINEAPPLE that you have caused, directly or indirectly, any damage or other liability to the Premises or any third party in connection with your use and access of the Platform, the Kiosks or the Services, your credit card will be charged an amount that PINEAPPLE reasonably determines, in its sole discretion, is sufficient to compensate any such third party for such damage or liability. Gift cards are not accepted as a form of credit card.
              </li>
              <li>
                You acknowledge and agree to indemnify PINEAPPLE, it’s affiliates, and 3rd-party clients from any and all harm. You accept all liability for damages associated with your use of the product.
              </li>
              <li>
                You acknowledge and agree that you, the Active User, are liable for the conduct and behavior of any “Guest” that accompanies you while using the Platform. A “Guest” is defined as any individual who is not an Active User for the Platform, as defined in these Terms and Conditions.
              </li>
            </ol>
            <br />
            <h3 className={styles.h3}>Registration.</h3>
            <ol>
              <li>
                Following your acceptance of these Terms (your “Registration”), you will receive an electronic notification from PINEAPPLE confirming your Registration has been accepted or rejected by PINEAPPLE. PINEAPPLE will submit certain of your User Data to a third-party authentication company, in order for PINEAPPLE to obtain identification verification before accepting your Registration. Although it is rare for PINEAPPLE to reject a Registration, PINEAPPLE reserves the right to do so in its sole discretion.  The Registration process for all Authorized Users requires the submission of a photo identification.
              </li>
            </ol>
            <br />
            <h3 className={styles.h3}>Our Agreement.</h3>
            <ol>
              <li>
                These Terms, along with our Privacy Policy, your Registration and your status as an Authorized User under the License described below (we refer to all of these together as “Our Agreement”) create a binding contract among you and us, and Our Agreement sets out the terms and conditions among you and us, and explains our relationship as you access and use the Platform, Kiosks and Services. Our Agreement describes the Services we will provide to you, when and how we will work together, and other aspects of our business and legal relationship. Our Agreement is an important legal document so some of the language is necessarily “legalese,” but we have tried to make it as readable as possible. By accessing or using the Platform or the Services, including any Kiosk, you agree to all of the terms of Our Agreement.
              </li>
            </ol>
            <br />
            <h3 className={styles.h3}>Use of the Services. </h3>
            <ol>
              <li>
                Your accepted Registration will enable PINEAPPLE to create an account covering your use of Your Subscription Services. You agree that you will maintain and update your Registration and account information on the Website to ensure that it is current, complete and correct at all times. PINEAPPLE has the right to suspend or terminate your account, your Subscription or your use of Your Subscription Services if we determine that your use of the Services (i) poses a security risk to the Services or our Confidential Information, (ii) is connected with service attacks, spamming, misappropriation of third party rights, illegal activity or may otherwise subject us or our affiliates to any liability or (iii) constitutes a breach of Our Agreement.
              </li>
            </ol>
            <br />
            <h3 className={styles.h3}>Your Responsibilities. </h3>
            <ol>
              <li>
                Other than with respect to your use of the Kiosks, you are responsible for providing and paying all fees and charges for the equipment, internet access and connections (the speed of which may have a significant impact on the responsiveness of the Platform and Your Subscription Services) and services (other than Your Subscription Services) that you need to access, download, install, and use the Platform and Your Subscription Services. Although we may from time to time recommend types of equipment, PINEAPPLE does not guarantee that the Services are accessible on or from any particular equipment or device or with any particular software or service plan and we are not responsible at all for any equipment, whether or not recommended by us.
              </li>
              <li>
                You acknowledge that utility and performance of the Platform and Your Subscription Services is based on and requires that our 3rd-party client partners have the necessary equipment installed and functioning properly. Pineapple makes no guarantees that equipment will be available and functioning during your tour.
              </li>
              <li>
                You are allowed to access and use the Platform, the Kiosks and Your Subscription Services only for your personal purposes and in accordance with all applicable laws, rules and regulations (including those relating to internet, data, and email privacy).
              </li>
              <li>
                You agree that you will not access or use the Platform, the Kiosks or Your Subscription Services for unlawful purposes or to engage in any illegal, offensive, indecent or objectionable conduct, including violation of any third-party privacy or other rights.
              </li>
              <li>
                You will not use the Platform, the Kiosks and Your Subscription Services to transmit, store or publish any content that is obscene, libelous, threatening or unlawful or that infringes or violates any rights. We reserve the right to immediately disable your access to the Platform in the event of your unauthorized use, disruption or abuse of PINEAPPLE’s resources (as determined by PINEAPPLE in its sole and absolute discretion).
              </li>
              <li>
                You are responsible for all of your activities that occur within, through or as a result of your access or use of the Platform, the Kiosks or the Services, whether or not such activities are authorized or known by you. You are also responsible for all activities that occur within, through or as a result of access or use of the Platform, the Kiosks or the Services by a person who uses your login credentials or identity, whether or not such access, use or activities are authorized or known by you. Please do not share your login credentials, including passwords, with anyone else.
              </li>
              <li>
                You agree that, without our express pre-approval, you will not: (a) use or launch any automated system that sends more request messages to our servers in a given period of time than a human can reasonably produce in the same period by using a conventional browser; (b) access or use the Platform, the Kiosks or the Services in any manner that damages, disables, overburdens, or impairs the Platform, the Kiosks or the Services or interferes with our or any other customer’s or user’s access to or use of the Platform, Kiosks or Services; or (c) attempt to gain unauthorized access to or use of the Platform, the Kiosks or the Services.
              </li>
              <li>
                You acknowledge that you are an individual who is at least 18 years old who is able and willing to comply with all of the requirements of these Terms and the Privacy Policy.
              </li>
              <li>
                You will notify us promptly of any unauthorized use of any of your login credentials, including passwords, or identifications.
              </li>
              <li>
                If you access or use the Platform or Services on a mobile device, you are solely responsible for all message and data fees charged by your wireless service providers. Please contact your mobile service provider for pricing plans and details. PINEAPPLE is not liable for any delays, interruptions or other transmission errors related to your devices, services or wireless service providers.
              </li>
              <li>
                The Platform, the Kiosks and the Services are protected by intellectual property laws, and except as specifically allowed in these Terms, you agree not to: distribute, sell, assign, encumber, transfer, rent, lease, sublicense, modify, or time-share the Platform, the Kiosks or the Services; use any of the Platform, the Kiosks or the Services in any service bureau arrangement or on behalf of any third party; reproduce, adapt, create derivative works of, translate, localize, port or otherwise modify any of the Platform, the Kiosks or the Services; or authorize any third party to do any of the things described in this paragraph.
              </li>
              <li>
                You agree not to: remove or change any copyright or other proprietary rights’ notice or restrictive rights legend contained or included in any of the Platform, the Kiosks or the Services; decompile, disassemble, reverse compile, reverse assemble, reverse translate or otherwise reverse engineer any of the Platform, the Kiosks or the Services; use any means to discover the source code of the Platform, the Kiosks or the Services or to discover the trade secrets in the Platform, the Kiosks or the Services; or otherwise circumvent any functionality that controls access to or otherwise protects the Platform, the Kiosks or the Services.
              </li>
            </ol>
            <br />
            <h3 className={styles.h3}>Our Responsibilities.</h3>
            <ol>
              <li>
                We are responsible for providing to you access to and use of the Platform, the Kiosks and Your Subscription Services as described in these Terms and the other parts of Our Agreement, and subject to all United States federal and state laws, rules and regulations applicable to our performance of Your Subscription Services.
              </li>
              <li>
                We will maintain administrative, physical and technical safeguards that we intend to protect the security, confidentiality and integrity of User Data and all other information that you provide to, through, or in connection with Your Subscription Services.
              </li>
              <li>
                We may modify the Platform, the Kiosks or the Services from time to time, including by adding or deleting features and functions, in an effort to improve the experience of you and other customers and users; but we are not required to do so. If we believe any improvements or modifications will significantly and harmfully affect how you access and use the Platform, the Kiosks or Your Subscription Services or how the Platform, the Kiosks or Your Subscription Services perform, we will notify you.
              </li>
              <li>
                We are not responsible for the content of Other Services (as defined below) or for losses, liabilities, actual or pending claims, actions, damages, expenses, costs of defense and attorneys’ fees arising from your access or use of content, information, web sites, software, services and other materials of third parties with which you may interact when you access or use the Platform, the Kiosks or the Services (collectively, “Other Services”). YOU WILL ACCESS OR USE ANY OTHER SERVICES ENTIRELY AT YOUR OWN RISK AND YOU ARE RESPONSIBLE FOR YOUR COMPLYING WITH TERMS AND CONDITIONS APPLICABLE TO YOUR ACCESS OR USE OF OTHER SERVICES.
              </li>
            </ol>
            <br />
            <h3 className={styles.h3}>Availability.</h3>
            <p>
              Availability of the platform is determined by the property manager or owner of the apartment you are visiting. Pineapple does not control what day or hours the Pineapple system is available to customers.
            </p>
            <br />
            <h3 className={styles.h3}>Third Party Websites.</h3>
            <p>
              {' '}
              The Website and the Platform may include links that will take you to other Web sites outside of the Website or Platform, including Web sites operated by unaffiliated third parties (collectively, “Linked Sites”). PINEAPPLE has no control over any Linked Sites and you therefore acknowledge and agree that we have no responsibility for the information, content, products, services, advertising, code or other materials which may or may not be provided by or through any Linked Site. You further acknowledge and agree that PINEAPPLE is not responsible for any form of transmission received from a Linked Site. Any Linked Sites are provided by PINEAPPLE as a convenience, and links to Linked Sites do not constitute an affiliation or endorsement by us of such Web sites or the information, content, products, services, advertising, code or other materials presented on or through such Linked Sites.
            </p>
            <br />
            <h3 className={styles.h3}>Additional Terms Applicable to the App.</h3>
            <p>
              If using the Pineapple App to access the Platform or Kiosks or to use the Services,, we grant Authorized Users a limited, non-exclusive, non-transferrable, non-sublicensable license to download, install, and run a copy of the App on mobile devices. Except as expressly permitted in these Terms, you may not: (a) copy, modify, or create derivative works based on the App; (b) distribute, transfer, sublicense, lease, lend, or rent the App to any third party; or (c) reverse engineer, decompile, or disassemble the App. PINEAPPLE reserves all rights to the App not expressly granted to you under these Terms.
            </p>
            <br />
            <p>
              If you access or download the App from any app store or distribution platform (such as the Apple Store, Google Play, or Amazon Appstore) (each, an “App Provider”), then you acknowledge and agree that you must comply with the applicable terms and conditions and other requirements of such App Provider and that:
            </p>
            <br />
            <ol>
              <li>
                These Terms are among you and PINEAPPLE, and not with App Provider, and that, as between PINEAPPLE and the App Provider, PINEAPPLE is solely responsible for the App.
              </li>
              <li>
                You are responsible for (a) installing the App on mobile devices that meet the hardware, software, internet and cellular service requirements for the Platform and (b) obtaining internet access for those mobile devices to enable the App to communicate with the Platform via the internet.
              </li>
              <li>App Provider has no obligation to furnish any maintenance and support services for the App.</li>
              <li>
                App Provider is not responsible for addressing any claims you have or any claims of any third party relating to the App or your possession and use of the App, including by not limited to: (i) product liability claims; (ii) any claim that the App fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or similar legislation.
              </li>
              <li>
                In the event of any third-party claim that the App, or your possession and use of the App, infringes that third party’s intellectual property rights, the App Provider will not be responsible for the investigation, defense, settlement and discharge of any intellectual property infringement claim.
              </li>
              <li>
                App Provider and its subsidiaries are third party beneficiaries of these Terms as related to your license of the App, and when you accept these Terms, App Provider will have the right (and will be deemed to have accepted the right) to enforce these Terms as related to your license of the App against you as a third-party beneficiary of these Terms.
              </li>
              <li>You must also comply with all applicable third-party terms of service when using the App. </li>
            </ol>
            <br />
            <h3 className={styles.h3}>Data. </h3>
            <ol>
              <li>
                User Data. As between you, on the one hand, and PINEAPPLE, on the other hand, you are the sole owner of all information or data relating to you, including User Data, and our Agreement does not provide PINEAPPLE with title or ownership of Your Data. You hereby agree that you have the right to provide all User Data to PINEAPPLE and you will provide all of Your Data and User Data in accordance with all applicable laws and regulations. PINEAPPLE may, in our sole discretion, include functionality within the Services to enable you to share access (but not ownership) of your User Data with third parties.
              </li>
            </ol>
            <br />

            <h3 className={styles.h3}>License to User Data.</h3>
            <ol>
              <li>
                Notwithstanding the foregoing and subject to the terms of our Privacy Policy, you grant to PINEAPPLE a perpetual, royalty free, worldwide right and license to copy, use, distribute and disclose your User Data prior to and after the End Date (as defined below): (i) to provide Your Subscription Services to you, and otherwise perform PINEAPPLE’s obligations under Our Agreement; (ii) to monitor for security and compliance purposes your access and use of the Platform and Your Subscription Services; (iii) to perform statistical and other analysis, including to improve the Platform and the Services, but in doing those analyses we won’t publicly disclose any of your User Data except in an aggregated form that would not reasonably be expected to permit a third party to identify the disclosed information as associated with you; and (iv) to enforce the terms of Our Agreement. The license granted in this Section is sub-licensable and assignable by PINEAPPLE to any of its affiliates, co-marketers, co-developers and successors.
              </li>
            </ol>

            <br />
            <h3 className={styles.h3}>Data Security. </h3>
            <ol>
              <li>
                Without limiting the terms of our Privacy Policy, you understand that we do not guarantee that your access or use of the Platform or the Services or that your User Data or your Confidential Information will be private or secure. We are not responsible or liable to you for any lack of privacy or security that you may experience. Information collected by your internet or mobile service provider and other third parties is used, stored, transferred and disclosed pursuant to your internet or mobile service provider’s or the third party’s terms, policies and practices. We encourage you to carefully consider disclosure of any information that might be accessible to others. You are fully responsible for taking precautions and providing security measures best suited for your situation and intended use of the Platform and Services.
              </li>
            </ol>

            <br />
            <h3 className={styles.h3}> What Happens to Your Data After the End Date. </h3>

            <ol>
              <li>
                PINEAPPLE will keep all your User Data for a period of up to one hundred eighty (180) days after the End Date. PINEAPPLE will have no obligation to keep any of your User Data after the end of such one hundred eighty (180) days (though it may keep a copy solely for purposes of exercising its license in Section 7(b)).
              </li>
            </ol>
            <br />
            <h3 className={styles.h3}>Confidentiality.</h3>
            <ol>
              <li>
                Subject to the ability to share access to User Data with third parties as set forth in this document, each of you and PINEAPPLE shall retain in confidence all non-public information and know-how disclosed pursuant to Our Agreement, whether oral or in writing or electronically, that is either designated as proprietary and/or confidential or, by the nature of the circumstances surrounding disclosure, should in good faith be treated as proprietary and/or confidential (“Confidential Information”), using at least the same standard of care used by it to protect its own confidential information (but in no event less than a reasonable standard of care).
              </li>
            </ol>
            <br />

            <h3 className={styles.h3}>Exclusions.</h3>
            <ol>
              <li>
                The parties’ obligations shall not apply to Confidential Information that the receiving party can demonstrate: (i) is or becomes a matter of public knowledge through no fault of the receiving party; (ii) was rightfully in the receiving party’s possession prior to disclosure by the disclosing party; (iii) subsequent to disclosure by the disclosing party, is rightfully obtained by the receiving party from a person in lawful possession of such Confidential Information; (iv) is independently developed by the receiving party without use of or reference to Confidential Information of the disclosing party; or (v) is required to be disclosed by law (but only to the extent of such required disclosure).
              </li>
            </ol>
            <br />

            <h3 className={styles.h3}>Intellectual Property. </h3>
            <ol>
              <li>
                The Platform, the Kiosks and the Services, including the “look and feel” (e.g., text, graphics, images, logos), content and other material, are protected under copyright, trademark and other laws. You acknowledge and agree that PINEAPPLE and its licensors own all right, title and interest in and to the Platform, the Kiosks and the Services, and of all copyright, trade secret, patent, trademark and other intellectual property rights in and to the Platform, the Kiosks and the Services (including any modifications or improvements made to the Platform, the Kiosks and the Services in the course of providing any Services to you), and Our Agreement does not provide you with title or licenses to or ownership of the Platform, the Kiosks or the Services, or any copies or modifications or improvements, or to or under any of PINEAPPLE’s copyrights, patents, patent applications, copyrights, trade secrets, trademarks or other intellectual property rights, but you have only a right of limited, remote access and use of the Platform, the Kiosks and Your Subscription Services as expressly permitted and limited by Our Agreement.
              </li>
            </ol>

            <br />
            <h3 className={styles.h3}>Prohibited Actions. </h3>
            <ol>
              <li>
                You will not, and will not allow any of your employees or agents to copy, market, distribute, export, translate, reverse engineer, unencrypt, decompile, disassemble, or derive the source code, application source code, or data base design for, modify, alter, adapt or create derivative works from, duplicate or re-engineer or use internal applications that mimic the functionality of, transmit, merge, modify, transfer, adapt, loan, rent, lease, assign, share, redistribute, host, hyper-link to, frame or store all or any portion of the Platform, the Kiosks and the Services. You also will not remove, obfuscate or alter any patent, copyright, trademark or other intellectual property or proprietary notices of PINEAPPLE. You will not (i) use any device, software or routine to interfere or attempt to interfere with the proper working of the Platform, the Kiosks and the Services; (ii) use the Platform, the Kiosks or any Services to display or share any libelous, defamatory or inappropriate information; or (iii) use the Platform, the Kiosks and the Services except in strict compliance with all laws, rules, regulations, privacy policies, contracts, agreements, judgments, orders and decrees binding on you or applicable to you. You will not take any action(s) inconsistent with our ownership of our intellectual property.
              </li>
            </ol>

            <h3 className={styles.h3}>Contributions </h3>
            <ol>
              <li>
                Any feedback, evaluations, suggestions and comments that you or your employees or agents provide to PINEAPPLE (in whatever format) on or through the Platform or Services, and all improvements, updates, modifications or enhancements to the Platform, the Kiosks and the Services (including any based on or resulting from any of that feedback, evaluations, suggestions or comments) are owned exclusively by PINEAPPLE. You agree that any contribution of feedback, evaluations, suggestions and comments and any resulting improvements, updates, modifications, and enhancements (collectively “Contributions”) does not give you or any of your employees or agents any rights in or to the Platform, the Kiosks or the Services. These Contributions are all property of PINEAPPLE and PINEAPPLE may use and disclose the Contributions without notice or compensation to you. You irrevocably assign to PINEAPPLE any and all right, title and interest (including, but not limited to, any patent, copyright, trade secret, trademark, show-how, know-how, moral rights, and any and all other intellectual property rights) that you may have in and to any and all Contributions. If we request, you will execute any document, registration or filing required to give effect to the foregoing assignment.
              </li>
            </ol>
            <br />
            <h3 className={styles.h3}>SMS</h3>
            <ol>
              <li>
                This Campaign generates an immediate confirmation text message following appointment bookings, providing indispensable details such as the appointment's location, date, time, and an access code. These details are not merely informational but are essential to obtain a physical key, which is a prerequisite for gaining access to a scheduled appointment. Failure to present this specific information will result in denied access. In addition to the reminder notifications sent 24 hours and 1 hour before the appointment, a navigational link is also included in the final message. This link commences a booked tour after obtaining a key.
              </li>
              <li>
                You can cancel the SMS service at any time. Just text "STOP" to the short code. After you send the SMS message "STOP" to us, we will send you an SMS message to confirm that you have been unsubscribed. After this, you will no longer receive SMS messages from us. If you want to join again, just sign up as you did the first time and we will start sending SMS messages to you again.
              </li>
              <li>
                If you are experiencing issues with the messaging program you can reply with the keyword HELP for more assistance, or you can get help directly at support@tourwithpineapple.com.
              </li>
              <li>
                Carriers are not liable for delayed or undelivered messages. As always, message and data rates may apply for any messages sent to you from us and to us from you. You will receive one message upon account creation, and one confirmation message per appointment booked as well as reminder messages 24 hours and 1 hour in advance of an appointment booked, if applicable. If you have any questions about your text plan or data plan, it is best to contact your wireless provider.
              </li>
            </ol>
            <br />

            <br />
            <h3 className={styles.h3}>Telephone Call Policy</h3>
            <ol>
              <li>
                PINEAPPLE may call your phone before, during, or after your use of the PLATFORM to provide additional customer support, ask for feedback, or provide quality control for the PLATFORM. For quality assurance, these calls may be monitored. An automated message will play prior to any inbound or outbound call between you, the End User, and PINEAPPLE, informing both parties if call recording is in place. You are under no obligation to call, or receive a call, from PINEAPPLE.
              </li>
            </ol>

            <br />
            <h3 className={styles.h3}>DISCLAIMER OF WARRANTIES, LIMITATIONS ON LIABILITY AND DAMAGES. </h3>
            <ol>
              <li>
                DISCLAIMER OF WARRANTIES. TO THE EXTENT PERMITTED BY APPLICABLE LAW, THE PLATFORM, THE KIOSKS AND THE SERVICES ARE MADE AVAILABLE “AS-IS,” “WITH ALL FAULTS,” AND “AS AVAILABLE.” YOU BEAR ALL RISK OF YOUR ACCESSING AND USING THE PLATFORM, THE KIOSKS AND THE SERVICES. PINEAPPLE, ON BEHALF OF ITSELF AND ITS AFFILIATES, AND ITS AND THEIR RESPECTIVE VENDORS, LICENSORS, AGENTS, AND SUPPLIERS (“DISTRIBUTORS”), GIVES NO EXPRESS WARRANTIES, GUARANTEES, OR CONDITIONS UNDER OR IN RELATION TO THE PLATFORM, THE KIOSKS AND THE SERVICES. YOU MAY HAVE ADDITIONAL CONSUMER RIGHTS UNDER YOUR LOCAL LAWS WHICH THESE TERMS CANNOT CHANGE. TO THE EXTENT PERMITTED UNDER YOUR LOCAL LAWS, PINEAPPLE AND ALL DISTRIBUTORS EXCLUDE ANY IMPLIED WARRANTIES OR CONDITIONS, INCLUDING THOSE OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. PINEAPPLE DOES NOT WARRANT (A) THAT THE SERVICES WILL MEET YOUR REQUIREMENTS, (B) THAT ACCESS, USE AND OPERATION OF THE PLATFORM, THE KIOSKS AND THE SERVICES WILL BE UNINTERRUPTED OR VIRUS- OR ERROR-FREE, (C) THAT THE PLATFORM, THE KIOSKS AND THE SERVICES WILL OPERATE OR BE COMPATIBLE WITH ANY OTHER APPLICATIONS OR ANY PARTICULAR SYSTEMS OR DEVICES, (D) THAT DEFECTS IN THE PLATFORM, THE KIOSKS AND THE SERVICES WILL BE CORRECTED OR (E) THAT THE PLATFORM AND THE SERVICES WILL BE AVAILABLE FOR REINSTALLS ON THE SAME OR MULTIPLE DEVICES. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES SO SOME OR ALL OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
              </li>
              <li>
                LIMITATIONS ON LIABILITY TO THE EXTENT PERMITTED BY APPLICABLE LAW, PINEAPPLE’S TOTAL LIABILITY WITH RESPECT TO ANY CLAIM (OR GROUP OF RELATED CLAIMS) BY YOU ARISING OUT OF OR RELATED TO OUR AGREEMENT, INCLUDING THE DELIVERY, ACCESS, USE, AVAILABILITY, LACK OF AVAILABILITY OR PERFORMANCE OR NON-PERFORMANCE OF THE PLATFORM, THE KIOSKS OR THE SERVICES (WHETHER SUCH CLAIM IS FOR BREACH OR NON COMPLIANCE OR OTHERWISE AND WHETHER IN CONTRACT, STRICT LIABILITY, NEGLIGENCE OR TORT OR UNDER ANY OTHER THEORY OF LIABILITY), WILL NOT EXCEED THE TOTAL AMOUNT PAID BY YOU TO PINEAPPLE WITH RESPECT YOUR ACCESS TO AND USE OF THE PLATFORM, THE KIOSKS AND THE SERVICES IN THE TWELVE (12) MONTHS PRECEDING THE FIRST ASSERTION OF THAT CLAIM (OR THE FIRST OF THE GROUP OF RELATED CLAIMS).
              </li>
              <li>
                LIMITATIONS ON DAMAGES TO THE EXTENT PERMITTED BY APPLICABLE LAW, PINEAPPLE WILL NOT BE LIABLE TO YOU, ANY OTHER AUTHORIZED USER OR ANY THIRD PARTY FOR INDIRECT, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL AND PUNITIVE DAMAGES (INCLUDING, BUT NOT LIMITED TO, COSTS OF PROCUREMENT OF SUBSTITUTE SERVICES, LOSS OF USE, LOSS OF DATA, LOSS OF CONTENT, LOSS OF PROFITS OR REVENUE, LOSS OF GOODWILL OR BUSINESS INTERRUPTION), HOWEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, WHETHER UNDER OUR AGREEMENT OR OTHERWISE ARISING IN ANY WAY IN CONNECTION WITH OUR AGREEMENT OR THE DELIVERY, ACCESS, USE, AVAILABILITY, LACK OF AVAILABILITY OR PERFORMANCE OR NON-PERFORMANCE OF THE PLATFORM, THE KIOSKS AND THE SERVICES (WHETHER SUCH CLAIM IS FOR BREACH OR NON-COMPLIANCE OR OTHERWISE AND WHETHER IN CONTRACT, STRICT LIABILITY, NEGLIGENCE OR TORT OR UNDER ANY OTHER THEORY OF LIABILITY), EVEN IF PINEAPPLE HAS BEEN ADVISED OF THE POSSIBILITY OF THAT DAMAGE OR IF YOUR REMEDY OTHERWISE FAILS OF ITS ESSENTIAL PURPOSE. YOU AGREE NOT TO ASSERT A CLAIM FOR OR SEEK TO RECOVER ANY DAMAGES NOT PERMITTED UNDER THIS SECTION. THIS DISCLAIMER OF LIABILITY WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
              </li>
            </ol>
            <br />

            <p>
              You expressly agree that the above limitations of liability and limitations on damages, together with the other provisions in these Terms and Our Agreement that limit liability and damages, are essential parts of these Terms and Our Agreement and that PINEAPPLE would not be willing to grant you or any Authorized User the rights set forth in these Terms and Our Agreement without your agreement to these limitations of liability and limitations on damages.
            </p>
            <br />
            <p>
              NOTHING IN OUR AGREEMENT EXCLUDES ANY PARTY’S LIABILITY FOR MATTERS FOR WHICH LIABILITY CANNOT BE EXCLUDED UNDER APPLICABLE LAW. NOTHING IN THIS AGREEMENT LIMITS ANY PARTY’S LIABILITY FOR MATTERS FOR WHICH LIABILITY CANNOT BE SO LIMITED UNDER APPLICABLE LAW.
            </p>
            <br />
            <h3 className={styles.h3}>Infringement.</h3>
            <p>
              {' '}
              If Your Subscription Services or the Platform becomes or, in our opinion, may become the subject of any claim, suit or proceeding for infringement of any United States or European Union patent, trademark, copyright or other intellectual property right under United States or European Union law, PINEAPPLE will, at its option and expense, either (i) obtain for you any additional right required under United States or European Union law for you to access and use the Platform and Your Subscription Services, (ii) replace Your Subscription Services with a non-infringing Service, or (iii) modify the Platform or Your Subscription Services so that it does not infringe. PINEAPPLE will not be liable for any infringement arising from: the combination of the Platform or a Service with other products or services.
            </p>
            <br />
            <p>
              Please direct questions regarding these Terms to our team by submitting a request to: support@tourwithpineapple.com.
            </p>
            <p>or by regular mail to: </p>
            <br />
            <p>
              uTour, Inc.
              <br />
              PO #200
              <br />
              Carlisle, MA 01741
            </p>

            <div className={styles.backbtn}>
              <button onClick={() => handleBtnClick(false)} className={styles.back}>
                Decline
              </button>
              <button onClick={() => handleBtnClick(true)} className={styles.accept}>
                Accept
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
