import React from 'react';
import Slider from '../../../common/slider/Slider';
import iconArea from '../../../../images/icons/icon-area.svg';
import iconBed from '../../../../images/icons/icon-beds.svg';
import iconBath from '../../../../images/icons/icon-bath.svg';
import styles from './UnitsCard.module.css';
import { useNavigate } from 'react-router-dom';
import { unitsProps } from '../../../../interface/LocationInterface';
import { clearPreviosUnitData } from '../../../../redux/unit/actions/unit.action';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../redux/rootReducer';

interface UnitProps {
    units: unitsProps;
    locationId: string | undefined;
    showPrice?: boolean
}

const UnitsCard = ({ units, locationId, showPrice = true }: UnitProps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const handleNavigate = () => {
        dispatch(clearPreviosUnitData());
        navigate(`/location/${locationId}/unit/${units.unitId}`);
    };

    return (
        <>
            <div className={styles.unitCard} onClick={handleNavigate}>
                {!!showPrice && units.unitPrice > 0 && (
                    <span className={styles.price}>
                        ${units.unitPrice}
                        <sup>*</sup>
                    </span>
                )}
                <div className="unitCardWrapper">
                    <Slider images={units?.unitImages && units.unitImages.split(',')} />
                </div>
                <div onClick={handleNavigate} className={styles.cardInfo}>
                    <div className={styles.cardTitle}>
                        <h2>{`${units?.unitYardiId ? units?.unitYardiId : units.unitName} ${units?.nickName ? `"${units?.nickName}"` : ''
                            } `}</h2>
                        {/* {units.nickName && <h2 className={styles.nickName}>"{units.nickName}"</h2>} */}
                    </div>
                    <div className={styles.facilities}>
                        <div className={styles.facility}>
                            <img src={iconArea} alt="Area Icon" />
                            <span>{units.unitSize} Sq Ft</span>
                        </div>
                        <div className={styles.facility}>
                            <img src={iconBed} alt="Area Beds" />
                            {+units?.unitRooms === 1 ? (
                                <span>{units.unitRooms} Bed</span>
                            ) : units.unitRooms > 0 ? (
                                <span>{units.unitRooms} Beds</span>
                            ) : (
                                <span>Studio</span>
                            )}
                        </div>
                        <div className={styles.facility}>
                            <img src={iconBath} alt="Area Bath" />
                            {(+units?.unitBaths || +units?.unitBaths === 0) && (
                                <span>{`${+units?.unitBaths} ${+units?.unitBaths === 1 ? 'Bath' : 'Baths'}`}</span>
                            )}
                        </div>
                    </div>
                    <p className={styles.priceNote}>
                        <span>*</span> Final amount subject to real time availability.
                    </p>
                </div>
            </div>
        </>
    );
};

export default UnitsCard;
