import Profile from '../components/profile/Profile'

const ProfilePage = () => {
  return (
    <div>
      <Profile />
    </div>
  )
}

export default ProfilePage