import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import drop from '../../../images/icons/icon-drop-down.svg';
import iconHide from '../../../images/icons/icon-hide.svg';
import iconShow from '../../../images/icons/icon-show.svg';
import { AppDispatch, IRootState } from '../../../redux/rootReducer';
import { verifyUser } from '../../../redux/verify-user/actions/verifyUser.action';
import { GuestCardProps } from '../interfaces/auth.interface';
import styles from './GuestCard.module.css';
import { getLeadSource } from '../../../redux/lead-sources/actions/leadSources.action';
import { uploadImages } from '../../../redux/auth/actions/auth.action';
import Popup from '../../common/popup/Popup';
import Avatar from '../../../../src/images/avatar-placeholder.jpg';
import CamIcon from "../../../images/icons/cam.svg";
import Webcam from 'react-webcam';
import CloseIcon from '../../../images/icon-close.svg';
import CamLayer from '../../../images/cam-layer.svg';

const bed = [
    { value: 'Studio', label: 'Studio' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
];

type BedOption = {
    value: string;
    label: string;
};


const GuestCard = ({
    setSignupDetails,
    signupDetails,
    setCreditCardDetail,
    setShowRentalTerm,
    setVerifyUserMessage,
    setConfirmPass,
    confirmPass,
    setCCVerification,
    setOnBoardComponent,
    setErrorMessage,
    setPhoneNum,
    phoneNum,
    locationId = '',
    setShowToast,
    setToastMessage,
    maxRoom
}: GuestCardProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const webcamRef = useRef<Webcam>(null);

    const generateBedArray = (maxValue: number): BedOption[] => {
        const bed: BedOption[] = [{ value: 'Studio', label: 'Studio' }];
        for (let i = 1; i <= maxValue; i++) {
            bed.push({ value: i.toString(), label: i.toString() });
        }
        return bed;
    };

    const videoConstraints: MediaTrackConstraints | boolean = {
        facingMode: 'user', // or 'environment' for rear camera
    };

    const capturePhoto = () => {
        const imageSrc = webcamRef.current?.getScreenshot();
        if (imageSrc) {
            setNewProfile(imageSrc);
        };
    };

    const [phoneNumberObj, setPhoneNumberObj] = useState({
        number: '',
        contry: '',
        length: 11,
        countryCode: 'us',
    });
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
    // const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [inValid, setValidation] = useState<boolean>(false);

    const [readOnly, setReadOnly] = useState(true);
    const [passError, setPassError] = useState('');
    const [emailError, setEmailError] = useState('');

    const [showWebCam, setShowWebCam] = useState(false)

    const firstNameRef = useRef<HTMLInputElement>(null);
    const lastNameRef = useRef<HTMLInputElement>(null);
    const phoneNumberRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const confirmPassRef = useRef<HTMLInputElement>(null);
    const desiredRef = useRef<any>(null);

    const [leadSource, setLeadSource] = useState([])
    const [uploadFiles, setUploadFiles] = useState('');
    const [newProfile, setNewProfile] = useState('');
    const [hasMediaAccess, setHasMediaAccess] = useState(false);

    const leadSourceData = useSelector((state: IRootState) => state.leadSourceReducer.data);

    const data = useSelector((state: IRootState) => state.guestCard.data);

    useEffect(() => {
        setLeadSource(leadSourceData.leadSources)
    }, [leadSourceData])

    useEffect(() => {
        if (signupDetails.fullName === '' || signupDetails.email === '' || signupDetails.phoneNumber === '') return;
    }, [data?.statusCode, signupDetails?.fullName, signupDetails?.email, signupDetails?.phoneNumber]);

    const onChangeHandler = async (e: { target: { name: string; value: string } }) => {
        const { name, value } = e.target;

        if (name === 'firstName' || name === 'lastName') {
            setSignupDetails((prevState) => {
                return {
                    ...prevState,
                    [name]: value.replace(/[^a-zA-Z\s]/g, ''),
                };
            });
        } else if (name === 'phoneNumber') {
            const input = value;
            const cleanedInput = input.replace(/\D/g, '').substring(0, 12);
            setSignupDetails({
                ...signupDetails,
                [name]: cleanedInput,
            });
        } else if (name === 'email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const isValidEmail = emailRegex.test(value);

            setSignupDetails({
                ...signupDetails,
                [name]: value.replace(/ {2,}/g, ''),
            });

            if (value === '') {
                setEmailError('Email should not be empty');
            } else if (!isValidEmail) {
                setEmailError('Invalid email');
            } else {
                setEmailError('');
            }
        } else if (name === 'password') {
            const passwordRegex = /^(?=.*[0-9])(?=.*[- ?!@#$%^&*\\])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9- ?!@#$%^&*\\]{8,30}$/;
            const isValidPassword = passwordRegex.test(value);

            setSignupDetails({
                ...signupDetails,
                [name]: value.replace(/ {2,}/g, ''),
            });

            if (!isValidPassword) {
                setPassError('password must be longer than or equal to 8 characters');
            } else {
                setPassError('');
            }
        } else {
            setSignupDetails({
                ...signupDetails,
                [name]: value.replace(/ {2,}/g, ''),
            });
        }
    };

    const handleBed = (selectedOption: { value: string } | null) => {
        setSignupDetails({
            ...signupDetails,
            desiredNumBedrooms: selectedOption?.value || '',
        });
    };

    const phone = (event: any, country: any) => {
        const phoneNumber = event.slice(country.dialCode.length);
        const cleanedPhoneNumber = phoneNumber.replace(/^0+/, '');
        const cleanedEvent = country.dialCode + Number(cleanedPhoneNumber);
        setPhoneNum?.(cleanedEvent);
        phoneNumberObj.countryCode !== country.countryCode && setPhoneNum?.('');
        setPhoneNumberObj({
            number: event.length,
            contry: country?.format?.match(/\./g)?.length,
            length: country.format.length,
            countryCode: country.countryCode,
        });

        const number = event.slice(country?.dialCode?.length);
        setSignupDetails({
            ...signupDetails,
            phoneNumber: Number(number),
            countryCode: Number(country?.dialCode),
        });
    };

    const base64ToFile = (base64String: string, fileName: string, contentType: string): File => {
        const trimmedBase64String = base64String.trim();

        if (!trimmedBase64String.startsWith('data:image/jpeg;base64,')) {
            throw new Error('Invalid base64 string format');
        }
        const byteCharacters = atob(trimmedBase64String.split(',')[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: contentType });
        return new File([blob], fileName, { type: contentType });
    }

    const submitHandler = async (e: React.FormEvent) => {
        e.preventDefault();

        if (
            signupDetails.firstName.length === 0 ||
            signupDetails.lastName.length === 0 ||
            signupDetails.phoneNumber === 0 ||
            signupDetails.email.length === 0 ||
            signupDetails.password.length === 0 ||
            phoneNumberObj.number < phoneNumberObj.contry ||
            signupDetails.password !== confirmPass ||
            signupDetails.desiredNumBedrooms.length === 0 ||
            signupDetails.expectedMoveInDate.length === 0 ||
            emailError ||
            phoneNum === '' ||
            uploadFiles === ""
        ) {
            setValidation(true);
            if (uploadFiles === '') {
                setShowToast && setShowToast(true);
                setToastMessage && setToastMessage('Please capture a selfie');
            }
            firstNameRef.current?.focus();
            signupDetails.firstName.length !== 0 && lastNameRef.current?.focus();
            signupDetails.firstName.length !== 0 && signupDetails.lastName.length !== 0 && phoneNumberRef.current?.focus();
            signupDetails.firstName.length !== 0 &&
                signupDetails.lastName.length !== 0 &&
                signupDetails.phoneNumber !== '' &&
                emailRef.current?.focus();
            signupDetails.firstName.length !== 0 &&
                signupDetails.lastName.length !== 0 &&
                signupDetails.phoneNumber !== '' &&
                signupDetails.email.length !== 0 &&
                desiredRef.current?.focus();
            signupDetails.firstName.length !== 0 &&
                signupDetails.lastName.length !== 0 &&
                signupDetails.phoneNumber !== '' &&
                signupDetails.email.length !== 0 &&
                signupDetails.desiredNumBedrooms.length !== 0 &&
                passwordRef.current?.focus();
            signupDetails.firstName.length !== 0 &&
                signupDetails.lastName.length !== 0 &&
                signupDetails.phoneNumber !== '' &&
                signupDetails.email.length !== 0 &&
                signupDetails.desiredNumBedrooms.length !== 0 &&
                signupDetails.password.length !== 0 &&
                confirmPassRef.current?.focus();
            return;
        } else {
            inValid && setValidation(false);
            const file = base64ToFile(uploadFiles, 'user', 'image/jpeg');
            const response = await uploadImages(file, '/user-profile');

            setSignupDetails((signupDetails) => ({
                ...signupDetails,
                photo_url: response
            }))
            const verifyUserObj = {
                email: signupDetails.email,
                phoneNumber: +signupDetails.phoneNumber,
            };

            dispatch(
                verifyUser(
                    verifyUserObj,
                    setCreditCardDetail,
                    setShowRentalTerm,
                    setVerifyUserMessage,
                    setErrorMessage,
                    setCCVerification,
                    setOnBoardComponent,
                ),
            );

            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    };

    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>, fieldName: 'firstName' | 'lastName') => {
        if (event.key === ' ' && String(signupDetails[fieldName]).length === 0) {
            event.preventDefault();
        }
    };

    const today = new Date().toISOString().split('T')[0];

    useEffect(() => {
        if (locationId !== '') {
            dispatch(getLeadSource(locationId))
        }
    }, [locationId]);

    const submitPhoto = () => {
        const newUserProfile = newProfile;
        setUploadFiles(newUserProfile)
        setNewProfile('')
        setShowWebCam(false)
    }

    const requestCameraPermission = () => {

        const askCameraPermission = async (): Promise<MediaStream | null> => await navigator.mediaDevices.getUserMedia({ video: true });

        let localStream: MediaStream | null;
        askCameraPermission().then(() => {
        }).then(() => {
            setHasMediaAccess(true);
            localStream?.getTracks().forEach(track => {
                track.stop();
            });
        }).catch((error) => {
            if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
                setShowToast && setShowToast(true);
                setHasMediaAccess(false);
                setToastMessage && setToastMessage('Camera access denied! Please grant access to your camera to continue.')
            }
        })
    };


    useEffect(() => {
        requestCameraPermission();
    }, []);

    const ModalBody = () => {
        return (
            <div className={styles.modalBodyContainer}>
                <div className={styles.modalHeader}>
                    <h3>Upload Selfie</h3>

                    <div className={styles.closeIcon} onClick={() => {
                        setShowWebCam(false);
                        setNewProfile('');
                    }}>
                        <img src={CloseIcon} alt='close-icon' width={20} height={20} />
                    </div>
                </div>
                <div className={styles.modalBodyInnerContainer}>

                    {!newProfile &&
                        <>
                            <div className={styles.camLayer}>
                                <img src={CamLayer} alt='layer' width={684} height={480} />
                            </div>
                            <Webcam
                                audio={false}
                                className={styles.webCamContainer}
                                screenshotFormat="image/jpeg"
                                videoConstraints={videoConstraints}
                                ref={webcamRef}
                                autoFocus
                            />
                        </>
                    }

                    {newProfile && <div className={styles.capturedImage}>
                        <img src={newProfile} alt='' width={640} height={480} />
                    </div>}
                </div>
                <div className={styles.modalFooter}>
                    {newProfile && <button className={styles.retakeBtn} onClick={() => setNewProfile('')}>Recapture Photo</button>}
                    <button className={newProfile ? styles.submitBtn : styles.captureBtn} onClick={newProfile ? submitPhoto : capturePhoto}>{!!newProfile ? 'Submit my picture' : 'Capture photo'}</button>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    <form onSubmit={submitHandler} className={styles.guestForm}>
                        <div className={styles.imageUploaderContainer}>
                            <div className={styles.innerContainer}>
                                <img src={uploadFiles ? uploadFiles : Avatar} width={120} height={120} alt="avatar" className={styles.userAvatar} />
                                <div role='button' className={styles.camIconContainer} onClick={() => {
                                    if (!hasMediaAccess) {
                                        setShowToast && setShowToast(true);
                                        setToastMessage && setToastMessage('Camera access denied! Please grant access to your camera to continue.')
                                    } else {
                                        setShowWebCam(true)
                                    }
                                }}>
                                    <img src={CamIcon} alt="camera-icon" width={20} height={20} />
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.fieldWrapper} ${styles.sm}`}>
                            <label>
                                First Name <sup>*</sup>
                            </label>
                            <input
                                onKeyPress={(e) => handleKeyPress(e, 'firstName')}
                                value={signupDetails?.firstName}
                                hidden={true}
                                onChange={onChangeHandler}
                                type="text"
                                name="firstName"
                                autoFocus
                                ref={firstNameRef}
                                placeholder="Enter your first name"
                            />
                            {signupDetails.firstName.trim().length === 0 && inValid && (
                                <p className={styles.errorText}>First name should not be empty</p>
                            )}
                        </div>
                        <div className={`${styles.fieldWrapper} ${styles.sm}`}>
                            <label>
                                Last Name <sup>*</sup>
                            </label>
                            <input
                                onKeyPress={(e) => handleKeyPress(e, 'lastName')}
                                value={signupDetails?.lastName}
                                hidden={true}
                                onChange={onChangeHandler}
                                type="text"
                                name="lastName"
                                ref={lastNameRef}
                                placeholder="Enter your last name"
                            />
                            {signupDetails.lastName.trim().length === 0 && inValid && (
                                <p className={styles.errorText}>Last name should not be empty</p>
                            )}
                        </div>
                        <div className={`${styles.fieldWrapper} ${styles.sm} ${styles.phoneNum}`}>
                            <label>
                                Phone Number <sup>*</sup>
                            </label>
                            <PhoneInput
                                country={phoneNumberObj.countryCode}
                                value={phoneNum || String(`+1${signupDetails.phoneNumber || ''}`)}
                                onChange={phone}
                                inputStyle={{
                                    marginLeft: '30px',
                                }}
                                inputProps={{
                                    maxLength: phoneNumberObj.length,
                                    autoFocus: true,
                                    required: true,
                                    ref: phoneNumberRef
                                }}
                                countryCodeEditable={false}
                                enableLongNumbers={true}
                            />
                            {!phoneNum && inValid ? (
                                <p className={styles.errorText}>Phone number should not be empty</p>
                            ) : (
                                phoneNumberObj.number !== phoneNumberObj.contry &&
                                phoneNum &&
                                +phoneNum > 0 && <p className={styles.errorText}>Please enter valid phone number</p>
                            )}
                        </div>

                        <div className={`${styles.fieldWrapper} ${styles.sm}`}>
                            <label>
                                Email <sup>*</sup>
                            </label>
                            <input
                                // readOnly={readOnly}
                                // onBlur={() => setReadOnly(false)}
                                // onFocus={() => setReadOnly(false)}
                                value={signupDetails?.email}
                                onChange={onChangeHandler}
                                name="email"
                                type='email'
                                ref={emailRef}
                                placeholder="Enter your email"
                            />
                            {signupDetails.email.length === 0 && inValid && !emailError && (
                                <p className={styles.errorText}>Email should not be empty</p>
                            )}
                            {emailError && inValid && <p className={styles.errorText}>{emailError}</p>}
                        </div>
                        <div className={`${styles.fieldWrapper} ${styles.sm}`}>
                            <label>
                                Expected Move In Date <sup>*</sup>
                            </label>
                            <input
                                onChange={onChangeHandler}
                                value={signupDetails.expectedMoveInDate}
                                type="date"
                                max="9999-12-31"
                                name="expectedMoveInDate"
                                min={today}
                                placeholder="Date"
                            />
                            {signupDetails.expectedMoveInDate.length === 0 && inValid && (
                                <p className={styles.errorText}>Move in date should not be empty</p>
                            )}
                        </div>
                        <div className={`${styles.fieldWrapper} ${styles.dropDownOption} ${styles.md} guestCardDropdown`}>
                            <label>
                                Desired Number of Bedrooms<sup>*</sup>
                            </label>
                            <Select
                                onChange={handleBed}
                                options={(maxRoom !== null && maxRoom !== undefined) ? generateBedArray(Number(maxRoom)) : bed}
                                ref={desiredRef}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999, fontSize: 14, fontWeight: 400 }),
                                }}
                                placeholder={<div className="selectPlaceholderText">Select</div>}
                                value={{ value: signupDetails.desiredNumBedrooms, label: signupDetails.desiredNumBedrooms }}
                            />
                            {signupDetails.desiredNumBedrooms.length === 0 && inValid && (
                                <p className={styles.errorText}>Bedroom should not be empty</p>
                            )}
                            <span className={styles.dropDownIcon}>
                                <img src={drop} alt="dropdown icon" />
                            </span>
                        </div>
                        <div className={`${styles.fieldWrapper} ${styles.sm} ${styles.password}`}>
                            <label>
                                Password <sup>*</sup>
                            </label>
                            <div className={styles.pwdWrapper}>
                                <input
                                    // readOnly={readOnly}
                                    // onBlur={() => setReadOnly(false)}
                                    // onFocus={() => setReadOnly(false)}
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    // minLength={8}
                                    placeholder="Enter your password"
                                    onChange={onChangeHandler}
                                    ref={passwordRef}
                                    value={signupDetails.password}
                                />
                                <div onClick={() => setShowPassword(!showPassword)} className={styles.iconToggle}>
                                    {!showPassword ? <img src={iconHide} alt="hide" /> : <img src={iconShow} alt="show" />}
                                </div>
                            </div>
                            {signupDetails.password.length === 0 && inValid && !passError && (
                                <p className={styles.errorText}>Password should not be empty</p>
                            )}
                            {inValid && passError && <p className={styles.errorText}>{passError}</p>}
                        </div>
                        <div className={`${styles.fieldWrapper} ${styles.sm}  ${styles.password}`}>
                            <label>
                                Confirm Password <sup>*</sup>
                            </label>
                            <div className={styles.pwdWrapper}>
                                <input
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    name="confirmPassword"
                                    minLength={8}
                                    placeholder="Re-Enter your password"
                                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                        setConfirmPass?.((e.target as HTMLInputElement).value)
                                    }
                                    ref={confirmPassRef}
                                    value={confirmPass}
                                />
                                <div onClick={() => setShowConfirmPassword(!showConfirmPassword)} className={styles.iconToggle}>
                                    {!showConfirmPassword ? <img src={iconHide} alt="hide" /> : <img src={iconShow} alt="show" />}
                                </div>
                            </div>
                            {confirmPass?.length === 0 && inValid && (
                                <p className={styles.errorText}>Confirm password should not be empty</p>
                            )}
                            {signupDetails.password.length !== 0 &&
                                confirmPass?.length !== 0 &&
                                signupDetails.password !== confirmPass &&
                                inValid && <p className={styles.errorText}>Password and Confirm password does not macth</p>}
                        </div>
                        {(locationId !== '' && !!leadSource?.length) && <div className={`${styles.fieldWrapper} ${styles.dropDownOption} ${styles.md} guestCardDropdown`} style={{ width: '100%' }}>
                            <label>
                                How did you hear about us?
                            </label>
                            <Select
                                onChange={(newValue: any) => {
                                    if (newValue) {
                                        setSignupDetails({
                                            ...signupDetails,
                                            pmsLeadSorce: {
                                                lead_id: newValue?.lead_id,
                                                lead_name: newValue?.lead_name,
                                                lead_PMS: newValue.lead_PMS
                                            }
                                        })
                                    }
                                }}
                                options={leadSource?.map((source: { lead_id: string, lead_PMS: string, lead_name: string }) => {
                                    return {
                                        value: source.lead_id + " " + source.lead_name,
                                        label: source.lead_name,
                                        ...source
                                    }
                                })}
                                ref={desiredRef}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999, fontSize: 14, fontWeight: 400 }),
                                }}
                                placeholder={<div className="selectPlaceholderText">Select</div>}
                                value={(signupDetails?.pmsLeadSorce?.lead_id !== '') ?
                                    {
                                        label: (leadSource?.find((source: any) => source?.lead_id === signupDetails?.pmsLeadSorce?.lead_id && source?.lead_name === signupDetails?.pmsLeadSorce?.lead_name) as any)?.lead_name,
                                        value: (leadSource?.find((source: any) => source?.lead_id === signupDetails?.pmsLeadSorce?.lead_id && source?.lead_name === signupDetails?.pmsLeadSorce?.lead_name) as any)?.lead_id + " " + (leadSource?.find((source: any) => source?.lead_id === signupDetails?.pmsLeadSorce?.lead_id && source?.lead_name === signupDetails?.pmsLeadSorce?.lead_name) as any)?.lead_name,
                                        lead_id: (leadSource?.find((source: any) => source?.lead_id === signupDetails?.pmsLeadSorce?.lead_id && source?.lead_name === signupDetails?.pmsLeadSorce?.lead_name) as any)?.lead_id,
                                        lead_name: (leadSource?.find((source: any) => source?.lead_id === signupDetails?.pmsLeadSorce?.lead_id && source?.lead_name === signupDetails?.pmsLeadSorce?.lead_name) as any)?.lead_name,
                                        lead_PMS: (leadSource?.find((source: any) => source?.lead_id === signupDetails?.pmsLeadSorce?.lead_id && source?.lead_name === signupDetails?.pmsLeadSorce?.lead_name) as any)?.lead_PMS
                                    }
                                    :
                                    null}
                            />
                            < span className={styles.dropDownIcon}>
                                <img src={drop} alt="dropdown icon" />
                            </span>
                        </div>}
                        <div className={styles.btnWrapper}>
                            <button type="submit">Save</button>
                        </div>
                    </form>
                </div >
            </div >

            <Popup open={showWebCam} setOpen={setShowWebCam} bodyData={<ModalBody />} />
        </>
    );
};

export default GuestCard;
