import axios from 'axios';
import { Dispatch } from 'react';
import {
    CLEAR_APPOINTMENT_STATE,
    CREATE_APPOINTMENT_FAILURE,
    CREATE_APPOINTMENT_REQUEST,
    CREATE_APPOINTMENT_SUCCESS,
    GET_APPOINTMENTS_FAILURE,
    GET_APPOINTMENTS_REQUEST,
    GET_APPOINTMENTS_SUCCESS,
    GET_APPOINTMENT_DETAILS_FAILURE,
    GET_APPOINTMENT_DETAILS_REQUEST,
    GET_APPOINTMENT_DETAILS_SUCCESS,
    SET_APPOINTMENT_CHAT_SESSION_FAILURE,
    SET_APPOINTMENT_CHAT_SESSION_REQUEST,
    SET_APPOINTMENT_CHAT_SESSION_SUCCESS
} from '../constants/appointment.constant';
import { AnyIfEmpty } from 'react-redux';

export interface appointment {
    reservedDate: string;
    start_time: string;
    end_time: string;
    availability: string;
    user: string;
    code: string;
    id: string;
    created_at: Date;
    updated_at: Date;
}

export interface deleteUserAppointment {
    isModified: boolean;
    reservedDate?: string;
    start_time?: string;
}

export interface AccessCodeProps {
    file: File;
    appointmentId: string;
}

export interface TourDetails {
    appointmentId: string,
    isDoorClosed: boolean,
    isKeyReturn: boolean
}

export interface AppointmentMapDetails {
    appointmentId: string,
    start_time: string,
    end_time: string,
    reservedDate: string,
    openViaSlug: string | null,
    openAIThreadId: string | null,
    openAITourVectorId: string | null,
    openAITourFileId: string | null,
    userId: string,
    timeZone: string,
    unitMapId: string | null,
    openViaChatBotPropertyId: string | null,
    leasingAgentNumber: string,
    leasingAgentEmailId: string,
    unitMapAssetId: string | null,
    appLink: string | null,
    mappedInId: string | null;
    mapFilePath: string | null;
}

const createAppointmentRequest = () => {
    return {
        type: CREATE_APPOINTMENT_REQUEST,
    };
};

const createAppointmentSuccess = (appointment: appointment) => {
    return {
        type: CREATE_APPOINTMENT_SUCCESS,
        payload: appointment,
    };
};

const createAppointmentFailure = (appointmentError: string) => {
    return {
        type: CREATE_APPOINTMENT_FAILURE,
        payload: appointmentError,
    };
};

const clearAppointmentStateAction = () => {
    return {
        type: CLEAR_APPOINTMENT_STATE,
    };
};

const getAppointmentDetailsRequest = () => {
    return {
        type: GET_APPOINTMENT_DETAILS_REQUEST,
    };
};

const getAppointmentDetailsSuccess = (appointments: string) => {
    return {
        type: GET_APPOINTMENT_DETAILS_SUCCESS,
        payload: appointments,
    };
};

const getAppointmentDetailsFailure = (appointmentDetailsError: string) => {
    return {
        type: GET_APPOINTMENT_DETAILS_FAILURE,
        payload: appointmentDetailsError,
    };
};

export const createAppointment = (appointment: AnyIfEmpty<object>, navigate?: AnyIfEmpty<object>) => {
    return (dispatch: Dispatch<AnyIfEmpty<object>>) => {
        dispatch(createAppointmentRequest());
        axios
            .post(`/appointments`, appointment)
            .then(async (response) => {
                if (response.data.statusCode === 201) {
                    dispatch(createAppointmentSuccess(response.data.appointment));
                    await navigate?.('/appointments');
                } else {
                    throw new Error(response.data.message);
                }
            })
            .catch((error) => dispatch(createAppointmentFailure(error)));
    };
};

const getAppointmentsRequest = () => {
    return {
        type: GET_APPOINTMENTS_REQUEST,
    };
};

const getAppointmentsSuccess = (appointments: string) => {
    return {
        type: GET_APPOINTMENTS_SUCCESS,
        payload: appointments,
    };
};

const getAppointmentsFailure = (appointementsError: string) => {
    return {
        type: GET_APPOINTMENTS_FAILURE,
        payload: appointementsError,
    };
};

const setAppointmentChatSessionRequest = () => {
    return {
        type: SET_APPOINTMENT_CHAT_SESSION_REQUEST,
    };
};

const setAppointmentChatSessionSuccess = (appointments: string) => {
    return {
        type: SET_APPOINTMENT_CHAT_SESSION_SUCCESS,
        payload: appointments,
    };
};

const setAppointmentChatSessionFailure = (chatSessionError: string) => {
    return {
        type: SET_APPOINTMENT_CHAT_SESSION_FAILURE,
        payload: chatSessionError,
    };
};

export const clearAppointmentState = () => {
    return (dispatch: Dispatch<AnyIfEmpty<object>>) => {
        dispatch(clearAppointmentStateAction());
    };
};


export const getUserAppointments = (type: string) => {
    return (dispatch: Dispatch<AnyIfEmpty<object>>) => {
        dispatch(getAppointmentsRequest());
        axios
            .get('/appointments/user', {
                params: {
                    sorting: type,
                },
            })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch(getAppointmentsSuccess(response.data.appointment));
                } else {
                    throw new Error(response.data.message);
                }
            })
            .catch((error) => dispatch(getAppointmentsFailure(error)));
    };
};

export const deleteUserAppointment = (id: string, type: string, appointmentDetail: deleteUserAppointment) => {
    return (dispatch: Dispatch<AnyIfEmpty<object>>) => {
        axios.delete(`appointments/${id}`, { data: appointmentDetail }).then((response) => {
            if (response.data.statusCode === 200) {
                dispatch(getUserAppointments(type));
            }
        });
    };
};


export const getAppointmentDetails = (id: string) => {
    return (dispatch: Dispatch<AnyIfEmpty<object>>) => {
        dispatch(getAppointmentDetailsRequest())
        axios.get(`appointments/${id}`).then((response) => {
            if (response.data.statusCode === 200) {
                dispatch(getAppointmentDetailsSuccess(response?.data))
            } else {
                dispatch(getAppointmentDetailsFailure(response?.data?.message))
            }
        }).catch((error) => {
            dispatch(getAppointmentDetailsFailure(error))
        });
    };
};

export const setAppointmentChatSession = (id: string, chatSession: any) => {
    return (dispatch: Dispatch<AnyIfEmpty<object>>) => {
        dispatch(setAppointmentChatSessionRequest())
        axios.patch(`appointments/chat-session`,
            {
                "appointmentId": id,
                "chatSession": chatSession
            }).then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch(setAppointmentChatSessionSuccess(response?.data))
                } else {
                    dispatch(setAppointmentChatSessionFailure(response?.data?.message))
                }
            }).catch((error) => {
                dispatch(setAppointmentChatSessionFailure(error))
            });
    };
};

export const requestAccessCode = (appointmentObj: AccessCodeProps, onSuccess: (accessCode: string) => void, onFailure: (message: string) => void) => {

    return () => {
        axios.patch(`appointments/generate-access-code`, appointmentObj,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        ).then((response) => {
            if (response.data.statusCode === 200) {
                onSuccess(response.data.code);
            } else {
                onFailure(response.data?.message);
            }
        }).catch((error) => {
            onFailure(error.response.data.message);
        });
    };
}

export const storeTourDetails = (tourObj: TourDetails, onTourSuccess: (message: string) => void, onFailure: (message: string) => void) => {
    return () => {
        axios.patch(`appointments/store-tour-details`, tourObj
        ).then((response) => {
            if (response.data.statusCode === 200) {
                onTourSuccess(response.data.message);
            } else {
                onTourSuccess(response.data.message);
            }
        }).catch((error) => {
            onTourSuccess(error.response.data.message)
        });
    };
}

export const getAppointmentMapDetails = (appointmentId: string, onSuccess: (appointment: AppointmentMapDetails) => void, onFailure: (message: string, statusCode: string) => void) => {
    return () => {
        axios.get(`appointments/map-details/${appointmentId}`).then((response) => {
            if (response.data.statusCode === 200) {
                onSuccess(response.data.appointment);
            } else {
                onFailure(response.data.message, response.data.statusCode);
            }
        }).catch((error) => {
            onFailure(error.response.data.message, error.response.data.statusCode)
        });
    };
}