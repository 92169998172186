import axios from 'axios';

export const askToChatBot = (question: string, appointmentId: string, onSuccess: (message: string) => void, onFailure: (error: string) => void) => {
  return async () => {
    await axios
      .get('/openai-chatbot/ask', {
        params: {
          question: question,
          appointmentId: appointmentId
        },
      })
      .then((response) => {
        if (response?.status === 200) {
          onSuccess(response?.data?.responseText);
        } else {
          onFailure(response?.data?.responseText)
        }
      })
      .catch((err) => onFailure(err?.response?.data?.responseText));
  };
};


export const getChatHistory = (appointmentId: string, onSuccess: (message: string, locationName: string) => void, onFailure: () => void) => {
  return async () => {
    await axios
      .get('/openai-chatbot/chat-history', {
        params: {
          appointmentId: appointmentId
        },
      })
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          onSuccess(response?.data?.data, response?.data?.locationName);
        } else {
          onFailure()
        }
      })
      .catch((err) => {
        onFailure()
      });
  }
}
