export const timeOption = [
    { value: '12:00', label: '12:00' },
    { value: '01:00', label: '01:00' },
    { value: '02:00', label: '02:00' },
    { value: '03:00', label: '03:00' },
    { value: '04:00', label: '04:00' },
    { value: '05:00', label: '05:00' },
    { value: '06:00', label: '06:00' },
    { value: '07:00', label: '07:00' },
    { value: '08:00', label: '08:00' },
    { value: '09:00', label: '09:00' },
    { value: '10:00', label: '10:00' },
    { value: '11:00', label: '11:00' },
];

export const timeMeridiem = [
    { value: 'AM', label: 'AM' },
    { value: 'PM', label: 'PM' },
];

export const bedrooms = [
    {
        name: 'Any Bedroom',
        id: 'any bedroom',
        value: '',
    },
    {
        name: 'Studio',
        id: 'studio',
        value: 0,
    },
    {
        name: '1 Bedroom',
        id: 'bedroom-1',
        value: 1,
    },
    {
        name: '2 Bedroom',
        id: 'bedroom-2',
        value: 2,
    },
    {
        name: '3 Bedroom',
        id: 'bedroom-3',
        value: 3,
    },
    {
        name: '4 Bedroom',
        id: 'bedroom-4',
        value: 4,
    },
    {
        name: '5 Bedroom',
        id: 'bedroom-5',
        value: 5,
    },
];

export const bathrooms = [
    {
        name: '1 Bathroom',
        id: 'bathroom-1',
        value: 1,
    },
    {
        name: '2 Bathroom',
        id: 'bathroom-2',
        value: 2,
    },
    {
        name: '3 Bathroom',
        id: 'bathroom-3',
        value: 3,
    },
    {
        name: '4 Bathroom',
        id: 'bathroom-4',
        value: 4,
    },
    {
        name: '5 Bathroom',
        id: 'bathroom-5',
        value: 5,
    },
];

export const sortBy = [
    {
        name: 'Low to High Price',
        sort: 'ASC',
        sortOrder: 'price',
    },
    {
        name: 'High to Low Price',
        sort: 'DESC',
        sortOrder: 'price',
    },
    {
        name: 'Min - Max Bedrooms',
        sort: 'ASC',
        sortOrder: 'rooms',
    },
    {
        name: 'Max - Min Bedrooms',
        sort: 'DESC',
        sortOrder: 'rooms',
    },
    {
        name: 'Min - Max Bathrooms',
        sort: 'ASC',
        sortOrder: 'baths',
    },
    {
        name: 'Max - Min Bathrooms',
        sort: 'DESC',
        sortOrder: 'baths',
    },
    {
        name: 'Min - Max Square Feet',
        sort: 'ASC',
        sortOrder: 'size',
    },
    {
        name: 'Max - Min Square Feet',
        sort: 'DESC',
        sortOrder: 'size',
    },
];

export const tempTimeOptions: any = [
    { value: '00:00', label: '00:00' },
    { value: '01:00', label: '01:00' },
    { value: '02:00', label: '02:00' },
    { value: '03:00', label: '03:00' },
    { value: '04:00', label: '04:00' },
    { value: '05:00', label: '05:00' },
    { value: '06:00', label: '06:00' },
    { value: '07:00', label: '07:00' },
    { value: '08:00', label: '08:00' },
    { value: '09:00', label: '09:00' },
    { value: '10:00', label: '10:00' },
    { value: '11:00', label: '11:00' },
    { value: '12:00', label: '12:00' },
    { value: '13:00', label: '13:00' },
    { value: '14:00', label: '14:00' },
    { value: '15:00', label: '15:00' },
    { value: '16:00', label: '16:00' },
    { value: '17:00', label: '17:00' },
    { value: '18:00', label: '18:00' },
    { value: '19:00', label: '19:00' },
    { value: '20:00', label: '20:00' },
    { value: '21:00', label: '21:00' },
    { value: '22:00', label: '22:00' },
    { value: '23:00', label: '23:00' },
];
