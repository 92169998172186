import axios from 'axios';
import { Dispatch } from 'react';
import { AnyIfEmpty } from 'react-redux';
import { CHANGE_PASSWORD_FAILURE, CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS, RESET_CHANGE_PASSWORD_MESSAGE } from '../constants/auth.constant';

const changePasswordRequest = () => {
  return {
    type: CHANGE_PASSWORD_REQUEST,
  };
};

const changePasswordSuccess = (data: AnyIfEmpty<object>) => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: data,
  };
};

const changePasswordFailure = (errMsg: AnyIfEmpty<object>) => {
  return {
    type: CHANGE_PASSWORD_FAILURE,
    payload: errMsg,
  };
};

export const resetChangePasswordMessage = () => {
  return {
    type: RESET_CHANGE_PASSWORD_MESSAGE,
  };
};

export const changePassword = (changePasswordDetails: AnyIfEmpty<object>, onSuccess: () => void) => {
  return async (dispatch: Dispatch<AnyIfEmpty<object>>) => {
    dispatch(changePasswordRequest());
    await axios
      .patch('/visitor/change-password', changePasswordDetails)
      .then((response) => {
        if (response?.data.statusCode === 200) {
          dispatch(changePasswordSuccess(response.data));
          onSuccess()
        } else if (response?.data.statusCode === 400) {
          dispatch(changePasswordFailure(response?.data?.message))
        }
      })
      .catch((err) => dispatch(changePasswordFailure(err)));
  };
};
