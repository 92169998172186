import { AnyIfEmpty } from 'react-redux';
import {
	GET_LEAD_SOURCES_REQUEST,
	GET_LEAD_SOURCES_SUCCESS,
	GET_LEAD_SOURCES_FAILURE
} from '../constants/leadSources.constants'

const initialState = {
	loading: false,
	data: [],
	errorMsg: '',
};


export const leadSourceReducer = (state = initialState, action: { type: string; payload: AnyIfEmpty<object> }) => {
	switch (action.type) {

		case GET_LEAD_SOURCES_REQUEST:
			return {
				...state,
				loading: true,
				errorMsg: '',
				data: []
			}
		case GET_LEAD_SOURCES_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				errorMsg: ''
			}
		case GET_LEAD_SOURCES_FAILURE:
			return {
				...state,
				loading: false,
				errorMsg: action.payload,
			};

		default:
			return state;

	}
}
