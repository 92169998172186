import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { AnyIfEmpty, useSelector } from 'react-redux';
import { tempTimeOptions } from '../../../../constants';
import { IRootState } from '../../../../redux/rootReducer';
import { generateTimeSlots } from '../../../../utils/utils';
import { SlotsProps } from '../BookAppointment';

export interface Availability {
    id: string;
    endDate: string;
    openTime: string;
    closeTime: string;
    startDate: string;
}
export interface Appointment {
    endTime: string;
    startTime: string;
    reservedDate: string;
    availabilityId: string;
}

export interface unit {
    id: string;
    name: string;
    price: number;
    rooms: number;
    baths: number;
    status: number;
    size: number;
    image_url: string;
    locationName: string;
    locationId: string;
    locationTimeZone: string;
    locationStreet1: string;
    locationStreet2: string;
    locationCity: string;
    locationState: string;
    locationZip: string;
    locationDescription: string;
    appointments?: AnyIfEmpty<object>;
    // appointments?: Appointment;
    availabilities: Availability[];
}
interface props {
    unit: unit;
    availabilityId: never[];
    timings: { startTime: moment.MomentInput; endTime: moment.MomentInput };
    setTimings: (arg0: { startTime: string; endTime: string }) => void;
    selectedDate: string;
    setShowTime: (arg: boolean) => void;
    setShowConfirm: (arg: boolean) => void;
    setAvailableIds: React.Dispatch<React.SetStateAction<never[]>>;
    setSlots: React.Dispatch<React.SetStateAction<SlotsProps[]>>;
    setLockerInfo: any;
    lockerInfo: any;
}

const TimeSlots = ({ selectedDate, setSlots, setLockerInfo, lockerInfo }: props) => {
    const [bookedTime, setBookedTime] = useState<Date[]>([]);
    const [rangeStartTime, setRangeStartTime] = useState('');
    const [rangeEndTime, setRangeEndTime] = useState('');
    const [reservedDate, setReservedDate]: AnyIfEmpty<object> = useState('');

    const timeSlots = useSelector((state: IRootState) => state.unitReducer.unit.data.units);

    const memoizedLockerInfo = useMemo(() => {
        return timeSlots?.appointments?.map((app: { availabilityId: string; startTime: string; endTime: string; reservedDate: string }) => {
            const matchingAvailability = timeSlots?.availabilities?.find((avail: { id: string }) => avail.id === app.availabilityId);
            return matchingAvailability
                ? {
                      id: matchingAvailability.id,
                      locker: matchingAvailability.locker,
                      bookedStartTime: app.startTime,
                      bookedCloseTime: app.endTime,
                      rangeStartTime: moment(app.startTime, 'HH:mm A').subtract(1, 'hour').format('HH:mm'),
                      rangeCloseTime: moment(app.endTime, 'HH:mm A').add(1, 'hour').format('HH:mm'),
                      reservedDate: app.reservedDate,
                  }
                : null;
        });
    }, [timeSlots]);

    useEffect(() => {
        setLockerInfo(memoizedLockerInfo);
    }, [timeSlots, memoizedLockerInfo]);

    useEffect(() => {
        const res = new Set(
            reservedDate && reservedDate?.filter((res: string) => res === moment(selectedDate).format('YYYY-MM-DD')),
        ).values();
        const value = res.next().value;

        let bookedDate = value ? value : selectedDate;
        bookedDate = moment(bookedDate).format('YYYY-MM-DD');

        const temp = [];
        const DATE_FORMAT = 'YYYY-MM-DD hh:mm a';

        for (let i = 0; i < timeSlots?.availabilities?.length; i++) {
            if (
                timeSlots?.availabilities[i].startDate <= moment(selectedDate).format('YYYY-MM-DD') &&
                timeSlots?.availabilities[i].endDate >= moment(selectedDate).format('YYYY-MM-DD')
            ) {
                temp.push({
                    startTime: timeSlots?.availabilities[i].openTime,
                    endTime: timeSlots?.availabilities[i].closeTime,
                    id: timeSlots?.availabilities[i].id,
                });
            }
        }
        temp.sort((a, b) => (a.startTime > b.startTime ? 1 : b.startTime > a.startTime ? -1 : 0));

        const tempArray: AnyIfEmpty<object> = [];

        for (let j = 0; j < temp.length; j++) {
            for (let i = 0; i < tempTimeOptions.length; i++) {
                const item = tempTimeOptions[i];

                item.id = temp[j].id;

                const time: {
                    value: string;
                    label: string;
                    isDisabled?: boolean;
                    id?: string;
                    isAvailable: boolean;
                    availableTimes: number;
                } = { ...item };

                const newDate1 = moment(`${bookedDate} ${time.value}`, DATE_FORMAT).toISOString();

                const slotStartTime = moment(`${bookedDate} ${time.value}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm');

                const roundUp = moment(`${bookedDate} ${temp[j]?.startTime}`, DATE_FORMAT).toISOString();

                const hour = moment(roundUp);

                var start = hour?.minute() ? hour?.startOf('hour') : hour.subtract(1, 'hour').startOf('hour');

                const roundDown = moment(`${bookedDate} ${temp[j]?.endTime}`, DATE_FORMAT).toISOString();

                const minute = moment(roundDown);

                const end =
                    minute?.minute() && minute?.hours() >= 23 && minute?.minute() === 59
                        ? minute.subtract(1, 'hour')
                        : minute.startOf('hour').subtract(1, 'hours');

                const date = new Date();

                if (moment(selectedDate).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')) {
                    if (moment(start).add(1, 'hours').format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')) {
                        if (moment(start).add(1, 'hours').format('HH') < moment(date).format('HH')) {
                            var start = moment(date).subtract(1, 'hours');
                        }
                    }
                }
                const isBooked = timeSlots?.appointments?.filter((item: any) => {
                    const start = moment(`${item.reservedDate} ${item.startTime}`, DATE_FORMAT)
                        .subtract(1, 'hours')
                        .format('YYYY-MM-DD HH:mm');
                    const end = moment(`${item.reservedDate} ${item.endTime}`, DATE_FORMAT).add(1, 'hour').format('YYYY-MM-DD HH:mm');

                    const isBetween = moment(slotStartTime).isBetween(start, end, undefined, '[)');
                    return item.availabilityId == time.id && isBetween;
                });
                time.isDisabled = isBooked?.length > 0 ? false : moment(slotStartTime).isBetween(start, end);

                if (time.isDisabled && bookedDate === moment(selectedDate).format('YYYY-MM-DD')) {
                    bookedTime?.map((bookingTime) => {
                        if (time.isDisabled) {
                            const start = moment(`${bookedDate} ${bookingTime}`, DATE_FORMAT).subtract(1, 'hours').toISOString();

                            const end = moment(`${bookedDate} ${bookingTime}`, DATE_FORMAT).add(2, 'hour').toISOString();

                            const booked = moment(newDate1).isBetween(start, end, undefined, '[)');

                            if (booked && time.availableTimes < temp.length) {
                                time.isDisabled = false;
                                time.availableTimes += time.availableTimes ?? 0;
                            }
                        }
                        return false;
                    });
                }
                tempArray.push(time);
            }
        }

        const filteredList = tempArray?.filter((item: { value: string; label: string; isDisabled?: boolean }) => item.isDisabled === true);

        const uniqueSortedSlots: { value: string; label: string; isDisabled?: boolean }[] = [
            ...new Map<string, { value: string; label: string; isDisabled?: boolean }>(
                filteredList
                    .sort((a: { label: string }, b: { label: string }) => a.label.localeCompare(b.label))
                    .map((item: { value: string }) => [item.value, item]),
            ).values(),
        ];

        const availableTime: { value: string; label: string }[] = [];

        for (let i = 0; i < lockerInfo?.length; i++) {
            const specificLocker = lockerInfo.filter(
                (info: { locker: number; reservedDate: string }) => info.locker === lockerInfo[i].locker,
            );

            const filteredTimeOptions = tempTimeOptions.filter((timeOption: { value: string }) =>
                specificLocker.every(
                    (locker: { rangeStartTime: string; rangeCloseTime: string; reservedDate: string }) =>
                        !(
                            timeOption.value > locker.rangeStartTime &&
                            timeOption.value < locker.rangeCloseTime &&
                            locker.reservedDate === selectedDate
                        ),
                ),
            );

            filteredTimeOptions.forEach((timeOption: any) => {
                availableTime.push(timeOption);
            });
        }

        const unavailableLockers = lockerInfo?.map((info: { locker: number }) => info.locker);
        const remainingLockersData = timeSlots?.availabilities?.filter((obj: any) => !unavailableLockers?.includes(obj.locker));

        for (let i = 0; i < remainingLockersData?.length; i++) {
            const timeSlots = generateTimeSlots(remainingLockersData[i]?.openTime, remainingLockersData[i]?.closeTime);

            availableTime.push(...timeSlots);
        }

        // @ts-ignore
        const finalizedArray = [...new Set(availableTime.map(JSON.stringify))].map(JSON.parse).sort((a, b) => {
            if (a.value < b.value) return -1;
            if (a.value > b.value) return 1;
            return 0;
        });

        const commonSlots = finalizedArray.filter((slot) => uniqueSortedSlots.some((uniqueSlot) => uniqueSlot.value === slot.value));

        const uniqueData = commonSlots.reduce<{ value: string; label: string }[]>((uniqueArr, item) => {
            const { value, label } = item;
            const existingItem = uniqueArr.find((u) => u.value === value);

            if (!existingItem) {
                uniqueArr.push({ value, label });
            }

            return uniqueArr;
        }, []);

        setSlots(uniqueData.length ? uniqueData : uniqueSortedSlots);
    }, [
        bookedTime,
        rangeStartTime,
        reservedDate,
        selectedDate,
        rangeEndTime,
        setSlots,
        timeSlots?.availabilities,
        timeSlots?.appointments,
        lockerInfo,
    ]);

    useEffect(() => {
        setBookedTime([]);
        timeSlots?.appointments?.map((time: { startTime: Date; reservedDate: Date }) => {
            if (moment(time.reservedDate).format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD')) {
                setBookedTime((prevStates) => [...prevStates, time.startTime]);
            }
            return false;
        });
        timeSlots?.appointments?.map((time: { reservedDate: Date }) =>
            setReservedDate((prevState: Date[]) => [...prevState, time.reservedDate]),
        );
        timeSlots?.availabilities?.forEach((time: { openTime: React.SetStateAction<string>; closeTime: React.SetStateAction<string> }) => {
            setRangeStartTime(time.openTime);
            setRangeEndTime(time.closeTime);
        });
    }, [timeSlots, selectedDate]);

    return <></>;
};

export default TimeSlots;
