import React from 'react';
import styles from './Footer.module.css';
import iconMail from '../../../images/icons/icon-mail.svg';

const Footer = () => {
    return (
        <div className={styles.fluid}>
            <div className={`${styles.outerContainer} ${styles.flexContainer}`}>
                <div className={`${styles.link} ${styles.flexContainer}`}>
                    <a href="mailto:visitor@tourwithpineapple.com">
                        <img src={iconMail} alt="Icon Mail" />
                        visitor@tourwithpineapple.com
                    </a>
                </div>
                <div className={`${styles.link} ${styles.flexContainer}`}>Copyright @Pineapple {new Date().getFullYear()}</div>
            </div>
        </div>
    );
};

export default Footer;
